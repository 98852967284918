import React from 'react'
import { NavLink } from 'react-router-dom'

const Gyanbox02ActivityScreen = () => {
  return (
    <>
          <main>
		

        <section className="bg-boarding gerua">
           <div className="container-fluid cf-set">
           

               <div className="row align-items-center justify-content-center ">
                   <div className="col-lg-6">
                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />ज्ञान बॉक्स</h2>
                   </div>
                   <div className="col-lg-4">
                       <NavLink className="float-right width-auto" to="/karo-02-activity-05-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div>

                   
               </div>
               
               <div className="row align-items-center justify-content-center mt-20">
                   <div className="col-lg-6">
                       <p className="mpara large-para fs-30 mt-40">आपने अब यह एक्टिविटी पूरी कर ली है, पर ज़रा सोचिए
                           कि क्या जो खूबी आपके मनपसंद  हीरो / हीरोइन / आदर्श में है क्या वह आपके अंदर नहीं हो सकती? और क्या आपकी खूबियाँ आपके मनपसंद  हीरो / हीरोइन / आदर्श में नहीं हैं? इसका जवाब है कि बिलकुल ऐसा हो सकता है और यदि कुछ खूबियाँ ना भी मिलती हो, तब भी यह याद रखना ज़रूरी है कि हर कोई ख़ास है, चाहे लड़का हो या लड़की।</p>
                       <NavLink to="/karo-03-activity-01-screen" className="btnset "><span>आगे बढ़ें </span> </NavLink>
                   </div>

                   
                   <div className="col-lg-4">
                       <NavLink to="" style={{"width": "100%;"}}>
                           <center>
                               <img draggable="false" width="100%" src="assets/img/karo/safai-girl.svg" />
                           </center>
                       </NavLink>

                   </div>

            
                   
               </div>

            
                               
                           

               
           </div>
        </section>
   
       

   </main>
    </>
  )
}

export default Gyanbox02ActivityScreen