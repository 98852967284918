import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import swal from "sweetalert";

const AccountDeactivate = () => {
    const users = JSON.parse(localStorage.getItem("user_details"));
    const navigate = useNavigate();
    const AccountDeactivate = (e) => {
    
        const user_id = users.user_id;
    
        var data = JSON.stringify({
          user_id: user_id,
          account: "deactivate",
          status: "1",
        });
    
        var config = {
          method: "post",
          url: BaseURL + "/accountStatus",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        axios(config)
          .then(function (response) {
            if (response.data.success === true) {
                // setAccountStatus(response.data.data.status)
                // navigate('/account-deactivate');
                const msg = response.data.message;
              swal(
                msg,
                "You clicked the button!",
                "success"
              );
            }
            // console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      };

  return (
    <>
      <main>
        <section className="bg-flexible general-bg">
          <div className="">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 ">
                <div className="col-lg-7">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    अकाउंट{" "}
                  </h2>
                </div>
                <div className="col-lg-5">
                  <NavLink
                    className="float-right width-auto"
                    to="/account-list"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row mt-30 align-items-center justify-content-center ">
                <div className="col-lg-4">
                  <div
                    className="six-tare text-center mt-50 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <center>
                      <img src="assets/img/icon/check-icon2.svg" alt="" />
                    </center>
                    <h3 className="green text-center mt-30">
                      {" "}
                      अकाउंट डीएक्टिवेट पूर्ण <br /> हुआ
                    </h3>
                  </div>
                  <h4
                    className="recording mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    अकाउंट को एक्टिवेट करें
                  </h4>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink
                      to="#"
                      onClick={AccountDeactivate}
                      className="btnset m-auto blue-shadow width-80p"
                    >
                      <span>एक्टिवेट </span>{" "}
                    </NavLink>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      className={0}
                      width="100%"
                      src="assets/img/on-boarding/pinky-and-punkaj.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AccountDeactivate;
