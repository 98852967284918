import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Karo02ActivityHint = () => {
  
  const [lg, setLg] = useState("col-lg-10 d-flex");

  return (
    <>
      <main>
        <section className="bg-boarding gerua">
          <div className="container-fluid cf-set">
            <Name lg={lg}  />

            <div className="row align-items-center justify-content-between">
              <div className="col-lg-12">
                <p className="mpara large-para fs-30 mt-40">
                  आपका मनपसंद हीरो / हीरोइन / आदर्श कोई भी हो सकता है, लड़का भी या
                  लड़की भी।
                </p>
                <p className="mpara large-para fs-30 ">
                  अच्छे से सोच लें कि आपको यह क्यों पसंद है या आपको यह क्यों
                  सुन्दर लगते हैं और इसी तरह अपने बारे में भी सोच लें और फिर
                  दोनों हिस्सों में 5-5 खूबियाँ लिख दें।
                </p>

                <div className="row align-items-center justify-content-between">
                  <div className="col-lg-6">
                    <div className="border-box gerua-change">
                      <div className="content">
                        <p className="mpara large-para fs-30">
                          मनपसंदहीरो/हीरोइन/ कलाकार और खूबियाँ
                        </p>

                        <textarea
                          className="fsetn mtext-area"
                          id="w3review"
                          name="w3review"
                          rows="4"
                          cols="50"
                          placeholder="कटरीना  "
                        ></textarea>
                      </div>
                      <span className="shapes one-s"></span>
                      <span className="shapes sec-s"></span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="border-box gerua-change gbg-change">
                      <div className="content">
                        <p className="mpara large-para fs-30">
                          अपना नाम और अपनी खूबियाँ
                        </p>
                        <textarea
                          className="fsetn mtext-area"
                          id="w3review"
                          name="w3review"
                          rows="4"
                          cols="50"
                          placeholder=" खूबियाँ "
                        ></textarea>
                      </div>
                      <span className="shapes one-s"></span>
                      <span className="shapes sec-s"></span>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div
                    className="mt-10 mb-15 mr-15 wow fadeInDown "
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink
                      to="/karo-02-activity-05-screen"
                      className="theme_btn theme_btn_bg chnage-d mt-15"
                    >
                      आगे बढ़ें{" "}
                    </NavLink>
                  </div>

                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink
                      to="/karo-02-activity-05-screen"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                    >
                      <div className="theme_btn theme_btn_bg chnage-d mt-15 hint-p">
                        <img src="assets/img/general/hint.svg" alt="" />
                      </div>
                      <br></br>
                      <p className="text-center hint-font">हिंट</p>
                    </NavLink>
                  </div>
                </div>

                {/*   <!-- Modal --> */}
              </div>

              {/* 
                   <!-- 
                   <div className="col-lg-5">
                    
                       <center><img draggable="false" className="mt-0" width="90%" src="assets/img/karo/pinky-pankaj-activity.svg" /></center>
                   </div> --> */}
            </div>
          </div>
        </section>
      </main>

      
   

    </>
  );
};

export default Karo02ActivityHint;
