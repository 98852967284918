import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import Name from "../common/Name";
import ReactAudioPlayer from "react-audio-player";
import axios from "axios";
import BaseURL,{AdminUrl} from "../common/Baseurl";

const Suno03Activity03Screen = () => {
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [audiocount11, setAudioCount1] = useState(0);
  const [audiocount21, setAudioCount2] = useState(0);

  const [audio1Checked, setAudio1Checked] = useState("blank");
  const [audio2Checked, setAudio2Checked] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));



  const audio1click = () => {
    setAudio1Checked("filled");
    const audiocount1 = audiocount11 + 1;
    setAudioCount1(audiocount1);
    if (audiocount1 === 1) {
      const attempt = { attempt: "first"};
      localStorage.setItem("audio-attempt", JSON.stringify(attempt));
    }

    if (audiocount1 === 2) {

      const attempt = { attempt: "second"};
      localStorage.setItem("audio-attempt", JSON.stringify(attempt));

     
    }

    if (audiocount1 === 3) {

      const attempt = { attempt: "third"};
      localStorage.setItem("audio-attempt", JSON.stringify(attempt));

      
    }

   

  };

  const audio2click = () => {
    setAudio2Checked("filled");
    const audiocount2 = audiocount21 + 1;
    setAudioCount2(audiocount2);
    if (audiocount2 === 1) {

      const attempt = { attempt: "first"};
      localStorage.setItem("audio-attempt", JSON.stringify(attempt));

     
    }

    if (audiocount2 === 2) {

      const attempt = { attempt: "second"};
      localStorage.setItem("audio-attempt", JSON.stringify(attempt));

     
    }

    if (audiocount2 === 3) {

      const attempt = { attempt: "third"};
      localStorage.setItem("audio-attempt", JSON.stringify(attempt));

     
    }

   

  };

  // console.log(checkBlankData);
  const BtnDisabled = () => {
    if (audio1Checked !== "blank" ) {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <NavLink to="/suno-03-activity-04-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible D4F3FD">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between ">
              <div className="col-lg-4">
                <h1
                  className="h1 mb-20  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  सुनो तो सही सुनो{" "}
                </h1>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {" "}
                  नीचे दी गई रिकॉर्डिंग को ध्यान से सुनेंं{" "}
                </p>
              </div>
              <div className="col-lg-4">
                <h4
                  className="recording wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {" "}
                  रिकॉर्डिंग नंबर 1
                </h4>
                <div
                  className="wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="mange-audio audi-percent">
                    <div className="edit-audio">
                      <ReactAudioPlayer
                        src={AdminUrl+"/line1.mp3"}
                        autoPlay
                        controls
                        onPlay={audio1click}
                      />

                      {/* <audio
                        controls=""
                        src="https://instrumentbible.github.io/sampler.js/assets/kick.wav"
                      />
                      <input type="file" /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4">
                <h4
                  className="recording wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {" "}
                  रिकॉर्डिंग नंबर 2{" "}
                </h4>
                <div
                  className="wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="mange-audio audi-percent">
                    <div className="edit-audio">
                      <ReactAudioPlayer
                         src={AdminUrl+"/line2.mp3"}
                        
                        controls
                        onPlay={audio2click}
                      />

                     
                    </div>
                  </div>
                </div>
               
              </div> */}
             
              <BtnDisabled />
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno03Activity03Screen;
