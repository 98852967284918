import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo12Activity01Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
          <Name/>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div
                  className="col-lg-4 mt-m-2 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h1 className="h1 mb-20 ">खेलो</h1>
                  <div
                    className="karo-activity-barabri wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <h4>कैसे रहें सुरक्षित?</h4>
                    <img
                      className="mt-30"
                      width="100%"
                      src="assets/img/khelo/krs-chune.svg"
                      alt=""
                    />
                    <div className="mt-30">
                      <NavLink
                        to="/khelo-12-activity-02-screen"
                        className="btnset radius-set m-auto"
                      >
                        <span>चुनें</span>{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 mt-m-2 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/krs-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo12Activity01Screen;
