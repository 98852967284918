import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";

import axios from "axios";
import BaseURL,{AdminUrl} from "../common/Baseurl";

const Khelo05Activity06Screen = () => {
  const [items,setItem] = useState([]);
  const [recordingUrl,setRecordingUrl] = useState();
  const [recordingDuration,setRecordingDuration] = useState();

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }



  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL+"/getKheloActivity05ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data.ans);
        if(response.data.success === true)
        {
          setRecordingUrl(response.data.data.ans);
          setRecordingDuration(response.data.data.recording_duration);
          setItem(response.data.data)


        }

      })
      .catch(function (error) {
        console.log(error);
      });
  },[]);



  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg FFE9E9">
          <Name />
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-50">
              <div className="col-lg-4">
                <h1
                  className="h1 text-center mb-20 mt-0 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  मेरी रिकॉर्डिंग
                </h1>

                <div
                  className="mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="mange-audio audi-percent">
                    <div className="edit-audio">
                      <audio
                        src={AdminUrl+`/public/`+recordingUrl}
                        controls="true"
                      />

                      {/* <audio
                        controls=""
                        src="https://instrumentbible.github.io/sampler.js/assets/kick.wav"
                      />
                      <input type="file" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mt-m-5 m-auto">
                <div
                  className="timer timer-small m-auto wow fadeInDown "
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <div className="content-alarm">
                    <p>{items.recording_duration}:00</p>
                  </div>
                </div>
                <p
                  className="mpara large-para fs-22  d-block wow fadeInDown "
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  रिकॉर्डिंग के लिए समय लिया : {items.recording_duration} सेकंड्स
                </p>
                <div
                  className="mt-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <NavLink to="/khelo-05-activity-07-screen" className="btnset m-auto">
                    <span>स्कोर जानिए</span>{" "}
                  </NavLink>
                </div>
              </div>
              <div
                className="col-lg-4 mt-m-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    draggable="false"
                    width="90%"
                    src="assets/img/dekho/cg-main-img.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo05Activity06Screen;
