import React from 'react';
import { NavLink} from 'react-router-dom';



const Menu = () => {
  return (
    <>
         <nav>
            <ul>
                
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/about">About</NavLink></li>
                <li><a href="#">Modules <i className="far fa-chevron-down"></i></a>
                    <ul className="submenu">
                        <li><a href="tkt.html">Taron ki Toli</a></li>
                        <li><a href="#">Team Change Leader</a></li>
                        <li><a href="#">Smart Learning & Development</a></li>
                    </ul>
                </li>
                <li><a href="#">RESOURCES</a></li>
                <li><a href="#">SCOREBOARD</a></li>
                <li><a href="#" className="nav-search search-trigger header-2-icon"><span><img src="../../assets/img/icon/search.svg" width="20px;" /></span></a></li>
            </ul>
        </nav>

     

    </>
  )
}

export default Menu