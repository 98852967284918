import { useEffect, useState } from "react";

const UseRecorder = () => {
  
  const [audio, setAudio] = useState("");
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [startTime,setStartTime] = useState(null);
  const [endTime,setEndtTime] = useState(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {

      // console.log('ehoo');
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = e => {
      setAudio(e.data);
      setAudioURL(URL.createObjectURL(e.data));
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const startRecording = () => {

    let today = new Date();
    // let hours = (today.getHours() < 10 ? '0' : '') + today.getHours();
    // let minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
    let seconds = (today.getSeconds() < 10 ? '0' : '') + today.getSeconds();
    // return hours + ':' + minutes + ':' + seconds;
    
    setStartTime(seconds);
    // console.log(seconds);
    setIsRecording(true);
  };

  const stopRecording = () => {

    let today = new Date();
    // let hours = (today.getHours() < 10 ? '0' : '') + today.getHours();
    // let minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
    let seconds = (today.getSeconds() < 10 ? '0' : '') + today.getSeconds();
    // return hours + ':' + minutes + ':' + seconds;
    setEndtTime(seconds);
  

    setIsRecording(false);
  };
  
  return [audio,audioURL, isRecording, startRecording, stopRecording,startTime,endTime];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default UseRecorder;
