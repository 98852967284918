import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

import { Modal, Button, Form } from "react-bootstrap";

const Khelo05Activity04Screen = () => {
  const chakkaGhumaSelectedVal = localStorage.getItem("chakkaGhumaSelectedVal");

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg FFE9E9">
          <Name />
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-50">
              <div className="col-lg-4">
                <div
                  className="six-tare  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="timer timer-small m-auto">
                    <div className="content-alarm">
                      <p>01:00</p>
                    </div>
                  </div>
                  <p className="text-center mt-20"> आपका टॉपिक है </p>
                  <h1 className="rh1 vw-2-5 mt-20">
                    {" "}
                    {chakkaGhumaSelectedVal}
                  </h1>
                </div>
              </div>
              <div
                className="col-lg-5 text-left offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="d-flex">
                  <NavLink to="#" onClick={handleShow}>
                    <center>
                      <img src="assets/img/btn/hint.svg" alt="" />
                    </center>
                    <p className="side-btn">हिंट</p>
                  </NavLink>
                </div>

                <div className="mt-15 ">
                  <NavLink
                    to="/khelo-05-activity-05-screen"
                    className="btnset "
                  >
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* modal */}

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">
                <div className="row align-items-center justify-content-center mt-60">
                  <div className="col-lg-12">
                    <h3>उदाहरण</h3>
                    <p>
                    मेरा टॉपिक है _______ और इस बारे में मेरे<br></br> विचार हैं ______| मैं इस बारे में यह भी सोचता / सोचती हूँ कि_______


                    </p>

                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo05Activity04Screen;
