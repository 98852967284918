import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import BtnDisabled from "../common/BtnDisabled";

const Karo06Activity04Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-7");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center pt-50"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [ans1, setAns1] = useState();
  const [ans2, setAns2] = useState();
  const [ans3, setAns3] = useState();
  const [ans4, setAns4] = useState();
  const [ans5, setAns5] = useState();
  const [ans6, setAns6] = useState();
  const [ans7, setAns7] = useState();
  const [ans8, setAns8] = useState();
  const [err, setErr] = useState();
  //   const [activites, setActivities] = useState([]);
  const [checkBlankData, setCheckBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const submitLetter = (e) => {

    // console.log(ans1);
    e.preventDefault();


    if (ans1 === undefined ||
      ans2 === undefined||
      ans3 === undefined||
      ans4 === undefined||
      ans5 === undefined||
      ans6 === undefined||
      ans7 === undefined ||
      ans8 === undefined



      ) {
     // const err = "please Insert All Fields";
     setErr("कृपया सभी फ़ील्ड दर्ज करें");
     return false;
   }



    if (ans1.length === 0 ||
       ans2.length === 0 ||
       ans3.length === 0 ||
       ans4.length === 0||
       ans5.length === 0||
       ans6.length === 0 ||
       ans7.length === 0 ||
       ans8.length === 0



       ) {
      // const err = "please Insert All Fields";
      setErr("कृपया सभी फ़ील्ड दर्ज करें");
    }

    var data = JSON.stringify({
      user_id: user_id,
      answers: [
        {
          ans: ans1,
          ques_id: "1",
        },
        {
          ans: ans2,
          ques_id: "2",
        },
        {
          ans: ans3,
          ques_id: "3",
        },
        {
          ans: ans4,
          ques_id: "4",
        },
        {
          ans: ans5,
          ques_id: "5",
        },
        {
          ans: ans6,
          ques_id: "6",
        },
        {
          ans: ans7,
          ques_id: "7",
        },
        {
          ans: ans8,
          ques_id: "8",
        },
      ],
    });

    // console.log(data);

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity06",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          navigate("/karo-06-activity-05-screen");
          // navigate('/karo-06-activity-05-screen');
        } else {

          if(response.data.message === 'Could Not Create Or Update,Your Certificate has Been Created')
          {

            navigate("/karo-07-activity-05-screen");
            return false;
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(ans2);
  };

  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity06Result",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          // setActivities(response.data.data.activities);
          setAns1(response.data.data.activities[0]["ans"]);
          setAns2(response.data.data.activities[1]["ans"]);
          setAns3(response.data.data.activities[2]["ans"]);
          setAns4(response.data.data.activities[3]["ans"]);
          setAns5(response.data.data.activities[4]["ans"]);
          setAns6(response.data.data.activities[5]["ans"]);
          setAns7(response.data.data.activities[6]["ans"]);
          setAns8(response.data.data.activities[7]["ans"]);
        } else {
          console.log(response.data);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <main className="F0F3FF bg-flexible">
        <section className="overflowsetbody F0F3FF">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">

          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-center mt-30">
              <div className="col-lg-12">
                <div className="form-under-text">
                  <h1
                    className="h1 mb-20 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                  पत्र
                  </h1>
                  <span className="showError">{err}</span>
                  <form action="#" onSubmit={submitLetter}>
                    <div className="form-div">
                      <label for="" className="laval-className">
                        प्रिय{" "}
                      </label>
                      <input
                        className="form-set"
                        type="text"
                        placeholder=""
                        onChange={(e) => setAns1(e.target.value)}
                        value={ans1}
                      />
                      <label for="" className="laval-className">
                        (आपके मित्र का नाम)
                      </label>
                    </div>
                    <div className="form-div">
                      <input
                        className="form-set"
                        type="text"
                        placeholder=""
                        onChange={(e) => setAns2(e.target.value)}
                        value={ans2}
                      />
                      <label for="" className="laval-className">
                        (आज की तारीख़)
                      </label>
                    </div>
                    <div className="form-div">
                      <label for="" className="laval-className">
                        आज मैं  तुम्हे बहुत याद कर रहा था/थी। मुझे बहुत याद आता है हम
                        दोनों का स्कूल में एक साथ{" "}
                      </label>
                      <input
                        className="form-set"
                        type="text"
                        placeholder=""
                        onChange={(e) => setAns3(e.target.value)}
                        value={ans3}
                      />
                      <label for="" className="laval-className">
                        करना।{" "}
                      </label>

                      <label for="" className="laval-className">
                        और फिर बहुत से खेल खेलना जैसे कि
                      </label>
                      <input
                        className="form-set"
                        type="text"
                        placeholder=""
                        onChange={(e) => setAns4(e.target.value)}
                        value={ans4}
                      />
                      <label for="" className="laval-className">
                        । याद है जब तुमने मेरी मदद की थी,{" "}
                      </label>

                      <input
                        className="form-set"
                        type="text"
                        placeholder=""
                        onChange={(e) => setAns5(e.target.value)}
                        value={ans5}
                      />
                      <label for="" className="laval-className">
                        । तुम नहीं होते/होती तो न जाने क्या होता।{" "}
                      </label>

                      <label for="" className="laval-className">
                        तुम्हारे इन सब गुणों की चर्चा तो मैं अक्सर अपने घर मेंं
                        और बाकी दोस्तों के सामने करती/करता हूँ , जैसे{" "}
                      </label>
                      <input
                        className="form-set"
                        type="text"
                        placeholder=""
                        onChange={(e) => setAns6(e.target.value)}
                        value={ans6}
                      />

                      <label for="" className="laval-className">
                        आशा करती/करता हूँ कि जल्द ही हम मिलें और खूब मज़े करें।{" "}
                      </label>
                      <input
                        className="form-set"
                        type="text"
                        placeholder=""
                        onChange={(e) => setAns7(e.target.value)}
                        value={ans7}
                      />
                    </div>
                    <br></br>
                    <div className="form-div">
                      <input
                        className="form-set"
                        type="text"
                        placeholder=""
                        onChange={(e) => setAns8(e.target.value)}
                        value={ans8}
                      />
                      <label for="" className="laval-className">
                        (आपका नाम)
                      </label>
                    </div>

                    <div className="mt-15 mb-15 ">
                      {/* <NavLink
                        className="theme_btn theme_btn_bg chnage-d"
                        to="mpm-acitivity-completion-screen.html"
                      >

                      </NavLink> */}
                      <button
                        type="submit"
                        className="theme_btn theme_btn_bg chnage-d"
                      >
                        {" "}
                        आगे बढ़ेंं{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo06Activity04Screen;
