import React from 'react'
import { NavLink } from 'react-router-dom'

const Gyanbox03ActivityScreen = () => {
  return (
    <>
         <main>

        <section className="bg-boarding gerua">
           <div className="container-fluid cf-set">



           <div className="row align-items-center justify-content-center ">
                   <div className="col-lg-5 ">
                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />ज्ञान बॉक्स</h2>
                   </div>
                   <div className="col-lg-5">
                       <NavLink className="float-right width-auto" to="/karo-03-activity-09-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /> </NavLink>
                   </div>
               </div>


               <div className="row align-items-top justify-content-center mt-50">
                   <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <p className="mpara large-para gyanpara-large mt-40">कैसा लगा सफाई करके? आप सब यह कर सकते हैं, लड़का हो या लड़की। आस-पास का माहौल मन की स्थिति को भी दर्शाता है तो बहुत ज़रूरी है अपने आस-पास को भी साफ रखना। हमारे रहने के जगह को साफ़ रखना हमारी ज़िम्मेदारी है - जैसे कि  हमारा कमरा, घर, गली और मोहल्ला।</p>
                   <NavLink to="/karo-04-activity-01-screen" className="btnset "><span>आगे बढ़ें </span> </NavLink>
                   </div>
                   <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <NavLink to="skljk1.html"><center><img draggable="false" className="mb-15" width="80%" src="assets/img/general/safai-girl.svg" /></center></NavLink>
                   </div>

               </div>



           </div>
        </section>



   </main>
    </>
  )
}

export default Gyanbox03ActivityScreen