import React, { useEffect } from 'react'
import {Navigate,Outlet,useNavigate } from 'react-router-dom';

// import {Navigate,Outlet} from 'react-router-dom';
import BaseURL from './common/Baseurl';
import axios from 'axios';

const Protected = (props) => {
  const navigate = useNavigate();
  const {Component} = props;
  const login = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem("groupId");

  let userType = 'user';
  let user_id = login.user_id;
  if(login.role_id == 16)
  {
    userType = 'group';
    user_id = groupId;
  }


  useEffect(()=>{

    let data = JSON.stringify({
      "user_id": user_id
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: BaseURL+'/checkUserExist',
      headers: {
        "type":`${userType}`,
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      console.log(response.data)
      if(response.data.success === false)
      {
        // alert();
        navigate('/welcome')
        // return <Navigate to="/welcome" />
      }

      // return true
      // console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });

  });



  // if(login)
  // {
  //   useEffect(()=>{

  //     let data = JSON.stringify({
  //       "user_id": "341"
  //     });

  //     let config = {
  //       method: 'post',
  //       maxBodyLength: Infinity,
  //       url: BaseURL+'/checkUserExist',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       data : data
  //     };

  //     axios.request(config)
  //     .then((response) => {
  //       if(response.data.success == false)
  //       {
  //         return <Navigate to="/welcome" />
  //       }

  //       return <Component/>
  //       // console.log(JSON.stringify(response.data));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  //   });
  // }else{
  //   return <Navigate to="/welcome" />
  // }

  return login ? <Component/>: <Navigate to="/welcome" />


}

export default Protected