import React, { useEffect, useState } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Khelo13Activity13Screen = () => {
  const navigate = useNavigate();
  const [totalStar, setTotalStar] = useState();
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity13Result",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setTotalStar(response.data.data.totalstar);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleShow();
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);



  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-13-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "khelo",
          "activity_key_no": "khelo13",
          "activity_no": "13"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            "type":`${userType}`,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }



  var data1 = JSON.stringify({
    user_id: user_id,
  });





  const ShowMsg = () => {
    if (totalStar > 0) {
      return (
        <>
          <h1
            className="bahut-khub fs-m-4 mt-m-1 mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            शाबाश!
          </h1>
          <div
            className="six-tare mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >

            <h1 className="rh1">आपकी राय जान कर अच्छा लगा </h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{totalStar}</h4>
              </li>
            </ul>
          </div>
        </>
      )
    } else if(totalStar === 0) {
      return (
        <>


          <div
            className="six-tare mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
             <h1 className="rh1">आपकी राय जान कर अच्छा लगा </h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{totalStar}</h4>
              </li>
            </ul>
          </div>
        </>
      )
    }
  }


  return (
    <>
      <main>
        <section className="bg-flexible E8FEFF">
          <div className="fixed-header">
            <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">

                  <ShowMsg/>
                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div>


                        <NavLink onClick={retryActivity} to="#">
                                  <center>
                                    <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                                  </center>
                                  <p className="side-btn">दुबारा कोशिश   करें</p>
                                </NavLink>

                      </div>
                    </div>
                    <div>
                      <div>
                        <NavLink to="/board-screen" className="btnset ">
                          <span>अगली एक्टिविटी </span>{" "}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 mt-m-2 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/kab-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup pt-m-3">
              <div className="container-fluid cf-set">

                <div className="">
                  <div className="row mt-0">
                    <div
                      className="col-lg-12"
                    >
                      <p className="mpara large-para mb-0 fs-30">
                        आप सबसे पहले नीचे दिए गए सुझावों को ध्यान से पढ़िए।{" "}
                      </p>
                      <p className="mpara large-para fs-30 ">
                        यह सुझाव आपको यह समझाने के लिए दिए गए हैं कि आप सुरक्षित स्पर्श और
                        असुरक्षित स्पर्श में अंतर समझ पाए।{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-0">
                    <div
                      className="col-lg-4 kab-p-30"
                    >
                      <div className="dekho-gyan-box kab-gayan">
                        <div className="gyan-box-img">
                          <center>
                            <img src="assets/img/khelo/kab-g1.svg" alt="" />
                          </center>
                        </div>
                        <p>
                        क्या कोई भी हमें कन्धे पर थपथपा सकता है? <br></br> <b>सुझाव -</b> यदि आपको इस स्पर्श से कुछ बुरा महसूस
                          नहीं हो रहा तो हाँ।{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 kab-p-30"
                    >
                      <div className="dekho-gyan-box kab-gayan">
                        <div className="gyan-box-img">
                          <center>
                            <img src="assets/img/khelo/kab-g2.svg" alt="" />
                          </center>
                        </div>
                        <p>
                          {" "}
                          क्या कोई हमारे बाल खींच सकता है चाहे मम्मी-पापा ही
                          क्यों न हों ? <br></br> <b>सुझाव -</b> बिलकुल नहीं, किसी भी व्यक्ति को
                          जाने अनजाने हमें चोट पहुँचाने या कोई भी दुःख देने का
                          अधिकार नहीं है।
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 kab-p-30 "
                    >
                      <div className="dekho-gyan-box kab-gayan">
                        <div className="gyan-box-img">
                          <center>
                            <img src="assets/img/khelo/kab-g3.svg" alt="" />
                          </center>
                        </div>
                        <p>
                          {" "}
                          क्या कोई हमें गोद में उठा सकता है, खेल के अलावा?
                          <br></br> <b>सुझाव -</b> यदि आपको किसी का यह व्यवहार पसंद नहीं आये तो
                          बिलकुल नहीं।{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 kab-p-30"
                    >
                      <div className="dekho-gyan-box kab-gayan">
                        <div className="gyan-box-img">
                          <center>
                            <img src="assets/img/khelo/kab-g4.png" alt="" />
                          </center>
                        </div>
                        <p>
                          क्या कोई हमें धक्का दे सकता है? <br></br> <b>सुझाव -</b> बिलकुल नहीं,
                          किसी भी व्यक्ति को जाने अनजाने हमें चोट पहुँचाने या
                          कोई भी दुःख देने का अधिकार नहीं है।{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 kab-p-30"
                    >
                      <div className="dekho-gyan-box kab-gayan">
                        <div className="gyan-box-img">
                          <center>
                            <img src="assets/img/khelo/kab-g5.svg" alt="" />
                          </center>
                        </div>
                        <p>
                          {" "}
                          क्या कोई हमें गाली या अपशब्द बोल सकता है?<br></br><b> सुझाव -</b>
                          बिलकुल नहीं, किसी भी व्यक्ति को हमें गाली देने या अपशब्द बोलने का अधिकार नहीं है।

                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 kab-p-30 "
                    >
                      <div className="dekho-gyan-box kab-gayan">
                        <div className="gyan-box-img">
                          <center>
                            <img src="assets/img/khelo/kab-g6.png" alt="" />
                          </center>
                        </div>
                        <p>
                          {" "}
                          क्या कोई भरोसेमंद व्यक्ति हमें गले लगा सकता है?<br></br> <b>सुझाव -</b> यदि आपको इस
                          स्पर्श से कुछ बुरा महसूस नहीं हो रहा तो हाँ।{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 kab-p-30 "
                    >
                      <div className="dekho-gyan-box kab-gayan">
                        <div className="gyan-box-img">
                          <center>
                            <img src="assets/img/khelo/kab-g7.svg" alt="" />
                          </center>
                        </div>
                        <p>
                          {" "}
                          क्या कोई धोखे या चालाकी से, जबरदस्ती और बहला - फुसला कर आपका हाथ या शरीर पकड़ सकता है ?
                          <br></br> <b>सुझाव -</b> यह असुरक्षित स्पर्श है| किसी भरोसेमंद बड़े व्यक्ति को तुरंत इस बारे में बतायें या 1098 हेल्पलाइन नंबर पर फ़ोन कर बतायें।

                        </p>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 kab-p-30"
                    >
                      <div className="dekho-gyan-box kab-gayan">
                        <div className="gyan-box-img">
                          <center>
                            <img src="assets/img/khelo/kab-g8.png" alt="" />
                          </center>
                        </div>
                        <p>
                          {" "}
                          क्या कोई हमसे हाथ मिला सकता है?<br></br><b>सुझाव -</b>  यदि आपको इस स्पर्श
                          से कुछ बुरा महसूस नहीं हो रहा तो हाँ।{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo13Activity13Screen;
