import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Karo06Activity02Screen = () => {
  const [lg, setLg] = useState("col-lg-5");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  return (
    <>
      <main>
        <section className="bg-flexible karo">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">

          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-40">
              <div
                className="col-lg-4  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h1 className="h1 ">करो</h1>
                <NavLink to="/karo-06-activity-03-screen">
                  <img
                    width="100%"
                    src="assets/img/karo/mpm-splash.svg"
                    alt=""
                    draggable="false"
                  />
                </NavLink>
              </div>
              <div
                className="col-lg-4 offset-lg-1  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    className="mt-0"
                    width="100%"
                    src="assets/img/karo/mera-priye-mitr.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo06Activity02Screen;
