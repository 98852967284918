import React, { useState, useEffect } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink } from "react-router-dom";
import BtnDisabled from "../common/BtnDisabled";

const Khelo08Activity08Screen = () => {
  const [ansResult, setAnsResult] = useState();
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity08ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const ans_result = response.data.data.ans_result;
          setAnsResult(ans_result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // console.log(ansResult);

  const AnsResult = () => {
    if (ansResult === "wrong") {
      return (
        <>
          <div
            className=" mt-15"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <button type="button" className="galat-btn">
              {" "}
              <img src="assets/img/khelo/icon/close.svg" alt="" /> गलत{" "}
            </button>
          </div>

          <div
            className="gif-box-set gif-full-width F8D3D3 pt-30 mt-40  wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <center>
              <img width="80%" src="assets/img/khelo/gif/karya-karna-sahi.gif" alt="" />
            </center>
          </div>
        </>
      );
    } else if (ansResult === "correct")  {
      return (
        <>
          <div
            className=" mt-15"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <button type="button" className="galat-btn A777">
              {" "}
              <img src="assets/img/khelo/icon/sahi.svg" alt="" />
              सही{" "}
            </button>
          </div>

          <div
            className="gif-box-set gif-full-width D3F8D9 pt-30 mt-40  wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <center>
              <img width="80%" src="assets/img/khelo/gif/karya-karna-sahi.gif" alt="" />
            </center>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible F0F4FF">
          <div className="fixed-header">
              <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-3 mt-m-2">
                  <AnsResult />
                </div>
                <div className="col-lg-3 mt-m-2 offset-lg-1">
                  <h1
                    className="h2 text-center  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    यह कार्य कोई भी कर सकता है, लड़का हो या लड़की
                  </h1>
                  <div
                    className="mt-40 wow fadeInDown m-auto "
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink
                      to="/khelo-08-activity-09-screen"
                      className="btnset m-auto"
                    >
                      <span>अगला सवाल </span>{" "}
                    </NavLink>
                  </div>
                </div>
                <div
                  className="col-lg-4 mt-m-2 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="80%"
                      src="assets/img/khelo/icon/kkkn-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo08Activity08Screen;
