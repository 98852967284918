import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo03Activity01Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
        <Name />
          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <h1 className="h1 mb-20 mt-m-1">खेलो</h1>
                <div
                  className="karo-activity-barabri wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <h4>आज मैं ऊपर</h4>
                  <img
                    width="75%"
                    src="assets/img/khelo/aaj-main-upar-chune.svg"
                    alt=""
                  />
                  <div className="mt-0">
                    <NavLink
                      to="/khelo-03-activity-02-screen"
                      className="btnset radius-set m-auto"
                    >
                      <span>चुनें</span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 mt-m-1 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="100%"
                    src="assets/img/khelo/aaj-main-upar-main.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo03Activity01Screen;
