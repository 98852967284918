import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ProfileMenu from "./common/ProfileMenu";
import MultiRangeSlider from "./common/MultiRangeSlider";
// import '../components/common/RangeSlider.css';
import axios from "axios";
import BaseURL from "./common/Baseurl";

const ChooseDays = () => {

  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [time, setTime] = useState();
  const [rangetime, setrangeTime] = useState();
  const [schecked,setSChecked] = useState();
  const [mchecked,setMChecked] = useState();
  const [tchecked,setTChecked] = useState();
  const [wchecked,setWChecked] = useState();
  const [thuchecked,setTHUChecked] = useState();
  const [fchecked,setFChecked] = useState();
  const [satchecked,setSATChecked] = useState();
  // const [days, setDays] = useState([]);
  const [daysNames, setDaysNames] = useState([]); //days name in array
  const [weekly_target,setWeekly_target] = useState();
  const [checkedAllDays,setCheckedAllDays] = useState('');

  const user_details = JSON.parse(localStorage.getItem("user_details"));



  useEffect(()=>{
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "weekly_target": "",
      "days": "",
      "time": ""
    });

    
    var config = {
      method: "post",
      url: BaseURL+"/weeklyTarget",
      headers: { 
        'Authorization': `Bearer ${user_details.token} `, 
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
      
        if(response.data.success === true)
        {
          const weekly_target = response.data.data.weekly_target
          setWeekly_target(weekly_target);
          const __selectDays = response.data.data.days;
          const __time = response.data.data.time;
          setTime(__time);
          const selectDays = __selectDays.split(",");
          if(selectDays.length === 7)
          {
            setCheckedAllDays('checked');
          }
          selectDays.map((val)=>{

            checkDaysSelect(val,'checked');
          })
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  },[])


 


  //save data in database


  const saveData = ()=>{
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "weekly_target": rangetime,
      "days": daysNames.join(),
      "time": time
    });

    
    var config = {
      method: "post",
      url: BaseURL+"/weeklyTarget",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        navigate('/flash-screen');
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }




  const saveDays = (e) => {
    
    const checked = e.target.checked;
    const days = e.target.value;
    if(checked === true)
    {
      setDaysNames(daysNames=>[...daysNames,days]);

      checkDaysSelect(days,'checked');
    }

    if(checked === false)
    {
      // setDaysNames(current=>[...current,days]);

      //remove element from array

      setDaysNames((daysNames) =>
      daysNames.filter((val) => {
        return val !== days;
      })
    );


      checkDaysSelect(days,'');
    }


    
    

   

    
  };

  console.log(daysNames);

  const saptakhikLakshya = (e) => {
    const range = e.target.value;

    setrangeTime(range);


  };

  const saveTime = (e)=>{
    const time = e.target.value;
    console.log(time);
  }


  // console.log(rangetime);


  const checkAllDays = (e)=>{

    const checkedStatus = e.target.checked;

    if(checkedStatus === true)
    {
      setCheckedAllDays('checked');

      setSChecked("checked");
      setMChecked("checked");
      setTChecked("checked");
      setWChecked("checked");
      setTHUChecked("checked");
      setFChecked("checked");
      setSATChecked("checked");
  
      const days = e.target.value;

      setDaysNames(current=>[...current,days]);

     
    }

    if(checkedStatus === false)
    {
      setCheckedAllDays('');
      setSChecked("");
      setMChecked("");
      setTChecked("");
      setWChecked("");
      setTHUChecked("");
      setFChecked("");
      setSATChecked("");

      setDaysNames(current=>[]);

    
  
    
    }


    


   
   
  

  
 

  }

  const checkDaysSelect=(days,selectChecked)=>{
    if(days === 'S')
    {
      setSChecked(selectChecked);
    }
    if(days === 'M')
    {
      setMChecked(selectChecked);
    }
    if(days === 'T')
    {
      setTChecked(selectChecked);
    }
    if(days === 'W')
    {
      setWChecked(selectChecked);
    }
    if(days === 'THU')
    {
      setTHUChecked(selectChecked);
    }
    if(days === 'F')
    {
      setFChecked(selectChecked);
    }
    if(days === 'SAT')
    {
      setSATChecked(selectChecked);
    }
  }


  const setTimer = (e)=>{
    // alert(e.target.value);
    setTime(e.target.value);


  }


  // console.log(time);



  return (
    <>
      <main>
        <section className="bg-flexible ">
            <ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust heightset-87">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-between ">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <h4 className="h4 mb-40">
                  अपना साप्ताहिक लक्ष्य निर्धारित करें
                </h4>
              </div>

              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <form>
                  <div className="slider">

                  <>
  <span className="min-value">15</span>
  <div className="range">
    <ul className="manage-range-slider d-flex justify-content-around bd-highlight">
      <li>
        15 मिनट <span />
      </li>
      <li>
        30 मिनट <span />
      </li>
      <li>
        45 मिनट <span />
      </li>
      <li>
        60 मिनट <span />
      </li>
      <li>
        75 मिनट <span />
      </li>
      <li>
        90 मिनट <span />
      </li>
    </ul>
    {/* <input type="range" name="date" id="dae1" min="30" max="300" step="5" value="50" required> */}
    <input
      type="range"
      id="rango2"
      min={15}
      max={90}
      defaultValue={(weekly_target === '')?15:weekly_target}
      step={15}
      list="listo"
      onChange={saptakhikLakshya}
    />
    <datalist id="listo">
      <option value={20}   />
      <option value={40} />
      <option value={60}  />
      <option value={80} />
      <option value={100} />
    </datalist>
    <span className="current-value">{(weekly_target === '')?15:weekly_target}</span>
    <ul className="aams mt-60 d-flex justify-content-around bd-highlight">
      <li>
        आसान <span />
      </li>
      <li>
        अनुशंसित <span />
      </li>
      <li>
        मुश्किल <span />
      </li>
    </ul>
  </div>
  <span className="max-value">90</span>
</>


                  {/* fasdf */}

                  </div>
                </form>
              </div>

              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                <h6
                  className="h6 mt-m-1 red text-center wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  अभ्यास के दिन चुनें
                </h6>

                <div
                  className="radiobuttons d-flex mt-m-2 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <div
                    className="rdio all-lavel rdio-primary radio-inline d-flex"
                    style={{ margin: "0px auto" }}
                  >
                    <input  value="S,M,T,W,THU,F,SAT" id="radio1" type="checkbox" onClick={checkAllDays} checked={checkedAllDays} />
                    <label for="radio1">All</label>
                  </div>
                </div>

                <ul className="aams d-flex justify-content-around bd-highlight">
                  <li>
                    <div className="content-area mt-0">
                      <div className="invisible-checkboxes btn-select daysbtn">
                        <input
                          type="checkbox"
                          name="days"
                          value="S"
                          id="dyas1"
                          onClick={saveDays}
                          checked={schecked}
                        />
                        <label
                          className="checkbox-alias activity-btn days-color"
                          for="dyas1"
                        >
                          <h4 className="slider-h"> S </h4>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content-area mt-0">
                      <div className="invisible-checkboxes btn-select daysbtn">
                        <input
                          type="checkbox"
                          name="days"
                          value="M"
                          id="dyas2"
                          onClick={saveDays}
                          checked={mchecked}
                        />
                        <label
                          className="checkbox-alias activity-btn days-color"
                          for="dyas2"
                        >
                          <h4 className="slider-h"> M </h4>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content-area mt-0">
                      <div className="invisible-checkboxes btn-select daysbtn">
                        <input
                          type="checkbox"
                          name="days"
                          value="T"
                          id="dyas3"
                          onClick={saveDays}
                          checked={tchecked}
                        />
                        <label
                          className="checkbox-alias activity-btn days-color"
                          for="dyas3"
                        >
                          <h4 className="slider-h"> T </h4>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content-area mt-0">
                      <div className="invisible-checkboxes btn-select daysbtn">
                        <input
                          type="checkbox"
                          name="days"
                          value="W"
                          id="dyas4"
                          onClick={saveDays}
                          checked={wchecked}
                        />
                        <label
                          className="checkbox-alias activity-btn days-color"
                          for="dyas4"
                        >
                          <h4 className="slider-h"> W </h4>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content-area mt-0">
                      <div className="invisible-checkboxes btn-select daysbtn">
                        <input
                          type="checkbox"
                          name="days"
                          value="THU"
                          id="dyas5"
                          onClick={saveDays}
                          checked={thuchecked}
                        />
                        <label
                          className="checkbox-alias activity-btn days-color"
                          for="dyas5"
                        >
                          <h4 className="slider-h"> T </h4>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content-area mt-0">
                      <div className="invisible-checkboxes btn-select daysbtn">
                        <input
                          type="checkbox"
                          name="days"
                          value="F"
                          id="dyas6"
                          onClick={saveDays}
                          checked={fchecked}
                          
                        />
                        <label
                          className="checkbox-alias activity-btn days-color"
                          for="dyas6"
                        >
                          <h4 className="slider-h"> F </h4>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="content-area mt-0">
                      <div className="invisible-checkboxes btn-select daysbtn">
                        <input
                          type="checkbox"
                          name="days"
                          value="SAT"
                          id="dyas7"
                          onClick={saveDays}
                          checked={satchecked}
                        />
                        <label
                          className="checkbox-alias activity-btn days-color"
                          for="dyas7"
                        >
                          <h4 className="slider-h"> S </h4>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3">
                <h6
                  className="h6 mt-m-2 red text-center wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  समय चुने
                </h6>

                <div
                  className="input-group mb-15 mt-15"
               
                >
                  <div className="input right green-shodow-input text-set">
                    <select
                      name="cars"
                      className="set-input"
                      value={time}
                     
                      style={{ "margin-top": "0.6vw" }}
                      onChange={setTimer}
                    >
                      <option value="01" >01 : 00 AM</option>
                      <option value="02">02 : 00 AM</option>
                      <option value="03">03 : 00 AM</option>
                      <option value="04" >04 : 00 AM</option>
                      <option value="05" >05 : 00 AM</option>
                      <option value="06" >06 : 00 AM</option>
                      <option value="07" >07 : 00 AM</option>
                      <option value="08" >08 : 00 AM</option>
                      <option value="09" >09 : 00 AM</option>
                      <option value="10" >10 : 00 AM</option>
                      <option value="11" >11 : 00 AM</option>
                      <option value="12" >12 : 00 PM</option>
                      <option value="13" >13 : 00 PM</option>
                      <option value="14" >14 : 00 PM</option>
                      <option value="15" >15 : 00 PM</option>
                      <option value="16" >16 : 00 PM</option>
                      <option value="17" >17 : 00 PM</option>
                      <option value="18" >18 : 00 PM</option>
                      <option value="19" >19 : 00 PM</option>
                      <option value="20" >20 : 00 PM</option>
                      <option value="21" >21 : 00 PM</option>
                      <option value="22" >22 : 00 PM</option>
                      <option value="23" >23 : 00 PM</option>
                      <option value="24" >24 : 00 PM</option>
                    </select>
                  </div>
                </div>


                

                

                <div
                  className="d-flex wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <div
                    className="dispalyset  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.7s"
                  >
                    <NavLink
                      to="#"
                      className="btnset m-auto "

                      onClick={saveData}
                    >
                      <span>आगे </span>{" "}
                    </NavLink>
                    {/* <NavLink
                      to="#"
                      className="baadmain"
                      style={{ width: "60%" }}
                    >
                      बाद मे करें
                    </NavLink> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ChooseDays;
