import React from 'react';
import { NavLink } from 'react-router-dom';

import Menu from './Menu';


const Header = () => {
  return (
    <>
    <header id="top-menu">
        <div className="main-header-area ">
            <div className="container-fluid cf-set">
                <div className="row align-items-center justify-content-between">
                    <div className="col-xl-2 col-lg-2 col-md-6 col-6">
                        <div className="logo">
                            <NavLink className="logo-img" to="/"><img alt="logo" src="../../assets/img/logo/header_logo_one.jpg"
                                     /></NavLink>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-6 d-none d-lg-block text-lg-center text-xl-right">
                        <div className="main-menu main-menu-03 d-none d-lg-block">
                           <Menu/>
                         
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 col-6 text-right" styleName=""> 
                        <div className="quote-btn d-none d-lg-block">
                            <NavLink to="/login" className="font-set"><span><img alt="logo" src="../../assets/img/icon/enter.svg" width="20px;" /></span>&nbsp;&nbsp;&nbsp;LOGIN/SIGN UP</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </>
  )
}

export default Header