import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";

const Khelo09Activity07Screen = () => {

  const users = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = users.user_id;
  if(users.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  const navigate = useNavigate();
  const saveAns = () => {

    var data = JSON.stringify({
      user_id: user_id,
      answers: {
        ques1: {
          ans: "",
          ques_id: "",
        },
        ques2: {
          ans: "na",
          ques_id: "2",
        },
        ques3: {
          ans: "",
          ques_id: "",
        },
      },
    });

    var config = {
      method: "post",
      url: BaseURL+"/kheloActivity09_1",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        handleClose()
        navigate('/khelo-09-activity-09-screen');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const goToNextPage = ()=>{
    handleShow()
  }

  const haAns = ()=>{
    handleClose()
    navigate('/khelo-09-activity-08-screen');
  }



  return (
    <>
      <main>
        <section className="bg-flexible bg-img-mobile khelo-bg mblh-9">
          <div className="fixed-header">
            <Name/>
          </div>
          <div
            className="footer-back mb-30 agge-bade-btn-set "

          >
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center ">
                <div className="col-lg-12">
                  <div className="mr-20">
                    {/* <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="btnset m-auto"><span>आगे</span> </a> */}
                    <button
                      type="button"
                      className="btnset m-auto"

                      onClick={goToNextPage}

                    >
                      आगे
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={show}
          className="popup-Yes-No"

          >
            <div
              className="modal-dialog modal-dialog-centered modal-sm my-small-m"
              role="document"
            >
              <div className="modal-content small-poppu-r">
                <div className="modal-body body-small text-center">
                  {/* <button type="button" class="close close-my" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> */}
                  <h4 className="h1-set-popup"> क्या पंकज वो रास्ता
                    लेगा?{" "}</h4>
                  <div>
                    <button   onClick={haAns} className="yes-or-no-btn">
                      हाँ{" "}
                    </button>
                    <button  onClick={saveAns} className="yes-or-no-btn">
                    नहीं
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>


        </section>
      </main>
    </>
  );
};

export default Khelo09Activity07Screen;
