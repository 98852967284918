import React,{useState} from 'react';
import { NavLink } from 'react-router-dom';
import HomeMenu from '../common/HomeMenu';
import HomeMenuOuter from '../common/HomeMenuOuter';

const Signin = () => {
    
  const [lg,setLg] = useState('col-lg-9')
  return (
    <>
        
    <main>
		
        <section className="bg-flexible">
          
          
           <HomeMenuOuter  />
          <div class="data-adjust">
           <div className="container-fluid cf-set">
               <div className="row align-items-center justify-content-center">
                  
                   <div className="col-lg-4">
                       <h3 className="main-headding wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">साइन अप </h3>


                       <div className="input-group wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                           <label className="levelset ">अपना नाम लिखें </label>
                           <div className="input right">
                               <input type="text" className="set-input" placeholder="पूरा नाम " />
                           </div>
                       </div>
                       
                       <div className="input-group mt-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <label className="levelset ">आपकी जन्म तिथि क्या है?</label>
                           <div className="input right green-shodow-input">
                               <input type="date" className="set-input" placeholder="पूरा नाम " />
                           </div>
                       </div>


                       

                       <p className="mpara mt-15 ml-10 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">अगर आप 18 साल से कम के हैं तो आपको अपने पेरेंट्स या गार्डियन की तरफ से वन टाइम ऑथराइजेशन (OTA) चाहिए होगा। </p>

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                           <NavLink to="/parent-signup" className="btnset m-auto blue-shadow "><span>आगे बढ़ेंं  </span> </NavLink>
                       </div>
                      
                       

                   </div>
                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                       <center><img draggable="false" className="mt-50" width="95%" src="assets/img/general/sign-up.svg"  /></center>
                       
                   </div>
               </div>
           </div>
           </div>
        </section>
   
       

   </main>
    </>
  )
}

export default Signin