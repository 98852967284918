import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo02Activity02Screen = () => {
    
    const [lg,setLg] = useState("col-lg-5");
    const [rowAllgn, setrowAllgn] = useState(
        "row align-items-center justify-content-center"
      );
      const [faqsize, setfaqsize] = useState('col-lg-4');
  return (
        <>
    <main>
        <section className="bg-boarding karo">
           <div className="container-fluid cf-set">

           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
               <div className="row align-items-center justify-content-center">
                   <div className="col-lg-4">
                       <h1 className="h1 mb-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">करो</h1>
                       <div className="karo-activity-barabri wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s" style={{"padding": "5vw 2vw;"}}> 
                           <NavLink className="width-p" to="/karo-02-activity-03-screen">
                               <h4>मैं भी सुन्दर हूँ </h4>
                               <img className="mt-30" width="100%" src="assets/img/general/mbsh-chune.svg" alt="" />
                           </NavLink>
                       </div>

                       {/* <!-- <a href="mbsh-Info-screen.html"><img src="assets/img/karo/main-bhi-sunder-hun-sticker.svg" alt="" draggable="false"></a> --> */}
                   </div>
                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <center><img draggable="false" className="0" width="100%" src="assets/img/on-boarding/pinky-and-punkaj.svg" /></center>
                   </div>
               </div>
           </div>
        </section>
   
       

   </main>
   

        </>
  )
}

export default Karo02Activity02Screen