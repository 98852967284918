import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Faq1 = () => {
  const [serachInput, setSearchInput] = useState();

  const searchFaq = (e) => {
    e.preventDefault();
    console.log(serachInput);
  };
  return (
    <>
      <main>
        <section className="bg-boarding">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center ">
              <div className="col-lg-6">
                <h2 className="h2 kkh-text">
                <NavLink to="/welcome" className="width-auto">
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  </NavLink>
                  FAQs
                </h2>
              </div>
              <div className="col-lg-4">
                <NavLink
                  className="float-right width-auto"
                  to="/faq1"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div className="row align-items-center justify-content-center">
              <div
                className="col-lg-5 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <div className="box-radius chat-type">
                  <div className="content-area-chat">
                    <div className="chat-me ">
                      <div className="float-right">
                        <p className="">How to login?</p>
                      </div>
                    </div>
                    {/* <!-- <div className="chat-you">
                                   <p>Couldn't find any match of your question. Your query has been submitted, we'll get back to you soon.</p>
                               </div> --> */}
                  </div>
                  <div className="chat-area d-flex">
                    <input
                      type="text"
                      name=""
                      className="chat-input"
                      id=""
                      placeholder="Type Anything........"
                    />
                    <NavLink to="faqs2.html" className="send-icon">
                      <img src="assets/img/icon/send.svg" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.6s"
              >
                <center>
                  <img
                    draggable="false"
                    className="mb-15"
                    width="100%"
                    src="assets/img/karo/apsi-muthbhed.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>

      <form onSubmit={searchFaq}>
        <main>
          <section className="bg-boarding">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center ">
                <div className="col-lg-10">
                  <h2 className="h2 kkh-text">
                  <NavLink to="/welcome" className="width-auto">
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    </NavLink>
                    FAQs
                  </h2>
                </div>
                {/* <div className="col-lg-4">
                       <NavLink className="float-right width-auto" to="skljk-info-screen.html"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div> */}
              </div>

              <div className="row align-items-center justify-content-center">
                <div
                  className="col-lg-5 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <div className="box-radius chat-type">
                    <div className="content-area-chat">
                      <div className="chat-me ">
                        <div className="float-right">
                          <p className="">How to login?</p>
                        </div>
                      </div>
                      <div className="chat-you">
                        <p>
                          यदि आपको अभी भी अपना उत्तर नहीं मिला है, तो कृपया
                          सबमिट बटन पर क्लिक करें।
                        </p>
                      </div>
                    </div>
                    <NavLink  to="/faq2" >
                    <div className="chat-area d-flex">
                      <input
                        type="text"
                        name=""
                        className="chat-input"
                        id=""
                        placeholder="Type Anything........"
                        value={serachInput}
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />

                      <NavLink 
                         to="/faq2"
                         className="send-icon">
                        <img src="assets/img/icon/send.svg" alt="" />
                      </NavLink>
                    </div>
                    </NavLink>
                  </div>
                </div>

                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.6s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mb-15"
                      width="100%"
                      src="assets/img/karo/apsi-muthbhed.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};

export default Faq1;
