import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import swal from "sweetalert";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  const [isVisible1, setVisible1] = useState(false);

  const toggle1 = () => {
    setVisible1(!isVisible1);
  };

  const [isVisible2, setVisible2] = useState(false);

  const toggle2 = () => {
    setVisible2(!isVisible2);
  };


  

  const users = JSON.parse(localStorage.getItem('user_details'));
  const updatePassword = (e) => {
      e.preventDefault();

     

      const  password_validate = (password) => {
        var re = {
            capital: /(?=.*[A-Z])/,
            length: /(?=.{7,20}$)/,
            specialChar: /[ -\/:-@\[-\`{-~]/,
            digit: /(?=.*[0-9])/,
        };
        return (
            re.capital.test(password) &&
            re.length.test(password) &&
            !re.specialChar.test(password) &&
            re.digit.test(password)
        );
    };
    
         var checkpas =  password_validate(newPassword)
          if (!checkpas) {
            
            swal(
              ' कृपया पासवर्ड में एक संख्यात्मक संख्या, एक अपरकेस, एक लोअरकेस का उपयोग करें या विशेष वर्ण का उपयोग न करें, उदाहरण के लिए @!#$%^&*()" आदि। पासवर्ड 8 अक्षर के बराबर या उससे अधिक होना चाहिए।',
              " ",
             
              "warning"
              );
  
              return false;

          }



    var data = JSON.stringify({
      currentPassword: oldPassword,
      password: newPassword,
      confirmPassword: confirmPassword,
      user_id: users.user_id,
    });

    var config = {
      method: "post",
      url: BaseURL+"/changePassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

          const msg = response.data.message;
        

        if(response.data.success === true)
        {
          
            window.localStorage.clear();

           navigate('/login')
           
        }else{
           
            swal(
            msg,
            " ",
            "warning"
            );
        }
        
      
      })
      .catch(function (error) {
        console.log(error);
      });
  };

 


  return (
    <>
      <main>
        <section className="bg-flexible general-bg">
          <div className="">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 ">
                <div className="col-lg-7">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    अकाउंट{" "}
                  </h2>
                </div>
                <div className="col-lg-5">
                  <NavLink
                    className="float-right width-auto"
                    to="/account-list"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <form action="" onSubmit={updatePassword}>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row mt-30 align-items-center justify-content-center ">
                <div
                  className="col-lg-8 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="row ">
                    <div className="col-lg-6 wow fadeInDown">
                      <div className="input-group mt-20">
                        <label className="levelset ">
                          पुराना पासवर्ड डालिये
                        </label>
                        <div className="input none-shadow-set right height-100">
                          <input
                            className="set-input ml-20"
                            type={!isVisible1 ? "password" : "text"}
                            placeholder="**********"
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                          />

                          <span
                              onClick={toggle1}
                              style={{
                                "margin-right": "5px",
                                "margin-top": "10px",
                              }}
                            >
                              {isVisible1 ? (
                                <i className="fa fa-eye fa-2x text-black"></i>
                              ) : (
                                <i className="fa fa-eye-slash fa-2x text-black"></i>
                              )}
                            </span>

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow fadeInDown">
                      <div className="input-group mt-20">
                        <label className="levelset ">नया पासवर्ड डालिये</label>
                        <div className="input none-shadow-set right height-100">
                          <input
                            className="set-input ml-20"
                            type={!isVisible ? "password" : "text"}
                            placeholder="**********"
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />

                        <span
                              onClick={toggle}
                              style={{
                                "margin-right": "5px",
                                "margin-top": "10px",
                              }}
                            >
                              {isVisible ? (
                                <i className="fa fa-eye fa-2x text-black"></i>
                              ) : (
                                <i className="fa fa-eye-slash fa-2x text-black"></i>
                              )}
                            </span>

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow fadeInDown">
                      <div className="input-group mt-20">
                        <label className="levelset ">
                          वापस नया पासवर्ड डालिये
                        </label>
                        <div className="input none-shadow-set right height-100">
                          <input
                            className="set-input ml-20"
                            type={!isVisible2 ? "password" : "text"}
                            placeholder="**********"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />

                          <span
                              onClick={toggle2}
                              style={{
                                "margin-right": "5px",
                                "margin-top": "10px",
                              }}
                            >
                              {isVisible2 ? (
                                <i className="fa fa-eye fa-2x text-black"></i>
                              ) : (
                                <i className="fa fa-eye-slash fa-2x text-black"></i>
                              )}
                            </span>

                        </div>
                      </div>
                    </div>

                    <div className="input-group mt-20">
                      <button
                        type="submit"
                        className="btnset"
                        
                      >
                        <span>पासवर्ड बदलें</span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      className={0}
                      width="90%"
                      src="assets/img/on-boarding/pinky-and-punkaj.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
          </form>
        </section>
      </main>
    </>
  );
};

export default ChangePassword;
