import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BaseURL from './common/Baseurl';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeMenu from './common/HomeMenu';


const Avtar = () => {

    const navigate = useNavigate();
    const [lg,setLg] = useState('col-lg-6')
    const [rowAllgn, setrowAllgn] = useState(
        "row align-items-center justify-content-center"
      );
      const [faqsize, setfaqsize] = useState("col-lg-6");
    const [items,setItems] = useState([]);
    const [userDetails,setUserDetails] = useState([]);
    const [checkBlankData,setCheckBlankData] = useState("blank");
    const users = JSON.parse(localStorage.getItem('user_details'));

    useEffect(()=>{

        if(users)
        {
            setUserDetails(users);
        }

        axios.get(`${BaseURL}/getavtars`).then((response)=>{
            setItems(response.data.data);
        }).catch(err=>{
            console.log(err);
        })
    },[]);

    // console.log(items);

    const selectAvtar = (e)=>{
        const avtar_id = e.target.getAttribute("data-id");
        const user_id = userDetails.user_id;
        setCheckBlankData('filled');
        // console.log(user_id);
        const data = JSON.stringify({
            "user_id":user_id,
            "avtar_id":avtar_id,
        });

        const config = {
            method:'post',
            url: BaseURL + "/avtar_user",
            headers: {
                'Content-Type': 'application/json'
              },
              data : data


        }

        axios(config).then((response)=>{
            console.log(response);

        }).catch(err=>{
            console.log(err)
        })


    }

    console.log(users);

    const submitResult = ()=>{

      if(users.role_id == "16")
      {
        navigate('/add-group');
      }else{

        navigate('/choose-days')
      }
    }

    const BtnDisabled = () => {
        if (checkBlankData !== "blank") {
          return (
            <>

                <div


                >


                  <button
                    className="btnset m-auto"
                    onClick={submitResult}
                  >
                    {" "}
                    आगे{" "}
                  </button>

                </div>


            </>
          );
        } else {
          return (
            <>
              <div


              >


                <button class="btnset m-auto disabled-btn" type="button" disabled>
                आगे{" "}
                </button>
              </div>
            </>
          );
        }
      };


  return (
    <>
         <main>

        <section className="bg-flexible">

           <HomeMenu/>
          <div class="data-adjust">
           <div className="container-fluid cf-set">
               <div className="row align-items-center justify-content-center">
                   <div className="col-lg-12">
                       <h1 className="h1 mb-40 mt-m-1">अपना अवतार चुनिए </h1>
                   </div>
               </div>
                   <div className="row-my-list align-items-center justify-content-between checkbox-effect rowcheck">


                   {
                        items.map((item)=>{
                            return(
                                <>
                                    <li className=" wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s" key={item.id}>
                                        <input type="radio" name="avtar" id={"myCheckbox"+item.id} onClick={selectAvtar} data-id={item.id} />
                                        <label className="avtar-img"  for={"myCheckbox"+item.id}>
                                        <img alt='logo' src={item.avtar} /></label>
                                    </li>
                                </>
                            )
                        })
                    }



                   </div>


                   <BtnDisabled/>
                   </div>




           </div>
        </section>



   </main>


    </>
  )
}

export default Avtar