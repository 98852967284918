import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo13Activity03_2Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible E8FEFF">
          <div className="fixed-header">
          <Name/>
            <div className="container-fluid cf-set">

            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center mt-20">
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h1
                    className="h1 mb-20 mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    दुसरा स्तर
                  </h1>
                  <NavLink to="/khelo-13-activity-05-screen">
                    <img
                      className="mt-10"
                      src="assets/img/khelo/kab-dusra-star.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/kab-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo13Activity03_2Screen;
