import React from 'react'
import { NavLink } from 'react-router-dom'

const Kyakrna03_1ActivityScreen = () => {
  return (
    <>
          <main>

        <section className="bg-boarding bg-flexible pt-m-2 karo">
           <div className="container-fluid cf-set">

               <div className="row align-items-center justify-content-center ">
                   <div className="col col-lg-7 ">
                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />क्या करना है ?</h2>
                   </div>
                   <div className="col col-lg-5">
                       <NavLink className="float-right width-auto" to="/karo-03-activity-03-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div>
               </div>


               <div className="row align-items-center justify-content-between mt-30">
                   <div className="col-lg-7">
                       <h2 className="h2 h2-en mt-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">साफ़ सुंदर स्वच्छ</h2>



                       <p className="mpara large-para fs-30 mt-10 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">आपको इस एक्टिविटी को करने के लिए साफ़-सफाई के सामान की भी ज़रूरत पड़ेगी, ध्यान से पहले बैठ कर प्लानिंग करें और फिर आगे बढ़ेंं।   </p>
                       <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s"> </p>

                       <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s"> </p>


                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d purple-shadow" to="/karo-03-activity-04-screen">आगे बढ़ें </NavLink>
                       </div>
                   </div>




                   <div className="col-lg-5">

                       <div className="six-tare">
                           <p>जब आप दोनों फोटो अपलोड कर देंगे तो आपको 5 तारे मिलेंगे। </p>

                           <ul className="ul-r align-left">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt=""/></li>
                               <li><h4>X 5</h4></li>
                           </ul>
                       </div>
                   </div>
               </div>
           </div>
        </section>



   </main>


    </>
  )
}

export default Kyakrna03_1ActivityScreen