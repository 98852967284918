import React,{useEffect} from "react";
import { NavLink } from "react-router-dom";

const Gyanbox01DekhoActivityScreen = () => {
  return (
    <>
      <main className="FFFADD">
        <section className="on-boarding FFFADD overflowsetbod pb-50">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center pt-70 ">
              <div className="col-lg-7">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  ज्ञान बॉक्स
                </h2>
              </div>
              <div className="col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/dekho-01-activity-09-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
            <div className="row mt-0">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mpara large-para fs-30 mt-40">
                  नीचे दिये गए कुछ शारीरिक बदलाव हैं। आप पढ़ें और अपने परिवार और
                  दोस्तों के साथ शेयर करें।
                </p>
              </div>
            </div>
            <div className="row mt-0">
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/lwb-img.svg" alt="" />
                    </center>
                  </div>
                  <p>लम्बाई व वजन बढ़ना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/dadi-munch-img-s.svg" alt="" />
                    </center>
                  </div>
                  <p>दाढ़ी मूछें आना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/bagal-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>बगल में बाल</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/body-img.svg" alt="" />
                    </center>
                  </div>
                  <p>मांसपेशियां विकसित होना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/jangh-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>जननांगो पर बाल आना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/hath-per-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>हाथ पैर पर गहरे बाल आना</p>
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/jabdo-ki-haddi.svg" alt="" />
                    </center>
                  </div>
                  <p>लडको में जबड़े की हड्डी का आकार लेना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img
                        src="assets/img/dekho/chati-par-bal-aana.svg"
                        alt=""
                      />
                    </center>
                  </div>
                  <p>छाती पे बाल आना व छाती चौड़ी होना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/awaz-bhari-hona.svg" alt="" />
                    </center>
                  </div>
                  <p>आवाज़ का भारी होना </p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/biryo.svg" alt="" />
                    </center>
                  </div>
                  <p>लड़कों में वीर्यपात होना</p>
                </div>
              </div>
            </div>
            <div className="row mt-0">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h4 className="mt-30 mb-20 red">लड़कियों में होने वाले बदलाव</h4>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/lwb-img.svg" alt="" />
                    </center>
                  </div>
                  <p>लम्बाई व वजन बढ़ना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img
                        src="assets/img/dekho/chehre-pe-bal-aana.svg"
                        alt=""
                      />
                    </center>
                  </div>

                  <p>चेहरे पे हल्के बाल आना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/bagal-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>बगल में बाल</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/wasa-jama-hona.svg" alt="" />
                    </center>
                  </div>
                  <p>कूल्हों व जांघों पे वसा का जमाव होना </p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/jangh-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>जननांगो पर बाल आना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/hath-per-bal-s.svg" alt="" />
                    </center>
                  </div>
                  <p>हाथ पैर पर गहरे बाल आना</p>
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/jabdo-ki-haddi.svg" alt="" />
                    </center>
                  </div>
                  <p>लड़कियों के गाल की हडडी का उभारना </p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/stan-bada-hone.svg" alt="" />
                    </center>
                  </div>
                  <p>स्तन विकसित होना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img src="assets/img/dekho/awaz-bhari-hona.svg" alt="" />
                    </center>
                  </div>
                  <p>आवाज़ का पतला होना</p>
                </div>
              </div>
              <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="dekho-gyan-box">
                  <div className="gyan-box-img">
                    <center>
                      <img
                        src="assets/img/dekho/mashik-dharm-hona.svg"
                        alt=""
                      />
                    </center>
                  </div>
                  <p>मासिक धर्म होना</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Gyanbox01DekhoActivityScreen;
