import React,{useState} from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Dekho01Activity01Screen = () => {

  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-6");

  return (
    <>
      <main>
        <section className="bg-flexible dekho-bg">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-top justify-content-between mt-0">
              <div className="col-lg-7">
                <h1
                  className="h1 mb-20 mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  अब हम बच्चे नहीं
                </h1>

                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  क्या आपने बड़े होते समय अपने शरीर में कुछ बदलाव देखे या महसूस
                  किए हैं? हम अक्सर उनके बारे में बात करने में शर्माते हैं और बस
                  अपने दोस्तों के साथ बांटते हैं। दोस्तों को या तो गलत जानकारी
                  होती है या अधूरी क्योंकि वे आपके हमउम्र हैं और वे भी ये सब
                  पहली बार अनुभव कर रहे हैं। इसलिए ऐसे किसी व्यक्ति से बात करनी
                  चाहिए जिसे उस विषय के बारे में सही जानकारी हो। आज झोले वाली
                  दीदी आई हैं आपको शरीर में होने वाले परिवर्तनों के बारे में
                  बताने, ध्यान से सुनना ।
                </p>

                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink to="/kyakrna-03-dekho-activity-screen" className="btnset ">
                    <span>आगे बढ़ेंं </span>{" "}
                  </NavLink>
                </div>
                <div
                  className="gkw-img d-flex mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <div className="mr-20">
                    <NavLink to="/kyakrna-03-dekho-activity-screen">
                      <center>
                        <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                      </center>
                      <p className="side-btn">क्या करना है?</p>
                    </NavLink>
                  </div>
                  <div className="">
                    <NavLink to="/board-screen">
                      <center>
                        <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                      </center>
                      <p className="side-btn">वापस जाएं</p>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="95%"
                    src="assets/img/dekho/ahbn-main-img2.svg"
                  />
                </center>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Dekho01Activity01Screen;
