import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import ProfileMenu from './common/ProfileMenu';

const Notification = () => {
    const [lg,setLg] = useState('col-lg-6')
    const [rowAllgn, setrowAllgn] = useState(
        "row align-items-center justify-content-center"
      );
      const [faqsize, setfaqsize] = useState("col-lg-4");
  return (
    <>
         <main>
		
        <section className="bg-boarding">
           <div className="container-fluid cf-set">
           <ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn}/>
               <div className="row align-items-center justify-content-center">
                  
                   <div className="col-lg-5">
                       <h1 className="h1 mb-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">तारों की टोली</h1>
                       <h6 className="h6 red text-left wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">नोटिफिकेशन</h6>
                       <p className="mpara mt-15 large-para wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">नोटिफिकेशन के बटन को दबाएं ताकि जब भी कोई नई ऐक्टिविटी अपलोड हो तो रिमाइंडर आ जाए।</p>


                     

                       <div className="row mt-30">
                           <div className="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s" >
                               <div className="dispalyset f-left wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.7s">
                                   <NavLink to="/flash-screen" className="btnset mr-15"><span>नोटिफिकेशन</span> </NavLink>
                               </div>
                               
                           </div>

                           <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s"> 
                               <NavLink  to="#" className="baadmain" style={{"width": "50%"}}>बाद मे करें</NavLink>
                           </div>
                       </div>

                           
              
                   </div>

                

                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                       <center><img draggable="false" className="mb-15" alt="stand" width="80%" src="assets/img/general/stand-pinky.svg" /></center>
                   </div>
               </div>
           </div>
        </section>
   
       

   </main>
   

    </>
  )
}

export default Notification