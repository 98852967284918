import React, { useEffect, useState, useRef } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL, { AdminUrl } from "../common/Baseurl";
import swal from "sweetalert";
import { Modal, Button, Form } from "react-bootstrap";
const Khelo01Activity04Screen = () => {
  const Ref = useRef(null);
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [correctNara, setCorrectNara] = useState([]);
  const [correctNaraNumber, setCorrectNaraNumber] = useState([]);
  const [slogan, setSlogan] = useState();
  const [sloganNumber, setSloganNumber] = useState();
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState();
  const [ekChecked, setEkChecked] = useState();
  const [ladkiChecked, setLadkiChecked] = useState();
  const [ladkaChecked, setLadkaChecked] = useState();
  const [samanChecked, setSamanChecked] = useState();
  const [taroChecked, setTaroChecked] = useState();
  const [hiChecked, setHiChecked] = useState();
  const [kiChecked, setKiChecked] = useState();
  const [badheChecked, setBadheChecked] = useState();
  const [kaChecked, setKaChecked] = useState();
  const [tabChecked, setTabChecked] = useState();
  const [sammanChecked, setSammanChecked] = useState();
  const [toliChecked, setToliChecked] = useState();
  const [timer, setTimer] = useState("00:00:00");
  const [checkBlankData, setCheckBlankData] = useState("blank");
  const [keyData, setKeyData] = useState([]);
  const [orignalData, setOrignalData] = useState([]);
  const [correctAns, setCorrectAns] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
  const [ShowHintStatus, setShowHint] = useState(false);
  const [nextVal, setNextValue] = useState();
  const [nooftime, setnotime] = useState(1);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');
  let userType = 'user';
  let user_id = user_details.user_id;
  if (user_details.role_id == 16) {
    userType = 'group';
    user_id = groupId;
  }
  const showNextHint = () => {
    let showHint = false;
    setnotime(nooftime + 1);
    for (let i = 0; i < keyData.length; i++) {
      if ((i + 1) == keyData[i]) {
        showHint = true;
      } else {
        showHint = false;
      }
    }
    if (keyData.length != 0) {
      if (showHint === false) {
        handleShow();
        return false;
      }
    }
    setShowHint(showHint);
    setNextValue((keyData.length + 1))
  }
  // console.log(nooftime);
  const matchKeyVal = (selectedItem) => {
    if (selectedItem === "1") {
      return "लड़का";
    }
    if (selectedItem === "2") {
      return "लड़की";
    }
    if (selectedItem === "3") {
      return "एक";
    }
    if (selectedItem === "4") {
      return "समान,";
    }
    if (selectedItem === "5") {
      return "तब";
    }
    if (selectedItem === "6") {
      return "ही";
    }
    if (selectedItem === "7") {
      return "बढ़े";
    }
    if (selectedItem === "8") {
      return "तारों";
    }
    if (selectedItem === "9") {
      return "की";
    }
    if (selectedItem === "10") {
      return "टोली";
    }
    if (selectedItem === "11") {
      return "का";
    }
    if (selectedItem === "12") {
      return "सम्मान";
    }
  };
  const matchVal = (selectedValItem) => {
    if (selectedValItem === "लड़का") {
      return "1";
    }
    if (selectedValItem === "लड़की") {
      return "2";
    }
    if (selectedValItem === "एक") {
      return "3";
    }
    if (selectedValItem === "समान,") {
      return "4";
    }
    if (selectedValItem === "तब") {
      return "5";
    }
    if (selectedValItem === "ही") {
      return "6";
    }
    if (selectedValItem === "बढ़े") {
      return "7";
    }
    if (selectedValItem === "तारों") {
      return "8";
    }
    if (selectedValItem === "की") {
      return "9";
    }
    if (selectedValItem === "टोली") {
      return "10";
    }
    if (selectedValItem === "का") {
      return "11";
    }
    if (selectedValItem === "सम्मान") {
      return "12";
    }
  };
  const selectItem = (e) => {
    const selectedItem = e.target.value;
    const checkedItem = e.target.checked;
    setCheckBlankData('filled');
    if (checkedItem === true) {
      var result = matchKeyVal(selectedItem);
      // console.log(selectedItem);
      setKeyData((keyData) => [...keyData, selectedItem]);
      setOrignalData((orignalData) => [...orignalData, result]);
      const el = result;
      const checkeitem = "checked";
      checkVar(el, checkeitem);
    }
    if (checkedItem === false) {
      var result = matchKeyVal(selectedItem);
      // console.log(selectedItem);
      // remove element from array
      setOrignalData((orignalData) =>
        orignalData.filter((j) => {
          return j !== result;
        })
      );
      // remove element from array
      setKeyData((keyData) =>
        keyData.filter((k) => {
          return k !== selectedItem;
        })
      );
      const el = result;
      const checkeitem = "";
      checkVar(el, checkeitem);
    }
  };
  const submitResult = () => {
    if (timer !== "00:00:00") {
      var data = JSON.stringify({
        answers: {
          ques1: {
            ans: keyData.join(","),
            ques_id: "1",
          },
          ques2: {
            ans: "",
            ques_id: "",
          },
          ques3: {
            ans: "",
            ques_id: "",
          },
          ques4: {
            ans: "",
            ques_id: "",
          },
          ques5: {
            ans: "",
            ques_id: "",
          },
        },
        user_id: user_id,
      });
    } else {
      var data = JSON.stringify({
        answers: {
          ques1: {
            ans: 'timeup',
            ques_id: "1",
          },
          ques2: {
            ans: "",
            ques_id: "",
          },
          ques3: {
            ans: "",
            ques_id: "",
          },
          ques4: {
            ans: "",
            ques_id: "",
          },
          ques5: {
            ans: "",
            ques_id: "",
          },
        },
        user_id: user_id,
      });
    }
    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity01_1",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate("/khelo-01-activity-04_1-screen");
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "1",
    });
    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity01ByUser",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setCheckBlankData('filled');
          setSlogan(response.data.data.ans);
          const ans = response.data.data.ans;
          const __ans = ans.split("-");
          setOrignalData(__ans)
          __ans.forEach((el) => {
            var res = matchVal(el);
            setKeyData((keyData) => [...keyData, res]);
            const checkeitem = "checked";
            checkVar(el, checkeitem);
          });
        } else {
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // console.log(keyData);
  const checkVar = (el, checkeitem) => {
    if (el === "एक") {
      setEkChecked(checkeitem);
    }
    if (el === "लड़की") {
      setLadkiChecked(checkeitem);
    }
    if (el === "लड़का") {
      setLadkaChecked(checkeitem);
    }
    if (el === "समान,") {
      setSamanChecked(checkeitem);
    }
    if (el === "तारों") {
      setTaroChecked(checkeitem);
    }
    if (el === "ही") {
      setHiChecked(checkeitem);
    }
    if (el === "की") {
      setKiChecked(checkeitem);
    }
    if (el === "बढ़े") {
      setBadheChecked(checkeitem);
    }
    if (el === "का") {
      setKaChecked(checkeitem);
    }
    if (el === "तब") {
      setTabChecked(checkeitem);
    }
    if (el === "सम्मान") {
      setSammanChecked(checkeitem);
    }
    if (el === "टोली") {
      setToliChecked(checkeitem);
    }
  };
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00:50");
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 50);
    return deadline;
  };
  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible
  // We put empty array to act as componentDid
  // mount only


  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const audio = new Audio(
    AdminUrl + "/alarm.mp3"
  );

  if (timer === "00:00:02") {
    audio.play();
    swal("उफ़, समय समाप्त हो गया", "", "error");

  }

  //for hint mdal
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div>
            <div
            >
              <button
                className="theme_btn theme_btn_bg chnage-d"
                onClick={submitResult}
              >
                {" "}
                भेजें{" "}
              </button>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink> */}
            <button class="btnset m-auto disabled-btn" type="button" disabled>
              भेजें{" "}
            </button>
          </div>
        </>
      );
    }
  };
  const HintPopup = () => {
    if (nooftime <= 3) {
      return (
        <>
          <NavLink to="#" onClick={showNextHint}>
            <center>
              <img src="assets/img/btn/hint.svg" alt="" />
            </center>
            <p className="side-btn">हिंट</p>
          </NavLink>
        </>
      )
    } else {
      return (
        <>
          <NavLink to="#" className="disabled-btn">
            <center>
              <img src="assets/img/btn/hint.svg" alt="" />
            </center>
            <p className="side-btn">हिंट</p>
          </NavLink>
        </>
      )
    }
  }
  return (
    <>
      <main>
        <section className="bg-flexible FFEBEB">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-60">
                <div className="col-lg-6">
                  <p
                    className="timepara wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    शेष समय {timer}
                  </p>
                  <p
                    className="mpara large-para fs-30 mt-10 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    एक-एक शब्द को चुनें और नीचे दिए गए बॉक्स में सही क्रम में
                    लगा कर एक नारा बनाएँ।{" "}
                  </p>
                  <ul
                    className="btn-effect wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <li className={(nextVal === 3) ? "select-green" : ""} >
                      <input
                        type="checkbox"
                        id="cb1"
                        value="3"
                        className="graphic"
                        onClick={selectItem}
                        checked={ekChecked}
                      //   checked={checked}
                      />
                      <label for="cb1">एक </label>
                    </li>
                    <li className={(nextVal === 2) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb2"
                        value="2"
                        className="graphic"
                        onClick={selectItem}
                        checked={ladkiChecked}
                      />
                      <label for="cb2">लड़की </label>
                    </li>
                    <li className={(nextVal === 1) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb6"
                        value="1"
                        className="graphic"
                        onClick={selectItem}
                        checked={ladkaChecked}
                      />
                      <label for="cb6">लड़का </label>
                    </li>
                    <li className={(nextVal === 4) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb3"
                        value="4"
                        className="graphic"
                        onClick={selectItem}
                        checked={samanChecked}
                      />
                      <label for="cb3">समान, </label>
                    </li>
                    <li className={(nextVal === 8) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb4"
                        value="8"
                        className="graphic"
                        onClick={selectItem}
                        checked={taroChecked}
                      />
                      <label for="cb4">तारों</label>
                    </li>
                    <li className={(nextVal === 6) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb5"
                        value="6"
                        className="graphic"
                        onClick={selectItem}
                        checked={hiChecked}
                      />
                      <label for="cb5">ही </label>
                    </li>
                    <li className={(nextVal === 9) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb7"
                        value="9"
                        className="graphic"
                        onClick={selectItem}
                        checked={kiChecked}
                      />
                      <label for="cb7">की</label>
                    </li>
                    <li className={(nextVal === 7) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb8"
                        value="7"
                        className="graphic"
                        onClick={selectItem}
                        checked={badheChecked}
                      />
                      <label for="cb8">बढ़े </label>
                    </li>
                    <li className={(nextVal === 11) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb9"
                        value="11"
                        className="graphic"
                        onClick={selectItem}
                        checked={kaChecked}
                      />
                      <label for="cb9">का </label>
                    </li>
                    <li className={(nextVal === 5) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb10"
                        value="5"
                        className="graphic"
                        onClick={selectItem}
                        checked={tabChecked}
                      />
                      <label for="cb10">तब </label>
                    </li>
                    <li className={(nextVal === 12) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb11"
                        value="12"
                        className="graphic"
                        onClick={selectItem}
                        checked={sammanChecked}
                      />
                      <label for="cb11">सम्मान</label>
                    </li>
                    <li className={(nextVal === 10) ? "select-green" : ""}>
                      <input
                        type="checkbox"
                        id="cb12"
                        value="10"
                        className="graphic"
                        onClick={selectItem}
                        checked={toliChecked}
                      />
                      <label for="cb12">टोली</label>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-lg-5 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="input-group">
                    <label className="levelset ">सही नारा </label>
                    <div className="input chune-sabd-text-area right" >
                      <textarea
                        className="set-input ml-20"
                        required
                        name="w3review"
                        rows="4"
                        cols="50"
                        placeholder=""
                        value={orignalData.join(" ")}
                      >
                        {" "}
                      </textarea>
                    </div>
                  </div>
                  <span className="error">{message}</span>
                  <div className="d-flex mt-30">
                    {/* <div className="mr-20">
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >
                        <NavLink to="/kyakrna-01-khelo-activity-screen">
                          <center>
                            <img src="assets/img/btn/kaise-khelen.svg" alt="" />
                          </center>
                          <p className="side-btn">कैसे खेलें </p>
                        </NavLink>
                      </div>
                    </div> */}
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >
                        {/* <NavLink to="#" onClick={handleShow}> */}
                        <HintPopup />
                      </div>
                    </div>
                    <div>
                      <BtnDisabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="addjustment-popup">
              <div className="container-fluid">
                <div className="row align-items-center justify-content-center mt-60">
                  <div className="col-lg-12">
                    <p
                      className="mpara large-para fs-30 mt-10 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      वाक्य सही करें
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Khelo01Activity04Screen;
