import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ProfileMenu from "./common/ProfileMenu";
import swal from "sweetalert";
import axios from "axios";
import BaseURL,{AdminUrl} from "./common/Baseurl";

const GeneralScreen = () => {
	const Ref = useRef(null);
	const navigate = useNavigate();

	const [timer, setTimer] = useState("00:00:00");

	const [lg, setLg] = useState("col-lg-5");
	const [rowAllgn, setrowAllgn] = useState(
		"row align-items-center justify-content-center"
	);
	const [faqsize, setfaqsize] = useState("col-lg-4");

	const [counter, setCounter] = useState(0);

	const user_details = JSON.parse(localStorage.getItem("user_details"));

	const groupId = localStorage.getItem('groupId');

	let userType = 'user';
	let user_id = user_details.user_id;
	if(user_details.role_id == 16)
	{

	  userType = 'group';
	  user_id =groupId;
	}


	const [starAll, setStarAll] = useState([

		1,2, 3, 4, 5, 6, 7, 8, 9, 10

	]);

	const [Clickdata, setClickData] = useState([]);

	const audio = new Audio(
		AdminUrl+"/alarm.mp3"
	  );



	const checkArray = (param) => {
		Clickdata.filter((k) => {
			return k === param
		});
		return false;
	}

	if (timer === "00:00:01") {
		audio.play();
		swal("Oops! Time Up", "You clicked the button!", "error");


	}

	const collectStar = (e) => {
		if (timer !== "00:00:00") {

			const min = 1;
			const max = 11;
			const rand = Math.floor(min + Math.random() * (max - min));



			e.target.style.display = 'none'

			const dataId = e.target.getAttribute("data-id");

			if (!checkArray(dataId) && dataId != null) {
				// console.log(dataId);
				setClickData((Clickdata) => [...Clickdata, dataId]);
			}



			// console.log(Clickdata);


			setStarAll((starAll) => [...starAll, rand]);
			// alert(dataId);
			// setCounter((count) => count + 1);
			// console.log(counter);
		} else {
			audio.play();
			swal("Oops! Time Up", "You clicked the button!", "error");
		}
	};

	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total,
			hours,
			minutes,
			seconds,
		};
	};

	const startTimer = (e) => {
		let { total, hours, minutes, seconds } = getTimeRemaining(e);
		if (total >= 0) {
			// update the timer
			// check if less than 10 then we need to
			// add '0' at the begining of the variable
			setTimer(
				(hours > 9 ? hours : "0" + hours) +
				":" +
				(minutes > 9 ? minutes : "0" + minutes) +
				":" +
				(seconds > 9 ? seconds : "0" + seconds)
			);
		}
	};

	const clearTimer = (e) => {
		// If you adjust it you should also need to
		// adjust the Endtime formula we are about
		// to code next
		setTimer("00:00:60");

		// If you try to remove this line the
		// updating of timer Variable will be
		// after 1000ms or 1sec
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			startTimer(e);
		}, 1000);
		Ref.current = id;
	};

	const getDeadTime = () => {
		let deadline = new Date();

		// This is where you need to adjust if
		// you entend to add more time
		deadline.setSeconds(deadline.getSeconds() + 60);
		return deadline;
	};

	useEffect(() => {
		clearTimer(getDeadTime());
	}, []);

	// save star in database

	const saveTotalStar = () => {


		var data = JSON.stringify({
			user_id: user_id,
			stars: Clickdata.length,
		});

		var config = {
			method: "post",
			url: BaseURL + "/addtotalStars",
			headers: {
				"Content-Type": "application/json",
				'type': `${userType} `,
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {


					localStorage.setItem('startimer', JSON.stringify(timer));

					var data = JSON.stringify({
						"user_id": user_id,
						"total_stars": "1",
						"activity_type": "game",
						"activity_no": "1",
						"user_star": Clickdata.length
					  });

					  var config = {
						method: 'post',
						url: BaseURL+'/addFinalStars',
						headers: {
						  'Content-Type': 'application/json',
						  'type': `${userType} `,
						},
						data : data
					  };

					  axios(config)
					  .then(function (response) {
						navigate("/general-screen-timer");
					  })
					  .catch(function (error) {
						console.log(error);
					  });



				}
				// console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			<main>
				<section
					className="fixed-bg star-screen"
					style={{

						overflow: "hidden",
					}}
				>
					<ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
					<div class="">
						<div className="container-fluid cf-set">
							<div className="row">
								<div className="col-lg-8">
									<h1
										className="h1 mb-20 mt-3 mt-m-2 text-left text-white wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										अपनी स्क्रीन पर गिरतेे हुए तारों को ज्यादा से ज्यादा इकट्ठा
										करें!
									</h1>




								</div>


							</div>
						</div>
					</div>

					<div class="btn-start-secreen">
						<div className="container-fluid ">
							<div className="row align-items-top justify-content-center">
								<div className="col col-lg-4">
									<div className="d-flex justify-content-start">
										<div className="box1 mr-30 mt-30" >
											<NavLink to="#">
												<div className="starbox d-flex justify-content-center">
													<img src="assets/img/icon/small-star.svg" alt="" />
													<h4>{Clickdata.length}</h4>
												</div>
											</NavLink>
										</div>


									</div>
								</div>

								<div className="col col-lg-4">
									<div
										className="mt-10"

									>
										<NavLink
											to="#"
											className="btnset blue-shadow m-auto "
											onClick={saveTotalStar}
										>
											<span>आगे बढ़ें </span>{" "}
										</NavLink>
									</div>
								</div>


								<div className="col col-lg-4">
									<div className="d-flex justify-content-end">


										<div className="box1 float-right" >
											<div class="timer timer-small m-timer">
												<div class="content-alarm">
													<p>{timer}</p>
												</div>
											</div>
										</div>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div id="bubbles">
						{
							starAll.map((el) => {
								return (
									<>
										<div className={"bubble x" + el} onClick={collectStar} style={{ "display": "block" }}>
											<img src="assets/img/icon/general/2.svg" data-id={Date.now()} alt="" />
										</div>
									</>
								)
							})
						}



					</div>
				</section>
			</main>
		</>
	);
};

export default GeneralScreen;
