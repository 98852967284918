import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo03Activity01Screen = () => {
  const [lg,setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState('col-lg-4');

  return (
    <>
      <main>
		
    <section className="bg-flexible karo">
             <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
             <div class="data-adjust">
           <div className="container-fluid cf-set">
               <div className="row align-items-center justify-content-center mt-30">
                   <div className="col-lg-4">
                       <h1 className="h1 mb-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">करो</h1>
                
                       <div className="karo-activity-barabri wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                           <h4>साफ़ सुंदर स्वच्छ </h4>
                           <img className="mt-20" width="85%" src="assets/img/general/pinky-chune.svg" draggable="false" alt="" />
                           <div className="mt-20">
                               <NavLink to="/karo-03-activity-03-screen" className="btnset radius-set m-auto"><span>चुनें</span> </NavLink>
                           </div>
                       </div>
                   </div>
                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <center><img className="mt-m-2" draggable="false"  width="100%" src="assets/img/general/safai-girl.svg" /></center>
                   </div>
               </div>


           </div>
           </div>
        </section>
 
   

   </main>
 
 

    </>
  )
}

export default Karo03Activity01Screen