import React, { useEffect, useState, useRef } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL,{AdminUrl} from "../common/Baseurl";
import swal from "sweetalert";

const Khelo02Activity03Screen = () => {
  const Ref = useRef(null);
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [timer, setTimer] = useState("00:00:00");
  const [apnaName, setApnaName] = useState();
  const [xxx, setXXX] = useState();
  const [gola, setGola] = useState();
  const [sclass, setClass] = useState();
  const [result, setResult] = useState();
  const [inputVal, setInputVal] = useState('');
  const [resultValue, setResultVal] = useState();
  const [nameErroMsg, setNameErrorMsg] = useState();
  const [xxxErroMsg, setxxxErrorMsg] = useState();
  const [golaErroMsg, setGolaErrorMsg] = useState();
  const [classErroMsg, setClassErrorMsg] = useState();

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const audio = new Audio(
		AdminUrl+"/alarm.mp3"
	  );
  // console.log(gola);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:02:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // console.log(timer);

  if (timer === "00:00:02") {
    audio.play();
    swal("Oops! Time Up", "You clicked the button!", "error");
    //  navigate("/khelo-02-activity-04-screen")
    //    console.log('helo');
  }



  /* submit form */

  const submitForm = (e) => {

    e.preventDefault();
    if (timer !== "00:00:00") {



      if (apnaName === '' || apnaName === undefined) {
        setNameErrorMsg('कृपया पसंदीदा रंग दर्ज करें');
        return false;
      }

      if (xxx === '' || xxx === undefined) {
        setxxxErrorMsg('कृपया xxx दर्ज करें');
        return false;
      }

      if (gola === '' || gola === undefined) {
        setGolaErrorMsg('कृपया गोला का चयन करें');
        return false;
      }

      if (sclass === '' || sclass === undefined) {
        setClassErrorMsg('कृपया कक्षा दर्ज करें');
        return false;
      }


      var data = JSON.stringify({
        user_id: user_id,
        "time": timer,
        "answers": {
          "ques1": {
            "ans": apnaName,
            "ques_id": "1"
          },
          "ques2": {
            "ans": xxx,
            "ques_id": "2"
          },
          "ques3": {
            "ans": gola,
            "ques_id": "3"
          },
          "ques4": {
            "ans": sclass,
            "ques_id": "4"
          },
          "ques5": {
            "ans": inputVal,
            "ques_id": "5"
          }
        }
      });

      // console.log(data);

      var config = {
        method: 'post',
        url: BaseURL+'/kheloActivity02_1',
        headers: {
          'type': `${userType} `,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {

          // console.log(response);
          // return false;
          if(response.data.success === true)
          {
            navigate('/khelo-02-activity-04-screen');
          }
        })
        .catch(function (error) {
          console.log(error);
        });



    } else {


      navigate('/khelo-02-activity-04-screen');
    }
  };

  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity02Result",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          // setActivities(response.data.data.activities);
          setApnaName(response.data.data.activities[0]["ans"]);
          setXXX(response.data.data.activities[1]["ans"]);
          setGola(response.data.data.activities[2]["ans"]);
          setClass(response.data.data.activities[3]["ans"]);

        } else {
          console.log(response.data);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  const multiplyVal = (e) => {
    const mul = e.target.value;

    const result = 8 * mul;
    setResult(result);
  };



  return (
    <>
      <main>
        <section className="bg-flexible FDF2E0">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">

              <div className="row align-items-top justify-content-between ">
                <div className="col-lg-6"></div>
                <div className="col-lg-4 ">

                </div>
              </div>

              <div className="row align-items-center justify-content-between mt-0">
                <form action="" onSubmit={submitForm}>
                  <div className="col-lg-12">


                    <div className="row-box">
                      <div className="row">

                        <div className="col-lg-4">
                          <div className="form-col-set d-flex mt-50">
                            <div className="filed-box-text">
                              <div className="spanset">1</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>पूरा पेज पढ़ लो</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8">
                          <div className="float-right">
                            <div className="timer timer-small">
                              <div className="content-alarm">
                                <p>{timer}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-10">
                        <div className="col-lg-4">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">2</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>अपना पसंदीदा रंग लिखो</p>
                                <div className="input right">
                                  <input
                                    type="text"

                                    className="set-input"
                                    placeholder=""
                                    value={apnaName}
                                    onChange={(e) => {
                                      setApnaName(e.target.value);
                                    }}
                                  />
                                </div>
                                <span className="showError">{nameErroMsg}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">3</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>तीन छोटे xxx बनाओ</p>
                                <div className="input right">
                                  <input
                                    type="text"

                                    className="set-input"
                                    placeholder=""
                                    value={xxx}
                                    onChange={(e) => {
                                      setXXX(e.target.value);
                                    }}
                                  />
                                </div>
                                <span className="showError">{xxxErroMsg}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">4</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>सबसे बड़े गोले पर चिन्ह लगाओ</p>

                                <div className="timer-form-set">
                                  <input type="radio" name="golaradio" id="html" value="a" onChange={(e) => {
                                    setGola(e.target.value);
                                  }} />
                                  <label for="html"></label>

                                  <input
                                    className="two-n"
                                    type="radio"
                                    id="two"
                                    name="golaradio"
                                    value="b"
                                    onChange={(e) => {
                                      setGola(e.target.value);
                                    }}
                                  />
                                  <label className="two" for="two"></label>

                                  <input
                                    className="thiree-n"
                                    type="radio"
                                    id="thiree"
                                    name="golaradio"
                                    value="c"
                                    onChange={(e) => {
                                      setGola(e.target.value);
                                    }}
                                  />
                                  <label className="thiree" for="thiree"></label>

                                  <input
                                    className="four-n"
                                    type="radio"
                                    name="golaradio"
                                    id="four"
                                    value="d"
                                    onChange={(e) => {
                                      setGola(e.target.value);
                                    }}
                                  />
                                  <label className="four" for="four"></label>

                                  <input
                                    className="five-n"
                                    type="radio"
                                    name="golaradio"
                                    id="five"
                                    value="e"
                                    onClick={(e) => {
                                      setGola(e.target.value);
                                    }}
                                  />
                                  <label className="five" for="five"></label>
                                </div>
                              </div>
                              <span className="showError">{golaErroMsg}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">5</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>
                                  यहां तक पहुँच कर, बिना कुछ कहे, अपना हाथ ऊपर करो
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">6</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>अब मन ही मन 1 से 10 तक गिनो</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">7</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>अपनी कक्षा लिखो</p>
                                <div className="input right">
                                  <input
                                    type="number"

                                    className="set-input"
                                    placeholder=""
                                    max="12"
                                    min="1"
                                    value={sclass}
                                    onChange={(e) => {
                                      setClass(e.target.value);
                                    }}
                                  />
                                </div>
                                <span className="showError">{classErroMsg}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-lg-7">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">8</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>
                                  अब किसी भी नम्बर से 8 को गुणा (मल्टीप्लई) कर दो
                                </p>

                                <div className="input right input-size-d1  ">
                                  <input
                                    type="text"
                                    className="set-input"
                                    placeholder=""
                                    onChange={(e)=>setInputVal(e.target.value)}
                                    // value="8"
                                  />
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-5">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">9</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>
                                  यह होते ही, अपने स्थान से उठ कर कहीं और बैठ जाओ
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-10">
                        <div className="col-lg-12">
                          <div className="form-col-set d-flex">
                            <div className="filed-box-text">
                              <div className="spanset">10</div>
                            </div>
                            <div className="filed-box-input">
                              <div className="input-group mr-form">
                                <p>
                                  यह सब पढ़ने के बाद सिर्फ सवाल नंबर 1 , 2 , 3 , 4
                                  , 7 ही करो
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="mt-15 mb-15"

                    >
                      {/* <NavLink to="/khelo-02-activity-04-screen" className="btnset">
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink> */}

                      <button type="submit" className="btnset">
                        <span>आगे बढ़ें </span>{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo02Activity03Screen;
