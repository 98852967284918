import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import { render } from "react-dom";
import axios from "axios";
import BaseURL from "../common/Baseurl";

import useRecorder from "../UseRecorder";

const Suno01Activity04_2Screen = () => {
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-6");
  let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();

  // console.log(stopRecording);

  const [yesNo, selectYesNoVal] = useState();
  const [checkBlankData, setCheckBlankData] = useState("blank");
  const[yesChecked,setYesChecked] = useState('');
  const[noChecked,setNoChecked] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));

  useEffect(() => {

    const user_id = user_details.user_id;
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "4",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getSunoActivity01ByUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {

          setCheckBlankData("filled");


            if(response.data.data.ans === 'yes')
            {
              setYesChecked('checked');
              setNoChecked('');
            }

            if(response.data.data.ans === 'no')
            {
              setNoChecked('checked');
              setYesChecked('');
            }


        }else{

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);




  const selectYesNo = (e) => {
    const yesNo = e.target.value;
    setCheckBlankData("filled");

    if(yesNo === 'लड़का')
    {
      setNoChecked('');
              setYesChecked('checked');
    }

    if(yesNo === 'लड़की')
    {
      setNoChecked('checked');
              setYesChecked('');
    }


    var data = JSON.stringify({
      user_id: user_details.user_id,
      ans: yesNo,
      ques_id: "4",
    });

    var config = {
      method: "post",
      url: BaseURL+"/sunoActivity01",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

      // console.log(checkBlankData);
      const BtnDisabled = ()=>{

        if(checkBlankData !== 'blank') {
          return(
            <>
                  <div
                className="mt-30 mb-15"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >


                <NavLink  to="/suno-01-activity-05-screen" className="btnset">
                  <span>आगे बढ़ेंं </span>{" "}
                </NavLink>


              </div>
            </>
          )
        }else{

          return(
            <>
                  <div
                className="mt-30 mb-15"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >


                {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                  <span>आगे बढ़ेंं </span>{" "}
                </NavLink> */}

                <button  class="btnset disabled-btn" type="button" disabled>आगे बढ़ेंं</button>


              </div>
            </>
          )

        }
      }




  return (
    <>
      <main>
        <section className="bg-flexible jaja-baba-bg-blue">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div
                className="col-lg-5 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                {/* <p className="mpara large-para fs-30 mt-40 d-block">
                  नीचे दिए गए सवालों के उत्तर देने पर आपको 10 तारे मिलेंगे। अगर
                  आप उत्तर नहीं देते हैं तो आपको तारे नहीं मिलेंगे।
                </p> */}
                <p className="mpara large-para fs-30 d-block">
                आपको क्या लगा, इन दोनोंं में से कौन लड़का है और कौन लड़की?
                                         जाजा

                </p>
                {/* <h6 className="yesorno mt-30"> हाँ या ना </h6> */}
                <div className="d-flex small-d-yes-or-no mt-30">
                  <div className="content-area">
                    <div className="invisible-checkboxes yes-design">
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="लड़का"
                        id="yes"
                        onClick={selectYesNo}
                        checked={yesChecked}
                      />
                      <label
                        className="checkbox-alias activity-btn yes-bg-change"
                        htmlFor="yes"
                      >
                        <h4 className="slider-h">लड़का है</h4>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex small-d-yes-or-no mt-30"><p className="mpara large-para fs-30 d-block">या </p></div>&nbsp;
                  <div className="content-area">
                    <div className="invisible-checkboxes yes-design red-bg-no">
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="लड़की"
                        id="no"
                        onClick={selectYesNo}
                        checked={noChecked}
                      />
                      <label
                        className="checkbox-alias activity-btn no-bg-change"
                        htmlFor="no"
                      >
                        <h4 className="slider-h">लड़की है</h4>
                      </label>
                    </div>
                  </div>
                </div>
                <BtnDisabled />
              </div>

              <div className="col-lg-1" />
              <div
                className="col-lg-5 text-center wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
              <img width="100%" className="mt-m-1" src="assets/img/suno/jaja-baja.svg" />


              </div>
              <div className="col-lg-1" />
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno01Activity04_2Screen;
