import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Khelo10Activity04Screen = () => {
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [selectItems, setSelectItems] = useState([]);

  const [mummyPapachecked, setMummyPapaChecked] = useState();
  const [bhaiBahanchecked, setBhaiBahanChecked] = useState();
  const [dostoKeSathchecked, setDostoChecked] = useState();
  const [rishtedarSathchecked, setRishtedarChecked] = useState();
  const [teacherchecked, setTeacherChecked] = useState();
  const [vidalayachecked, setVidalayaChecked] = useState();
  const [classchecked, setClassChecked] = useState();


  const [checkBlankData, setCheckBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const selectActivity = [];
  const getSelect = (e) => {
    const activity = e.target.value;
    selectActivity.push(activity);
    setCheckBlankData("filled");

    // console.log(selectActivity);

    var data = JSON.stringify({
      user_id: user_id,
      ans: selectActivity.join(),
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity10",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(selectActivity);
  };

  useEffect(() => {
    // const user_details = JSON.parse(localStorage.getItem("user_details"));
    // const user_id = user_details.user_id;

    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "2",
    });

    const config = {
      method: "post",
      url: BaseURL + "/getKheloActivity10ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          setCheckBlankData("filled");
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(",");
          // console.log(Ans_data);

          Ans_data.map((item) => {
            //   console.log(item);
            if (item === "मम्मी पापा के साथ") {
                setMummyPapaChecked("checked");
            }

            if (item === "भाई-बहन के साथ") {
                setBhaiBahanChecked("checked");
            }

            if (item === "दोस्तों के साथ") {
                setDostoChecked("checked");
            }

            if (item === "रिश्तेदारों के साथ") {
                setRishtedarChecked("checked");
            }

            if (item === "टीचर के साथ") {
                setTeacherChecked("checked");
            }

            if (item === "विद्यालय में") {
                setVidalayaChecked("checked");
            }

            if (item === "कक्षा में") {
                setClassChecked("checked");
            }


          });

          setSelectItems(Ans_data);
          // setCheckBlankData("filled");
          // setExistImage(response.data.data.ans);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(selectItems);
  }, []);

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <NavLink to="/khelo-10-activity-05-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="kya-karna-hai-scroll F8F1FD pt-0">
          <div className="fixed-header">
              <Name/>
            <div className="container-fluid cf-set">
            </div>
          </div>
          <div className="data-adjust2">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-12">
                  <p
                    className="mpara mt-m-1 large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    आपको 5 कथन दिए गए हैं । आप एक से ज़्यादा विकल्पों का चयन कर
                    सकते हैं। इस एक्टिविटी में कोई सही या गलत जवाब नहीं है।
                    हमारा उद्देश्य आपके विचार जानना है।{" "}
                  </p>
                  <div
                    className="mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d step-btn"
                      to="#"
                    >
                      सवाल <span className="span-c-change">02</span>
                    </NavLink>
                  </div>
                  <h1
                    className="h1 mb-20 mt-40 mt-m-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    हम कहाँ चुपचाप सुनते रहते हैं
                  </h1>
                </div>
                <div className="col-lg-12">

                <OwlCarousel className='owl-theme' items={6} margin={8} autoplay={true}  dots  >
                    <div className="post-slide">
                      <div className="activity-design">
                        <div className="box-img">
                          <center>
                            <img
                              src="assets/img/khelo/gif/m-p-ke-samne.gif"
                              alt=""
                            />
                          </center>
                        </div>
                        <div className="content-area">
                          <div className="invisible-checkboxes btn-select">
                            <input
                              type="checkbox"
                              name="rGroup"
                              defaultValue="मम्मी पापा के साथ"
                              id="skljk1"
                              onClick={getSelect}
                            checked={mummyPapachecked}

                            />
                            <label
                              className="checkbox-alias activity-btn"
                              htmlFor="skljk1"
                            >
                              <h4 className="slider-h"> मम्मी पापा के साथ</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="post-slide">
                      <div className="activity-design">
                        <div className="box-img">
                          <center>
                            <img
                              src="assets/img/khelo/gif/bha--behen-ke-sath.gif"
                              alt=""
                            />
                          </center>
                        </div>
                        <div className="content-area">
                          <div className="invisible-checkboxes btn-select">
                            <input
                              type="checkbox"
                              name="rGroup"
                              defaultValue="भाई-बहन के साथ"
                              id="skljk2"
                              onClick={getSelect}
                            checked={bhaiBahanchecked}

                            />
                            <label
                              className="checkbox-alias activity-btn"
                              htmlFor="skljk2"
                            >
                              <h4 className="slider-h"> भाई-बहन के साथ</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="post-slide">
                      <div className="activity-design">
                        <div className="box-img">
                          <center>
                            <img
                              src="assets/img/khelo/gif/dosto-ke-sath.gif"
                              alt=""
                            />
                          </center>
                        </div>
                        <div className="content-area">
                          <div className="invisible-checkboxes btn-select">
                            <input
                              type="checkbox"
                              name="rGroup"
                              defaultValue="दोस्तों के साथ"
                              id="skljk3"
                              onClick={getSelect}
                            checked={dostoKeSathchecked}

                            />
                            <label
                              className="checkbox-alias activity-btn"
                              htmlFor="skljk3"
                            >
                              <h4 className="slider-h"> दोस्तों के साथ </h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="post-slide">
                      <div className="activity-design">
                        <div className="box-img">
                          <center>
                            <img
                              src="assets/img/khelo/gif/rishtedar-ke-sath.gif"
                              alt=""
                            />
                          </center>
                        </div>
                        <div className="content-area">
                          <div className="invisible-checkboxes btn-select">
                            <input
                              type="checkbox"
                              name="rGroup"
                              defaultValue="रिश्तेदारों के साथ"
                              id="skljk4"
                              onClick={getSelect}
                            checked={rishtedarSathchecked}

                            />
                            <label
                              className="checkbox-alias activity-btn"
                              htmlFor="skljk4"
                            >
                              <h4 className="slider-h"> रिश्तेदारों के साथ</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="post-slide">
                      <div className="activity-design">
                        <div className="box-img">
                          <center>
                            <img
                              src="assets/img/khelo/gif/teacher-ke-samne.gif"
                              alt=""
                            />
                          </center>
                        </div>
                        <div className="content-area">
                          <div className="invisible-checkboxes btn-select">
                            <input
                              type="checkbox"
                              name="rGroup"
                              defaultValue="टीचर के साथ"
                              id="skljk5"

                              onClick={getSelect}
                            checked={teacherchecked}

                            />
                            <label
                              className="checkbox-alias activity-btn"
                              htmlFor="skljk5"
                            >
                              <h4 className="slider-h"> टीचर के साथ </h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="post-slide">
                      <div className="activity-design">
                        <div className="box-img">
                          <center>
                            <img
                              src="assets/img/khelo/gif/vidhyalay-main.gif"
                              alt=""
                            />
                          </center>
                        </div>
                        <div className="content-area">
                          <div className="invisible-checkboxes btn-select">
                            <input
                              type="checkbox"
                              name="rGroup"
                              defaultValue="विद्यालय में"
                              id="skljk6"
                              onClick={getSelect}
                            checked={vidalayachecked}
                            />
                            <label
                              className="checkbox-alias activity-btn"
                              htmlFor="skljk6"
                            >
                              <h4 className="slider-h">विद्यालय में </h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="post-slide">
                      <div className="activity-design">
                        <div className="box-img">
                          <center>
                            <img
                             src="assets/img/khelo/gif/classmian.gif"
                              alt=""
                            />
                          </center>
                        </div>
                        <div className="content-area">
                          <div className="invisible-checkboxes btn-select">
                            <input
                              type="checkbox"
                              name="rGroup"
                              defaultValue="कक्षा में"
                              id="skljk7"
                              onClick={getSelect}
                            checked={classchecked}


                            />
                            <label
                              className="checkbox-alias activity-btn"
                              htmlFor="skljk7"
                            >
                              <h4 className="slider-h">कक्षा में</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </OwlCarousel>

                  <BtnDisabled />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo10Activity04Screen;
