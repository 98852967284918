import React,{useState} from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo03Activity03Screen = () => {

    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center mt-10"
    );
    const [faqsize, setfaqsize] = useState("col-lg-3");

  return (
    <>
      <main>
        <section className="bg-flexible EEFFE8">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
       
          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center mt-20">
              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  पहला स्तर
                </h1>
                <NavLink to="/khelo-03-activity-04-screen">
                  <img
                    className="mt-10 activity-number-img"
                    src="assets/img/khelo/g-phela-star.svg"
                    alt=""
                  />
                </NavLink>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    draggable="false"
                    width="100%"
                    src="assets/img/khelo/aaj-main-upar-main.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo03Activity03Screen;
