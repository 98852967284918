import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Name from "../common/Name";

const Khelo04Activity08Screen = () => {

    const [user_star, setUser_star] = useState();

    const user_details = JSON.parse(localStorage.getItem("user_details"));

    useEffect(() => {
      var data = JSON.stringify({
        user_id: user_details.user_id,
        ques_id: "3",
      });

      var config = {
        method: "post",
        url: BaseURL + "/getKheloActivity04ByUser",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response.data.success === true) {
            setUser_star(response.data.data.user_star);
          }
        //   console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);

    const Result1 = () => {
      if (user_star === 1) {
        return (
          <>
            <div
              className="six-tare  wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <h1 className="rh1 vw-2-5 green">
                शाबाश! सही जवाब है <br />
                साइना नेहवाल!!
              </h1>
              <p className="text-center"> तारे इकट्ठे हुए </p>
              <ul className="ul-r">
                <li>
                  <img src="assets/img/icon/star.svg" alt="" />
                </li>
                <li>
                  <img src="assets/img/icon/star.svg" alt="" />
                </li>
                <li>
                  <img src="assets/img/icon/star.svg" alt="" />
                </li>
                <li>
                  <h4>{user_star}</h4>
                </li>
              </ul>
            </div>
          </>
        );
      } else if (user_star === 0){
        return (
          <>
            <div
              className="six-tare  wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <h1 className="rh1 vw-2-5 red">
                गलत जवाब! <br /> सही जवाब है साइना नेहवाल!!
              </h1>
              <p className="text-center"> तारे इकट्ठे हुए </p>
              <ul className="ul-r">
                <li>
                  <img src="assets/img/icon/star.svg" alt="" />
                </li>
                <li>
                  <img src="assets/img/icon/star.svg" alt="" />
                </li>
                <li>
                  <img src="assets/img/icon/star.svg" alt="" />
                </li>
                <li>
                  <h4>{user_star}</h4>
                </li>
              </ul>
            </div>
          </>
        );
      }
    };


    console.log(user_star);



  return (
    <>
        <main className="F8F1FD ">
  <section className="bg-flexible karo overflowsetbody F8F1FD height-auto">
  <Name />

    <div className="container-fluid cf-set">

      <div className="row  mt-30">
        <div className="col-lg-5">
          <Result1 />
          <h1
            className="mt-40 red wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            आपकी जानकारी के लिए -
          </h1>{" "}
          <br />
        </div>
        <div className="col-lg-12">
          <div className="row">
            <div
              className="col-lg-4 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div className="services-box">
                <div className="img-box">
                  <center>
                    <img src="assets/img/khelo/raster-img/duti-chand.jpg" alt="" />
                  </center>
                </div>
                <div className="content-area">
                  <h3>दुती चन्द</h3>
                  <p>
                  दूती चन्द भारत की तीसरी महिला खिलाड़ी हैं, जिन्होंने ग्रीष्मकालीन ओलम्पिक खेलों के 100 मीटर की इवेंट में क़्वालीफाई किया गया है।  2013 में एशियाई एथलेटिक्स चैंपियनशिप, वर्ल्ड यूथ चैंपियनशिप और रांची में नेशनल सीनियर एथलेटिक्स चैंपियनशिप में भी उन्होंने शानदार प्रदर्शन किया
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div className="services-box">
                <div className="img-box">
                  <center>
                    <img src="assets/img/khelo/raster-img/shakshi-malik.jpg" alt="" />
                  </center>
                </div>
                <div className="content-area">
                  <h3>साक्षी मलिक</h3>
                  <p>साक्षी मलिक भारतीय महिला पहलवान हैं। भारत के लिए ओलंपिक पदक जीतने वाली वे पहली महिला पहलवान हैं। उन्होंने 2014 ग्लास्गो राष्ट्रमंडल खेलों में रजत पदक हासिल किया था</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div className="services-box">
                <div className="img-box">
                  <center>
                    <img
                      src="assets/img/khelo/raster-img/shayna-nehwal.jpg"
                      alt=""
                    />
                  </center>
                </div>
                <div className="content-area">
                  <h3>साइना नेहवाल </h3>
                  <p>
                  साइना नेहवाल बैडमिंटन खिलाड़ी हैं। 2012 में साइना ने इतिहास रचते हुए बैडमिंटन की महिला एकल स्पर्धा में कांस्य पदक हासिल किया | बैडमिंटन में ऐसा करने वाली वे भारत की पहली खिलाड़ी हैं।
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div className="services-box">
                <div className="img-box">
                  <center>
                    <img src="assets/img/khelo/raster-img/pb-sindhu.jpg" alt="" />
                  </center>
                </div>
                <div className="content-area">
                  <h3>पी.वी.सिंधु</h3>
                  <p>
                  पी.वी.सिंधु विश्व वरीयता बैडमिंटन खिलाड़ी हैं तथा भारत की ओर से ओलम्पिक खेलों में महिला एकल बैडमिंटन का रजत पदक व कांस्य पदक जीतने वाली वह पहली खिलाड़ी हैं। वह वर्ल्ड चैंपियनशिप जीतने वाली पहली भारतीय शटलर हैं।
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div className="services-box">
                <div className="img-box">
                  <center>
                    <img src="assets/img/khelo/raster-img/ekta-bhayan.jpg" alt="" />
                  </center>
                </div>
                <div className="content-area">
                  <h3>एकता भयान</h3>
                  <p>
                  एकता भयान पैरा एथलीट हैं, जो क्लब थ्रो और भाला फ़ेंक खेल में भारत का प्रतिनिधित्व करती हैं। 2018 में इंडोनेशिया के जकार्ता में आयोजित एशियाई खेलों मे एकता ने भारत का प्रतिनिधित्व करते हुए क्लब थ्रो प्रतियोगिता में स्वर्ण पदक जीता।
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div
                className="mt-30  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <NavLink
                  className="theme_btn theme_btn_bg chnage-d"
                  to="/khelo-04-activity-09-screen"
                >
                  अगला सवाल
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

    </>
  );
};

export default Khelo04Activity08Screen;
