import React,{useEffect} from 'react'
import Header from '../common/Header';
import Footer from '../common/Footer';
import { NavLink, useNavigate} from 'react-router-dom';
import InnerFooter from '../common/InnerFooter';
import axios from 'axios';
import BaseURL from '../common/Baseurl';

const Home = () => {

    const navigate = useNavigate();



    const user_details = JSON.parse(localStorage.getItem("user_details"));
  useEffect(() => {
    if(user_details === null)
    {
        return navigate("/");
    }
    var data = JSON.stringify({
      token:
      `${user_details.token} `,
    });

    var config = {
      method: "post",
      url: BaseURL+"/get_user",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if(response.status === "Token is Expired")
        {
          window.localStorage.clear();
          navigate('/welcome')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);




    const Static = ()=>{

        if(user_details === null || user_details === '')
        {


            return(
                <>
                       <NavLink to="/student-facilator"><h3 className="title">Taaron Ki Toli</h3></NavLink>
                </>
            )
        }else{
            return(
                <>
                       <NavLink to="/board-screen"><h3 className="title">Taaron Ki Toli</h3></NavLink>
                </>
            )
        }


    }




  return (
    <>

       <header>
        <div className="header-wrapper">
            <div className="container-fluid cf-set">
                <div className="row">
                    <div className="col-lg-6">
                        {/* <img className="logo-home wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s" src="assets/img/general/logo-black.svg" alt="" /> */}
                        <img className="logo-home wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s" src="assets/img/general/bt_new_logo.png" alt="" />
                        <h1 className="headding-header wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s" ><span>Welcome to</span> Breakthrough’s Online Courses!</h1>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                        <img width="100%" src="assets/img/general/home-page-pinkey-pankaj.svg" alt="" />
                    </div>
                            <h5>आगे बढ़ने के लिए निचे दिए गए टैब्स पर क्लिक करें:-</h5>
                </div>



                <div className="row mt-20">
                    <div className="col-md-6 col-sm-6 col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                        <div className="serveies-home-page">
                            <img src="assets/img/general/tkt-home-img.jpg" />
                            <div className="box-content">
                             <Static/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                        <div className="serveies-home-page">
                            <img src="assets/img/general/team-change-leaders.jpg" />
                            <div className="box-content">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tkt.inbreakthrough&pli=1"><h3 className="title">Download Android App</h3></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div className="serveies-home-page">
                            <img src="assets/img/general/sld-home.jpg" />
                            <div className="box-content">
                                <a target="_blank" href="https://apps.apple.com/us/app/taaron-ki-toli/id6449253609"><h3 className="title">Download iOS App</h3></a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </header>


    <section className="fo-section">
        <div className="container-fluid cf-set">
            <div className="row">
                <div className="col-lg-12">
                    <h3 className="red find-out-h wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">Find out more about...</h3>
                    <div className="fs-img-box wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <a href="https://inbreakthrough.org/our-work/" target="_blank">Breakthrough’s Work</a>
                    </div>
                    <div className="fs-img-box fs-img-box2 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                        <a href="https://inbreakthrough.org/focus-area/" target="_blank"> Breakthrough’s Focus Areas</a>
                    </div>

                    <div className="fs-img-box fs-img-box3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                        <a href="https://inbreakthrough.org/focus-area/tcl/" target="_blank"> Team Change</a>
                    </div>
                </div>
            </div>
        </div>
    </section>




    <section className="footer-img-large">
        <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between" >
                <div className="col col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                    <a href="https://inbreakthrough.org/e-volunteer-2/" target="_blank" className="btn-set-home text-white">Volunteer With Us</a>
                </div>
                <div className="col col-lg-6 text-right wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                    {/* <NavLink to="https://inbreakthrough.org/donate/" className="btn-set-home text-white"></NavLink> */}
                    <a className="btn-set-home text-white" href="https://inbreakthrough.org/donate/" target="_blank" >Donate To Breakthrough</a>

                </div>
            </div>
        </div>
        <div className=" wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
        <img width="100%" style={{"margin-top": "-1vw;"}} draggable="false" src="assets/img/general/footer-img.svg" alt="" />
        </div>
    </section>

    {/* <section className="footer-img-large">
            <img width="100%" draggable="false" src="assets/img/general/footer-img.svg" alt="" />
    </section> */}



    <Footer />
    </>
  )
}

export default Home