import React,{useState,useEffect} from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";

const Dekho01Activity08Screen = () => {
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");


    const [larkiyochecked, setLarkiyoChecked] = useState("");
    const [larkochecked, setLarkoChecked] = useState("");
    const [donochecked, setDonoChecked] = useState("");
    const [checkBlankData, setCheckBlankData] = useState("blank");


    const user_details = JSON.parse(localStorage.getItem("user_details"));
    const groupId = localStorage.getItem('groupId');

    let userType = 'user';
    let user_id = user_details.user_id;
    if(user_details.role_id == 16)
    {

      userType = 'group';
      user_id =groupId;
    }


    const selectItem = (e) => {
      const selectedItem = e.target.value;

      if (selectedItem === "larkiyo me") {
        setLarkiyoChecked("checked");
        setLarkoChecked("");
        setDonoChecked("");
        setCheckBlankData("filled");
      }

      if (selectedItem === "larko me") {
        setLarkoChecked("checked");
        setLarkiyoChecked("");
        setDonoChecked("");
        setCheckBlankData("filled");
      }

      if (selectedItem === "dono me") {
        setLarkoChecked("");
        setLarkiyoChecked("");
        setDonoChecked("checked");
        setCheckBlankData("filled");
      }

      var data = JSON.stringify({
        user_id: user_id,
        ans: selectedItem,
        ques_id: "5",
      });

      var config = {
        method: "post",
        url: BaseURL + "/dekhoActivity01",
        headers: {
          'Authorization': `Bearer ${user_details.token} `,
          'type': `${userType} `,
          'Content-Type': 'application/json'
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    useEffect(() => {

      var data = JSON.stringify({
        user_id: user_id,
        ques_id: "5",
      });

      var config = {
        method: "post",
        url: BaseURL+"/getDekhoActivity01ByUser",
        headers: {
          'Authorization': `Bearer ${user_details.token} `,
          'type': `${userType} `,
          'Content-Type': 'application/json'
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if(response.data.success === true)
          {
            console.log(response.data.data.ans);
            if(response.data.data.ans === 'larko me')
            {
              setLarkiyoChecked("");
              setLarkoChecked("checked");
              setDonoChecked("");
              setCheckBlankData("filled");
            }

            if(response.data.data.ans === 'larkiyo me')
            {
              setLarkiyoChecked("checked");
              setLarkoChecked("");
              setDonoChecked("");
              setCheckBlankData("filled");
            }

            if(response.data.data.ans === 'dono me')
            {
              setLarkiyoChecked("");
              setLarkoChecked("");
              setDonoChecked("checked");
              setCheckBlankData("filled");
            }

          }
          // console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);

    // console.log(checkBlankData);
    const BtnDisabled = () => {
      if (checkBlankData !== "blank") {
        return (
          <>
            <div
              className="mt-30 mb-15"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <NavLink
                to="/dekho-01-activity-09-screen"
                className="btnset m-auto"
              >
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              className="mt-30 mb-15"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                  <span>आगे बढ़ेंं </span>{" "}
                </NavLink> */}

              <button class="btnset m-auto disabled-btn" type="button" disabled>
                आगे बढ़ेंं
              </button>
            </div>
          </>
        );
      }
    };



  return (
    <>
      <main>
        <section className="bg-flexible FFDCDF">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-30">
              <div className="col-lg-5 dekho-wizard">
                <img src="assets/img/dekho/awaz-change.svg" alt="" />
                <h4 className="mt-10">आवाज़ में बदलाव</h4>
                <ul
                  className="btn-effect mt-10 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <li>
                    <input
                      type="radio"
                      name="dekhobtn"
                      id="cb1"
                      className="graphic"
                      value="larkiyo me"
                      onClick={selectItem}
                      checked={larkiyochecked}
                    />
                    <label htmlFor="cb1">लड़कियों में </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="dekhobtn"
                      id="cb2"
                      className="graphic"
                      value="larko me"
                      onClick={selectItem}
                      checked={larkochecked}
                    />
                    <label htmlFor="cb2">लड़कों में </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="dekhobtn"
                      id="cb6"
                      className="graphic"
                      value="dono me"
                      onClick={selectItem}
                      checked={donochecked}
                    />
                    <label htmlFor="cb6">दोनों में </label>
                  </li>
                </ul>
                {/* <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink to="/dekho-01-activity-09-screen" className="btnset ">
                    <span>आगे बढ़ेंं </span>{" "}
                  </NavLink>
                </div> */}
                <BtnDisabled/>
                <br />
                <ul className="progressbar mt-20">
                  <li className="active">
                    <a href="" />
                  </li>
                  <li className="active">
                    <a href="" />
                  </li>
                  <li className="active">
                    <a href="" />
                  </li>
                  <li className="active">
                    <a href="" />
                  </li>
                  <li className="active">
                    <a href="" />
                  </li>
                </ul>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="90%"
                    src="assets/img/dekho/ahbn-main-img2.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Dekho01Activity08Screen;
