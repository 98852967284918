import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import swal from "sweetalert";
import axios from "axios";
import BaseURL from "../common/Baseurl";

const Khelo03Activity04Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-10"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [checkBlankData, setCheckBlankData] = useState("blank");

  const [parvahChecked, setParvah] = useState();
  const [dostChecked, setDost] = useState();
  const [imendarChecked, setImandar] = useState();
  const [jimmedarChecked, setJimmedar] = useState();
  const [mehanatiChecked, setMehanati] = useState();
  const [gambhirChecked, setGambhir] = useState();
  const [bafadarChecked, setBafadar] = useState();
  const [dayaluChecked, setDayalu] = useState();
  const [majedarChecked, setMajedar] = useState();
  const [dhairyaChecked, setDhairya] = useState();
  const [bharosemandChecked, setBharosemand] = useState();

  //for show message
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const [itemElem, setItemElem] = useState([]);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

    let userType = 'user';
    let user_id = user_details.user_id;
    if(user_details.role_id == 16)
    {

      userType = 'group';
      user_id =groupId;
    }


  const itemArr = [];
  const selectItem = (e) => {
    const item = e.target.value;
    const checked = e.target.checked;



    if (checked === true) {
      setCheckBlankData("filled");
      itemArr.push(item);
      const __selectedItem = itemArr.join();
      setItemElem([...itemElem, __selectedItem]);
      const checkeitem = "checked";
        checkVar(item,checkeitem);
      // setItemElem(__selectedItem)
    }

    if (checked === false) {

      const newarr = itemElem.filter((el) => {
        return el !== item;
      });

      setItemElem(newarr);

      if(itemElem.length > 1)
      {

        setCheckBlankData("filled");
      }else{

        setCheckBlankData("blank");

      }

        const checkeitem = "";
        checkVar(item,checkeitem);



    }

    // console.log(itemElem);
  };




  const ImageChange = ()=>{
    if(itemElem.length == 1)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="70%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 2)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="74%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 3)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="78%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 4)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="84%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 5)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="86%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 6)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="88%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 7)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="90%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 8)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="92%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 9)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="94%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 10)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="96%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else if(itemElem.length == 11)
    {
      return (
        <>
              <img
                    draggable="false"
                    width="98%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )
    }else {

      return (
        <>
              <img
                    draggable="false"
                    width="68%"
                    src="assets/img/khelo/GirlAvatar3.svg"
                  />
        </>
      )

    }
  }



  const submitKhubiya = () => {
    if (itemElem.length > 3) {
      swal("आप सिर्फ 3 ही खूबियाँ चुन सकते हैं!", "", "warning");
      return false;
    }

    var data = JSON.stringify({
      user_id: user_id,
      ans: itemElem.join(),
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity03",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        navigate('/khelo-03-activity-05-screen');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL+"/getKheloActivity03ByUser",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          const ans = response.data.data.ans;
          console.log(ans);
          const __ans = ans.split(",");
          setItemElem(__ans);
          __ans.forEach((el)=>{
            const checkeitem = "checked";
            checkVar(el,checkeitem);
          })


         setCheckBlankData("filled");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  },[]);


  const checkVar = (el,checkeitem)=>{


    if (el === "दोस्त") {
      setDost(checkeitem);
    }
    if (el === "ईमानदार") {
      setImandar(checkeitem);
    }

    if (el === "परवाह करने वाला") {
      setParvah(checkeitem);
    }
    if (el === "ज़िम्मेदार") {
      setJimmedar(checkeitem);
    }
    if (el === "परिश्रमी/ मेहनती") {
      setMehanati(checkeitem);
    }
    if (el === "गंभीर") {
      setGambhir(checkeitem);
    }
    if (el === "जिज्ञासु") {
      setBafadar(checkeitem);
    }
    if (el === "दयालु") {
      setDayalu(checkeitem);
    }
    if (el === "मज़ेदार") {
      setMajedar(checkeitem);
    }
    if (el === "धैर्यवान") {
      setDhairya(checkeitem);
    }
    if (el === "भरोसेमंद") {
      setBharosemand(checkeitem);
    }
  }



  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div className="mt-15 mb-15">
            <center>
              <button
                className="theme_btn theme_btn_bg chnage-d purple-shadow m-auto"
                onClick={submitKhubiya}
              >
                आगे बढ़ेंं{" "}
              </button>
            </center>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-10 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible EEFFE8">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />

          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center mt-50">
              <div className="col-lg-7">
                <span className="showError">{message}</span>

                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  आप सिर्फ 3 ही खूबियाँ चुन सकते हैं

                </h1>

                <ul
                  className="btn-effect amu-btn-shadow-none absn-2-mp wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <li>
                    <input
                      type="checkbox"
                      id="cb1"
                      className="graphic"
                      value="परवाह करने वाला"
                      checked={parvahChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb1">परवाह करने वाला</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb2"
                      className="graphic"
                      value="दोस्त"
                      checked={dostChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb2">दोस्त</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb3"
                      className="graphic"
                      value="ईमानदार"
                      checked={imendarChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb3">ईमानदार</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb4"
                      className="graphic"
                      value="ज़िम्मेदार"
                      checked={jimmedarChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb4">ज़िम्मेदार</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb5"
                      className="graphic"
                      value="परिश्रमी/ मेहनती"
                      checked={mehanatiChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb5">परिश्रमी/ मेहनती</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb6"
                      className="graphic"
                      value="गंभीर"
                      checked={gambhirChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb6">गंभीर</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb7"
                      className="graphic"
                      value="जिज्ञासु"
                      checked={bafadarChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb7">जिज्ञासु</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb8"
                      className="graphic"
                      value="दयालु"
                      checked={dayaluChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb8">दयालु</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb9"
                      className="graphic"
                      value="मज़ेदार"
                      checked={majedarChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb9">मज़ेदार </label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb10"
                      className="graphic"
                      value="धैर्यवान"
                      checked={dhairyaChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb10">धैर्यवान</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="cb11"
                      className="graphic"
                      value="भरोसेमंद"
                      checked={bharosemandChecked}
                      onClick={selectItem}
                    />
                    <label htmlFor="cb11">भरोसेमंद</label>
                  </li>
                </ul>
                <BtnDisabled />
              </div>

              <div
                className="col-lg-4  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <NavLink to="#">
                  <center>
                   <ImageChange/>
                  </center>
                </NavLink>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo03Activity04Screen;
