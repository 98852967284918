import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo10Activity01Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg">
          <div className="fixed-header">
              <Name/>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-20">
                <div className="col-lg-4">
                  <h1
                    className="h1 mt-m-1 mb-20 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                   खेलो
                  </h1>
                  <div
                    className="karo-activity-barabri wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <h4>बड़ों से बातचीत </h4>
                    <img
                      className="mt-20"
                      width="85%"
                      src="assets/img/khelo/bsbc-chune.svg"
                      alt=""
                    />
                    <div className="mt-30">
                      <NavLink
                        to="/khelo-10-activity-02-screen"
                        className="btnset radius-set m-auto"
                      >
                        <span>चुनें</span>{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="100%"
                      src="assets/img/khelo/bsbc-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo10Activity01Screen;
