import { type } from '@testing-library/user-event/dist/type'
import React from 'react'
import './style.css';

const FlashMessage = (props) => {
    
  return (
  <>
      <div className={`alert alert-${props.type}`} role="alert"  >                    
                        <p>{props.message}</p>
          
          </div>

                 

  </>
  )
}

export default FlashMessage