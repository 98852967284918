import React, { useState, useEffect } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink } from "react-router-dom";

const Khelo11Activity20Screen = () => {
  const [ansResult, setAnsResult] = useState();
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "9",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity11ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const ans_result = response.data.data.ans_result;
          setAnsResult(ans_result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // console.log(ansResult);

  const AnsResult = () => {
    if (ansResult === "wrong") {
      return (
        <>
          <div
            className=" mt-15 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <button type="button" className="galat-btn">
            आपका उत्तर गलत है
            </button>
          </div>
        </>
      );
    } else if(ansResult === "correct"){
      return (
        <>
          <div
            className=" mt-15 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <button type="button" className="galat-btn A777">
            आपका उत्तर सही है{" "}
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible F5F0FF">
          <div className="fixed-header">
            <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="mt-m-2 col-lg-4">
                  <AnsResult />

                  <h1
                    className="h2 mt-m-1 text-center mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >

"झूठ बोलने से लोगों का हम पर से भरोसा टूटता है | उनके साथ हमारा रिश्ता कमज़ोर होता जाता है।
"

                  </h1>
                  <div
                    className="mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <NavLink to="/khelo-11-activity-21-screen" className="btnset m-auto">
                      <span>आगे बढ़ेंं </span>{" "}
                    </NavLink>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/kythapl-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo11Activity20Screen;
