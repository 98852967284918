import React from "react";
import { NavLink } from "react-router-dom";

const Gyanbox01KheloActivityScreen = () => {
  return (
    <>
      <main>
        <section className="bg-boarding star-bg">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  ज्ञान बॉक्स
                </h2>
              </div>
              <div className="col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/khelo-01-activity-02-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div className="row align-items-center justify-content-center ">
              <div
                className="col-lg-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                <p className="mpara large-para fs-30 mt-100 text-center">
                  आज जो हमने मिलकर नारा तैयार किया है वह तारों की टोली की पहचान
                  बन सकता है। आप अपने साथियों के साथ मिलकर इस के जैसे और भी नारे
                  तैयार कर सकते हैं और हमारे साथ साझा कर सकते हैं।{" "}
                </p>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <NavLink to="#" className="width-p">
                  <center>
                    <img
                      draggable="false"
                      className="mb-15"
                      width="90%"
                      src="assets/img/khelo/hamara-nana-img.svg"
                    />
                  </center>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Gyanbox01KheloActivityScreen;
