import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import BaseURL,{AdminUrl} from "../common/Baseurl";

const Khelo04Activity07Screen = () => {

    const Ref = useRef(null);

  const [timer, setTimer] = useState("00:00:00");
  const [checkBlankData, setCheckBlankData] = useState("blank");
  const [selectedItem, setSelectedItem] = useState();
  const [dutichandChecked, setDutiChand] = useState();
  const [sakshiChecked, setSakshi] = useState();
  const [sainanehwalChecked, setSainanehwal] = useState();
  const [pvsindhuChecked, setPvSindhu] = useState();
  const [ektaChecked, setEkta] = useState();

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const selectItem = (e) => {
    const selectedItem = e.target.value;



    const checked = "checked";
          checkVal(selectedItem,checked);


    var data = JSON.stringify({
      user_id: user_id,
      ans: selectedItem,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity04",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setSelectedItem(selectedItem);
          setCheckBlankData("filled");
          // const checked = "checked";
          // checkVal(selectedItem, checked);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity04ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const selectedItem = response.data.data.ans;
          console.log(selectedItem);
          setSelectedItem(selectedItem);
          setCheckBlankData("filled");
          const checked = "checked";
          checkVal(selectedItem, checked);
        }

        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const checkVal = (el, checked) => {
    if (el === "duti chand") {
        setDutiChand(checked);
        // setDutiChand('');
        setSakshi('');
        setSainanehwal('');
        setPvSindhu('');
        setEkta('');

      return false;
    }
    if (el === "sakshi malik") {
        setSakshi(checked);
        setDutiChand('');
        // setSakshi('');
        setSainanehwal('');
        setPvSindhu('');
        setEkta('');
      return false;
    }
    if (el === "saina nehwal") {
        setSainanehwal(checked);
        setDutiChand('');
        setSakshi('');
        // setSainanehwal('');
        setPvSindhu('');
        setEkta('');
      return false;
    }
    if (el === "pv sindhu") {
        setPvSindhu(checked);
      return false;
    }
    if (el === "ekta bhayan") {
        setEkta(checked);
        setDutiChand('');
        setSakshi('');
        setSainanehwal('');
        setPvSindhu('');
        // setEkta('');
      return false;
    }

  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00:50");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 50);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);


  const audio = new Audio(
		AdminUrl+"/alarm.mp3"
	  );


if(timer === '00:00:10')
{
  audio.play();
}



  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div
                    className="mt-30  text-center "

                  >
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d"
                      to="/khelo-04-activity-08-screen"
                    >
                      सही उत्तर जानिए
                    </NavLink>
                  </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-30  text-center"

          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                  <span>आगे बढ़ेंं </span>{" "}
                </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
            सही उत्तर जानिए!
            </button>
          </div>
        </>
      );
    }
  };


  return (
    <>
      <main>
        <section className="bg-flexible F8F1FD">
        <Name/>
        <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-center">
              <div className="col-lg-3">
                <div
                  className="mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <NavLink
                    className="theme_btn theme_btn_bg chnage-d step-btn"
                    to="#"
                  >
                    सवाल <span className="span-c-change">03</span>
                  </NavLink>
                </div>
                <p
                  className="mpara large-para mt-20 fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.6s"
                >
                  {" "}
                  क्या आप इस खिलाड़ी को जानते हैं?{" "}
                </p>
                <div
                  className="img-box-fixed wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.7s"
                >
                  <img src="assets/img/khelo/raster-img/shayna-nehwal.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-7 offset-lg-1">
                <div
                  className="row align-items-center justify-content-center wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="col-lg-4">
                    <div className="content-area">
                      <div className="invisible-checkboxes kkm-btn">
                        <input
                          type="radio"
                          name="k1"
                          defaultValue="duti chand"
                          id="kkm1"
                          onClick={selectItem}
                            checked={dutichandChecked}
                        />
                        <label
                          className="checkbox-alias activity-btn"
                          htmlFor="kkm1"
                        >
                          <h4 className="slider-h"> दुती चंद</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="content-area">
                      <div className="invisible-checkboxes kkm-btn">
                        <input
                          type="radio"
                          name="k1"
                          defaultValue="sakshi malik"
                          id="kkm2"
                          onClick={selectItem}
                            checked={sakshiChecked}
                        />
                        <label
                          className="checkbox-alias activity-btn"
                          htmlFor="kkm2"
                        >
                          <h4 className="slider-h"> साक्षी मलिक</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="content-area">
                      <div className="invisible-checkboxes kkm-btn">
                        <input
                          type="radio"
                          name="k1"
                          defaultValue="saina nehwal"
                          id="kkm3"
                          onClick={selectItem}
                            checked={sainanehwalChecked}
                        />
                        <label
                          className="checkbox-alias activity-btn"
                          htmlFor="kkm3"
                        >
                          <h4 className="slider-h">साइना नेहवाल</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row align-items-center justify-content-center wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <div className="col-lg-4">
                    <div className="content-area">
                      <div className="invisible-checkboxes kkm-btn">
                        <input
                          type="radio"
                          name="k1"
                          defaultValue="pv sindhu"
                          id="kkm4"
                          onClick={selectItem}
                            checked={pvsindhuChecked}
                        />
                        <label
                          className="checkbox-alias activity-btn"
                          htmlFor="kkm4"
                        >
                          <h4 className="slider-h"> पी वी सिंधु</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="content-area">
                      <div className="invisible-checkboxes kkm-btn">
                        <input
                          type="radio"
                          name="k1"
                          defaultValue="ekta bhayan"
                          id="kkm5"
                          onClick={selectItem}
                            checked={ektaChecked}
                        />
                        <label
                          className="checkbox-alias activity-btn"
                          htmlFor="kkm5"
                        >
                          <h4 className="slider-h">एकता भयान </h4>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <BtnDisabled/>
                <div
                  className="mt-30 d-flex align-items-center justify-content-center  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.7s"
                >
                  <div className="mr-20">
                    <div className="timer timer-small">
                      <div className="content-alarm">
                           <p>{timer}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <NavLink
                      to="#"
                      data-toggle="modal"
                      data-target="#exampleModalCenter"
                      onClick={handleShow}
                    >
                      <div className="theme_btn theme_btn_bg chnage-d mt-15 hint-p">
                        <img src="assets/img/general/hint.svg" alt="" />
                      </div>
                      <br />
                      <p className="text-center hint-font"> हिंट</p>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modalmenage">
        <Modal.Body>
          <>
            <div className="modal-content">
              <button
                type="button"
                className="close close-player"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>

              <div className="border-box popop-set">
                <div className="content pset-popup">
                  <div
                    className="wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <center>
                      <img src="assets/img/khelo/hint-2-img.svg" alt="" />
                    </center>
                  </div>
                  <p className="black mt-30 fs-30 text-center">
                  ये भारत सरकार द्वारा पदमश्री और सर्वोच्च खेल पुरस्कार राजीव गांधी खेल रत्न पुरस्कार से सम्मानित हो चुकी है!
                  </p>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Khelo04Activity07Screen;
