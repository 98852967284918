import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";

import Name from "../common/Name";

const Khelo09Activity04Screen = () => {
 

 
  return (
    <>
      <main>
        <section className="bg-flexible bg-img-mobile khelo-bg mblh-8">
          <div className="fixed-header">
           <Name/>
          </div>
          <div
            className="footer-back mb-30 agge-bade-btn-set"
           
          >
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center ">
                <div className="col-lg-12">
                  <div className="mr-20">
                    <NavLink to="/khelo-09-activity-03-screen">
                      <button type="button" className="btnset btn-large m-auto">
                        {" "}
                        दुबारा कोशिश करें{" "}
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo09Activity04Screen;
