import React from "react";
import { NavLink } from "react-router-dom";

const BtnDisabled = (props) => {
  if (props.checkBlankData !== "blank") {
    return (
      <>
        <div className={props.class1}>
          <div
            className={props.class2}
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <center>
              <NavLink
                className="theme_btn theme_btn_bg chnage-d purple-shadow m-auto"
                to={props.toUrl}
              >
                आगे बढ़ेंं{" "}
              </NavLink>
            </center>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
       <div className={props.class1}>
        <div
          className={props.class2}
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink> */}

          <button className="btnset m-auto disabled-btn" type="button" disabled>
            आगे बढ़ेंं
          </button>
        </div>
        </div>
      </>
    );
  }
};

export default BtnDisabled;
