import React,{useState} from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Dekho02Activity03Screen = () => {

    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");

  return (
    <>
      <main>
        <section className="bg-flexible FFFADD">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">
           

            <div className="row align-items-center justify-content-center mt-30">
              <div className="col-lg-5">
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  आशा करते हैं आपको ये एक्टिविटी पसंद आई होगी और झोलेवाली दीदी
                  से आपने बहुत कुछ सीखा होगा।{" "}
                </p>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  {" "}
                  यहाँ पर कुछ सवाल दिए गए है, अगर आपने वीडियो को ध्यान से देखा
                  है तो इन सवालों के उत्तर दें
                </p>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink to="/dekho-01-activity-04-screen" className="btnset ">
                    <span>आगे बढ़ेंं </span>{" "}
                  </NavLink>
                </div>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="85%"
                    src="assets/img/dekho/ahbn-main-img2.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Dekho02Activity03Screen;
