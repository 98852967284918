import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Name from "../common/Name";

const Khelo04Activity04Screen = () => {
  const [user_star, setUser_star] = useState();

  const user_details = JSON.parse(localStorage.getItem("user_details"));

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity04ByUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setUser_star(response.data.data.user_star);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const Result = () => {
    if (user_star === 1) {
      return (
        <>
          <div
            className="six-tare  wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1 vw-2-5 green">
              शाबाश! सही जवाब है <br />
              मैरी कॉम!
            </h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{user_star}</h4>
              </li>
            </ul>
          </div>
        </>
      );
    } else if (user_star === 0){
      return (
        <>
          <div
            className="six-tare  wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1 vw-2-5 red">
              गलत जवाब! <br /> सही जवाब है मैरी कॉम!
            </h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{user_star}</h4>
              </li>
            </ul>
          </div>
        </>
      );
    }
  };


  return (
    <>
      <main className=" F8F1FD ">
        <section className="overflowsetbody  height-auto">
          <Name />
          <div className="container-fluid cf-set">
            <div className="row  mt-30">
              <div className="col-lg-5">
                <Result />
                <h1
                  className="mt-40 red wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  आपकी जानकारी के लिए -
                </h1>{" "}

              </div>

              <div className="col-lg-12">
                <div className="row">
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img src="assets/img/khelo/raster-img/geeta-fogart.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>गीता फोगाट</h3>
                        <p>
                        गीता फोगाट एक भारतीय महिला फ्रीस्टाइल (freestyle) पहलवान हैं जिन्होंने पहली बार भारत के लिए राष्ट्रमंडल खेलों में स्वर्ण पदक जीता था।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img src="assets/img/khelo/raster-img/pt-usha.jpeg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>पी.टी. उषा </h3>
                        <p>

                          पी.टी. उषा भारत की महानतम खिलाड़ियों/धावकों में से एक हैं | पी.टी. उषा लंबे स्ट्राइड (stride) के साथ एक बेहतरीन स्प्रिंटर (sprinter) थीं। उन्होंने कुल 23 पदक जीते, जिनमें से 14 स्वर्ण पदक थे।

                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img src="assets/img/khelo/raster-img/saniya-mirza.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>सानिया मिर्ज़ा</h3>
                        <p>
                        सानिया मिर्ज़ा अब तक भारत की सबसे बेहतरीन महिला टेनिस (tennis) खिलाड़ी हैं। सानिया मिर्ज़ा ने 2003 से 2023 तक अपने शानदार करियर में भारतीय टेनिस के लिए नई ऊँचाइयों को छुआ है।
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img src="assets/img/khelo/raster-img/s-khiladi-img.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>मैरी कॉम</h3>
                        <p>
                        मैरी कॉम ने मुक्केबाज़ी को अपने करियर के तौर पर चुना | मैरी कॉम ने विश्व मुक्केबाज़ी चैंपियनशिप में पदक हासिल किया है, इसमें 6 स्वर्ण, 1 रजत और 1 कांस्य पदक शामिल है।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img src="assets/img/khelo/raster-img/deepa-malik.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>दीपा मलिक </h3>
                        <p>
                        दीपा मलिक, शॉटपुट एवं भाला फेंक  (javelin throw) के साथ-साथ तैराकी एवं मोटर रेसिंग से जुड़ी एक विकलांग भारतीय खिलाड़ी हैं जिन्होंने 2016 पैरालंपिक में शॉटपुट में रजत पदक जीतकर इतिहास रचा।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div
                      className="mt-30  wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <NavLink
                        className="theme_btn theme_btn_bg chnage-d"
                        to="/khelo-04-activity-05-screen"
                      >
                        अगला सवाल
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo04Activity04Screen;
