import React, { useState, useEffect } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink } from "react-router-dom";

const Khelo08Activity03Screen = () => {
  const [larkaCss, setLarkaCss] = useState();
  const [larkiCss, setLarkiCss] = useState();
  const [donoCss, setDonoCss] = useState();
  const [checkBlankData, setBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  const selectAns = (e) => {
    const selectVal = e.target.value;
    setBlankData("filled");
    checkValue(selectVal);
    //   console.log(selectVal);

    var data = JSON.stringify({
      user_id: user_id,
      ans: selectVal,
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity08",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const checkValue = (selectVal) => {
    if (selectVal === "larka") {
      setLarkaCss({ opacity: "1" });
      setLarkiCss({ opacity: "0.1" });
      setDonoCss({ opacity: "0.1" });
    }

    if (selectVal === "larki") {
      setLarkiCss({ opacity: "1" });
      setLarkaCss({ opacity: "0.1" });
      setDonoCss({ opacity: "0.1" });
    }

    if (selectVal === "dono") {
      setDonoCss({ opacity: "1" });
      setLarkiCss({ opacity: "0.1" });
      setLarkaCss({ opacity: "0.1" });
    }
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity08ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const selectVal = response.data.data.ans;
          checkValue(selectVal);
          setBlankData("filled");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div
            className="mt-30 "
          >
            <NavLink to="/khelo-08-activity-04-screen" className="btnset m-auto ">
              आगे बढ़ेंं
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-30"
          >
            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible F0F4FF">
          <div className="fixed-header">
              <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                  <div
                    className=" mt-m-2 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <button className="theme_btn theme_btn_bg chnage-d step-btn">
                      <span className="span-c-change">01</span>
                    </button>
                  </div>
                  <h1
                    className="h1 mt-30 mt-m-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    खाना बनाना
                  </h1>
                  <div
                    className="gif-box-set mt-15 pt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <center>
                      <img
                        width="80%"
                        src="assets/img/khelo/gif/khana-banana.gif"
                        alt=""
                      />
                    </center>
                  </div>
                </div>
                <div className="col-lg-3 mt-m-2 offset-lg-1">
                  <div className="kkkn-check-effect">
                    <div
                      className="activity-box invisible-checkboxes kkkn-yellow-bg wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                      style={larkaCss}
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="larka"
                        id="t1"
                        onClick={selectAns}
                      />
                      <label
                        className="checkbox-alias kkkn-yellow-bg-sub"
                        htmlFor="t1"
                      >
                        <h4 className="slider-h burai-text">
                          <span>
                            <img
                              src="assets/img/khelo/icon/kkkn-ladka.svg"
                              alt=""
                            />
                          </span>{" "}
                          लड़का{" "}
                        </h4>
                      </label>
                    </div>
                    <div
                      className="activity-box invisible-checkboxes kkkn-yellow-bg green-bg blue-bg mt-15 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                      style={larkiCss}
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="larki"
                        id="b1"
                        onClick={selectAns}
                      />
                      <label
                        className="checkbox-alias kkkn-yellow-bg-sub kkkn-blue-bg"
                        htmlFor="b1"
                      >
                        <h4 className="slider-h burai-text">
                          {" "}
                          <span>
                            <img
                              src="assets/img/khelo/icon/kkkn-ladki.svg"
                              alt=""
                            />
                          </span>{" "}
                          लड़की
                        </h4>
                      </label>
                    </div>
                    <div
                      className="activity-box invisible-checkboxes kkkn-yellow-bg kkkn-pink-bg  mt-15 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                      style={donoCss}
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="dono"
                        id="b2"
                        onClick={selectAns}
                      />
                      <label
                        className="checkbox-alias kkkn-yellow-bg-sub kkkn-pin-bg-sub "
                        htmlFor="b2"
                      >
                        <h4 className="slider-h burai-text">
                          {" "}
                          <span>
                            <img
                              src="assets/img/khelo/icon/kkkn-dono.svg"
                              alt=""
                            />
                          </span>{" "}
                          दोनों
                        </h4>
                      </label>
                    </div>
                    <BtnDisabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo08Activity03Screen;
