import React,{useState,useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Name from '../common/Name';
import { Modal, Button, Form } from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';
import BaseURL from '../common/Baseurl';

const Karo07Activity06Screen = () => {
    const navigate = useNavigate();
    const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-3");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
	const groupId = localStorage.getItem('groupId');

	let userType = 'user';
	let user_id = user_details.user_id;
	if(user_details.role_id == 16)
	{

	  userType = 'group';
	  user_id =groupId;
	}


  useEffect(() => {
    const timer = setTimeout(() => {
      handleShow()
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);


  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  // retry

  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/karo-07-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "karo",
          "activity_key_no": "karo07",
          "activity_no": "7"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            'type': `${userType} `,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }



  return (
    <>
        <main>

        <section className="bg-flexible EEECE2">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">

           <div className="container-fluid cf-set">




               <div className="row align-items-center justify-content-center">
                   <div className="col-lg-4 fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
                       <h1 className="bahut-khub">बधाई हो</h1>

                       <div className="six-tare wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                           <h1 className="rh1">आपके बारे में जान कर अच्छा लगा  </h1>
                           <p className="text-center"> तारे इकट्ठे हुए </p>
                           <ul className="ul-r">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><h4>10</h4></li>
                           </ul>

                       </div>




                       <div className="d-flex mt-30 gkw-img">
                           <div className="mr-20">
                               <div className="gkw-img d-flex wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                               <NavLink onClick={retryActivity} to="#">
                                <center>
                                  <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                                </center>
                                <p className="side-btn">दुबारा कोशिश   करें</p>
                              </NavLink>

                               </div>
                           </div>
                           {/* <div className="mr-20">
                               <NavLink to="/gyanbox-07-activity-screen">
                                   <center><img src="assets/img/icon/gyan-box.svg" alt="" /></center>
                                   <p className="side-btn">ज्ञान बॉक्स</p>
                               </NavLink>

                           </div> */}

                           <div >
                               <div className=" wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                                   <NavLink to="/board-screen" className="btnset "><span>अगली एक्टिविटी </span> </NavLink>
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
                       <center><img draggable="false" width="100%" src="assets/img/karo/kisse-hai-meri-pehchan.svg" /></center>
                   </div>



               </div>
               </div>
           </div>
        </section>






   </main>

   <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
          <button
                      type="button"
                      className="close c-newp"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <span aria-hidden="true">×</span>
                    </button>

            <div className="addjustment-popup gyan-box-padding">
              <div className="container-fluid">
                <div className="row align-items-center justify-content-between ">
                  <div className="col-lg-11 col-md-6 col-sm-6">
                    <h2 className="h2 kkh-text">
                      {" "}
                      <img
                        className="mr-20 kkh-home-img"
                        src="assets/img/icon/home.png"
                        alt=""
                      />
                      ज्ञान बॉक्स
                    </h2>
                  </div>

                </div>

            <div class="row align-items-center justify-content-center mt-50">
              <div class="col-lg-6">
                <p
                  class="mpara large-para fs-23 mt-20 text-leff wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >


                  आमतौर पर हमें पिता के नाम से ही जाना जाता है, जबकि हमारी
                  परवरिश की बड़ी ज़िम्मेदारी तो माँ भी निभाती हैं। क्यों, हम महिला
                  के योगदान को महत्व नहीं देते? क्यों हम भूल जाते हैं कि हमारी
                  पहचान सिर्फ पुरुषों से नहीं बल्कि उतनी ही महिलाओं से है । इसलिए ज़रूरी है कि हम आज से इस पहचान सर्टिफिकेट को याद
                  रखें और अपनी माँ के नाम से भी जोड़ें। सभी परिवार एक जैसे नहीं
                  होते किसी में सिंगल या किसी में सेम जेन्डर के पेरेंट्स या
                  अभिभावक हो सकते हैं। हम में से कई लोग ‘पारंपरिक' परिवार में
                  नहीं रहते होंगे। हमें  इस आधार पर किसी के साथ न्याय या भेदभाव
                  नहीं करना चाहिए कि वे किस तरह के परिवार से हैं। हर कोई सम्मान
                  का पात्र है!
                </p>



              </div>
              <div
                class="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                <NavLink to="#">
                  <center>
                    <img
                      draggable="false"
                      class="mb-15"
                      width="90%"
                      src="assets/img/karo/gyan-box-card.svg"
                    />
                  </center>
                </NavLink>
              </div>
            </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default Karo07Activity06Screen