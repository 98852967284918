import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";


const Khelo01Activity09Screen = () => {
    const navigate = useNavigate();
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-6");

  return (
    <>
         <main>
		
        <section className="bg-flexible FFEBEB">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
            <div className="container-fluid cf-set">




                <div className="row align-items-top justify-content-between mt-30">
                    <div className="col-lg-4">
                        <h1 className="h1 mb-20 mt-m-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">चौथा स्तर    </h1>

                        <p className="timepara wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">शेष समय   00 : 50  </p>

            
                    </div>
                    <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                        <NavLink to="/khelo-01-activity-10-screen" ><img className="mt-10 activity-number-img" src="assets/img/khelo/chautha-star.svg" alt="" /></NavLink>
                    </div>
                    <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                       <img  width="85%" src="assets/img/khelo/hamara-nana-img.svg" alt="" />
                    </div>


                </div>
            </div>
            </div>
         </section>
		

    </main>
	

    </>
  )
}

export default Khelo01Activity09Screen