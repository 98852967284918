import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Samasya from "../common/Samasya";
import Activity5Menu from "../common/Activity5Menu";
import BtnDisabled from "../common/BtnDisabled";

const Karo05Activity04Screen = () => {
  const [lg, setLg] = useState("col-lg-10");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [SmNo,setSmNo] = useState('02');
  const [checked,setChecked] = useState();
  const [checkedOption1,setCheckedOption1] = useState();
  const [checkedOption2,setCheckedOption2] = useState();
  const [checkedOption3,setCheckedOption3] = useState();
  const [checkBlankData, setCheckBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKaroActivity05ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setCheckBlankData('filled');

            const selectedData = response.data.data.ans;
            console.log(selectedData);

            if (selectedData === "option1") {

                setCheckedOption1("checked");
                return false;
            }

            if (selectedData === "option2") {
                // console.log('heo')
                setCheckedOption2("checked");
                return false;
            }

            if (selectedData === "option3") {
                setCheckedOption3("checked");
                return false;
            }


        }else{
            setCheckedOption1('');
            setCheckedOption2('');
            setCheckedOption3('');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);




  const selectOption = (e) => {
    const ans = e.target.value;

    setCheckBlankData('filled');

    var data = JSON.stringify({
      user_id: user_id,
      ans: ans,
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity05",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if(ans === 'option1')
        {
          setCheckedOption1('checked');
          setCheckedOption2('');
          setCheckedOption3('');

        }

        if(ans === 'option2')
        {
          setCheckedOption2('checked');
          setCheckedOption1('');

          setCheckedOption3('');

        }

        if(ans === 'option3')
        {
          setCheckedOption3('checked');
          setCheckedOption2('');
          setCheckedOption1('');



        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <main>
        <section className="bg-flexible blues">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} SmNo={SmNo} />
            <div class="data-adjust">

          <div className="container-fluid cf-set">


            <div className="row align-items-center justify-content-between">
              <div className="col-lg-12">
                <h1
                  className="h1 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  भयभीत कर धौंस जमाना
                </h1>
              </div>
              <div className="col-lg-5 pr-50 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">


                        <div className="gif-box-set">
                            <center><img width="80%" src="assets/img/karo/gif/bhebheet-hone.gif" alt="" /></center>
                        </div>
                        <p className="mpara large-para fs-24 mt-40">सीन: स्कूल के बाहर  कुछ लड़के संजीव को परेशान कर रहे हैं और उसे भयभीत कर उसकी साइकिल की चाबी लेने की कोशिश कर रहे हैं।  संजीव ऐसी परिस्थिति में कुछ नहीं कर पा रहा और अपना मुँह छुपा कर रोने लगता है।
                            ऐसे में उसको क्या करना चाहिए? </p>
                    </div>

              <div className="col-lg-7">
                <div className="row ">
                  <div
                    className="col-lg-6 mt-m-2 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <div className="activity-box invisible-checkboxes green-bg">
                      <input
                        type="radio"
                        name="rGroup"
                        value="option1"
                        id="t1"
                        checked={checkedOption1}
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias green-bg-s" for="t1">
                        <h4 className="slider-h burai-text">
                          {" "}
                          संजीव वहां से रोते-रोते भाग जाता है।{" "}
                        </h4>
                      </label>
                    </div>

                    <div
                      className="activity-box invisible-checkboxes red-bg mt-40 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        value="option2"
                        id="b2"
                        checked={checkedOption2}
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias red-bg-s" for="b2">
                        <h4 className="slider-h burai-text">
                          {" "}
                          संजीव बाकी लोगोंं से लड़ाई करता है और उनसे खतरा मोल लेता
                          है।{" "}
                        </h4>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-m-2">
                    <div
                      className="activity-box invisible-checkboxes green-bg blue-bg wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        value="option3"
                        id="b1"
                        checked={checkedOption3}
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias blue-bg-s" for="b1">
                        <h4 className="slider-h burai-text">
                          {" "}
                          अपनी परेशानी किसी भरोसेमंद बड़े व्यक्ति से साझा करता है
                          और बाकी लोगों से बातचीत कर के मदद लेता है।
                        </h4>
                      </label>
                    </div>



                    <BtnDisabled
                    checkBlankData={checkBlankData}
                    toUrl="/karo-05-activity-05-screen"
                    class1="col-lg-6 mt-30"
                    class2="mt-40 mb-15 wow fadeInDown"
                  />

                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo05Activity04Screen;
