import React,{useState} from 'react';
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo01Activity01Screen = () => {
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-3");
  return (
    <>
          <main>
		
        <section className="bg-flexible khelo-bg">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">
           <div className="container">

               <div className="row align-items-center justify-content-center">
                  
                   <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <h1 className="h1 mb-20 mt-m-1">खेलो  </h1>
                  
                       <div className="karo-activity-barabri wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                           <h4>हमारा नारा </h4>
                           <img className="mt-20" width="70%" src="assets/img/khelo/hamara-nara-chune-img.svg" alt="" />
                           <div>
                               <NavLink to="/khelo-01-activity-02-screen" className="btnset radius-set m-auto"><span>चुनें</span> </NavLink>
                           </div>
                       </div>

                       
                       
                   </div>
                   <div className="col-lg-4 mt-m-1 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <center><img draggable="false"   width="90%" src="assets/img/khelo/hamara-nana-img.svg" /></center>
                   </div>
               </div>
               </div>


           </div>
        </section>
   
       

   </main>
    </>
  )
}

export default Khelo01Activity01Screen