import React, { useEffect, useState,useRef } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import swal from "sweetalert";

import axios from "axios";
import BaseURL from "../common/Baseurl";

import useRecorder from "../UseRecorder";

const Khelo05Activity05Screen = () => {


  const ref = useRef(null);

    const navigate = useNavigate();
    let [
      audio,
      audioURL,
      isRecording,
      startRecording,
      stopRecording,
      startTime,
      endTime
    ] = useRecorder();

  // console.log(ref.current);

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const [counter, setCounter] = useState(0);

  const clearTimerRef = useRef();


  const stopFunction = () => {
      //  clearInterval(clearIntervalRef.current)
       clearTimeout(clearTimerRef.current)
      //  setCounter(0);
  }



  const startSetInterval = ()=>{
    // console.log(counter);
    clearTimerRef.current = setTimeout(() => setCounter(counter + 1), 1000);

  }



    useEffect(() => {

      if(counter == 60){
        // console.log('counter'+counter);

        ref.current.click();

        stopFunction()

      }


    }, [counter]);






  const saveAudio = () => {
    if (
      startTime === undefined ||
      startTime === null ||
      endTime === undefined ||
      endTime === null
    ) {
      swal(
        "कृपया अपनी रिकॉर्डिंग शुरू और बंद करें!",
        "",
        "warning"
      );
      return false;
    }

    const recordingDuration = endTime - startTime;

  //  console.log(endTime);
  //  console.log(startTime);
  //  return false;

    const data = {
      user_id: user_id,
      duration: counter,
      // ans: audioURL,
      ques_id: "2",
    };

    let fileName = Date.now() + "recording.webm";
    let file = new File([audio], fileName);
    const formData = new FormData();
      formData.append('files.file', file);
      formData.append('data', JSON.stringify(data));



      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BaseURL + "/kheloActivity05",
        headers: {
          'type': `${userType} `,
          'Content-Type': 'multipart/form-data'
        },
        data : formData
      };



      // let config = {
      //   header: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // }

      axios.request(config).then(response => {

        navigate('/khelo-05-activity-06-screen')
      }).catch(error => {
        console.log('error', error)
      });




  };

  const RecordingStart = () => {

    if (isRecording === true) {
      startSetInterval();

      return (
        <>
          <div>
            {" "}
            <button
              onClick={startRecording}
              disabled={isRecording}
              className="recording-btn start"
            >
              रिकॉर्डिंग...
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            {" "}
            <button
              onClick={startRecording}
              disabled={isRecording}
              className="recording-btn start"
            >
              शुरू करे
            </button>
          </div>
        </>
      );
    }
  };




  const RecordingStop = () => {

    if (isRecording) {
      return (
        <>
          <div>
            {" "}
            <button
            ref={ref}
              onClick={stopRecording}
              disabled={!isRecording}
              className="recording-btn stop"
            >
              रुकना
            </button>
          </div>
        </>
      );
    }
  };


  return (
    <>
      <main>
        <section className="bg-flexible khelo-bg FFE9E9">
          <Name />
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-50">
              <div className="col-lg-4">
                <h1
                  className="h1 text-center mb-20 mt-0 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  रिकॉर्डिंग शुरू करें{" "}
                </h1>
                <div
                  className="mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="mange-audio audi-percent">
                  <span className="audioTimer audioTimer2">00:{counter}</span>
                  <div className="edit-audio editAudioTimer">
                      <audio src={audioURL} controls />

                      {/* <audio
                        controls=""
                        src="https://instrumentbible.github.io/sampler.js/assets/kick.wav"
                      />
                      <input type="file" /> */}
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-center mt-20 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                 <RecordingStart />
                    <RecordingStop />
                  {/* <div>
                    {" "}
                    <button
                      onClick={stopRecording}
                      disabled={!isRecording}
                      className="recording-btn stop"
                    >
                      stop
                    </button>
                  </div> */}
                </div>
                <div
                  className="mt-15 mt-m-2 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <button onClick={saveAudio} className="btnset m-auto">
                    <span>सेव करें  </span>{" "}
                  </button>
                </div>
              </div>
              <div
                className="col-lg-4 mt-m-1 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    draggable="false"
                    width="90%"
                    src="assets/img/dekho/cg-main-img.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo05Activity05Screen;
