import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { HOMEROUTE } from "../Constants";

const HomeMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToFaq = () => {
    const pathdata = location.pathname;

    localStorage.setItem("previous_link", JSON.stringify(pathdata));
    navigate("/faq2");
  };

  return (
    <>
      <div className="fixed-header">
        <div className="container-fluid cf-set">
          <div className="row align-items-center justify-content-between pt-20">
            <div className="col col-lg-4 d-flex">
              <div className="d-flex my-profile">
                <NavLink
                  to="/board-screen"
                  className="width-auto"
                  title="Main Page"
                >
                  <img
                    className="home-icon"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
            <div className="col col-lg-6">
              <ul className="list-navi float-right">
                <li>
                  <NavLink  onClick={goToFaq} to="/faq2" title="Faq" className="help">
                    <img src="assets/img/icon/ques.svg" />
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="#" title="Book">
                    <img src="assets/img/icon/book.svg" />
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" title="Settings">
                    <img src="assets/img/icon/settings.svg" />
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeMenu;
