import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";

const Khelo01Activity08_1Screen = () => {

    const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [totalStar,setTotalStar] = useState();

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(()=>{

    var data = JSON.stringify({
      "user_id": user_id,

      "ques_id": "3"
    });

    var config = {
      method: 'post',
      url: BaseURL+'/getKheloActivity01ByUser',
      headers: {
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {



        if(response.data.success === true)
        {

            setTotalStar(response.data.data.user_star);
        }
    //   console.log(JSON.stringify(response.data.data.totalstar));
    })
    .catch(function (error) {
      console.log(error);
    });

  },[]);





  const ShowMsg = ()=>{
      if(totalStar > 0)
      {
        return (
          <>
                <img className="mt-m-2" style={{"margin-top": "100px", "margin-left": "70px","margin-bottom": "7px"}} src="/assets/img/khelo/sahi-jabab-heading.svg"></img>
               <div className="six-tare mt-m-2 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">



               <p class="mpara large-para fs-30 text-center"><b>बराबरी का साथ निभाएँ, महिलायें अब आगे आएँ</b> </p>

               <br></br>
               <p className="text-center text-success"><b> तीसरा  स्तर पूरा हुआ</b></p>
               <br></br>
               <p class="side-btn">तारे इकठ्ठे हुए</p>
                           <ul className="ul-r">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><h4 className="zero-position-success" >{totalStar}</h4></li>
                           </ul>
                       </div>
          </>
        )
      }else if(totalStar <= 0){
        return (
          <>
               {/* <h1 className="rh1 red">ग़लत जवाब</h1> */}
               <img className="mt-m-2" style={{"margin-top": "100px", "margin-left": "70px","margin-bottom": "7px"}} src="/assets/img/khelo/cross-galat-jabab.svg"></img>
               <div className="six-tare mt-m-2 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">

               <p className="text-center text-success"> सही जवाब है</p>
               <br></br>

               <p class="mpara large-para fs-30 text-center"><b>बराबरी का साथ निभाएँ, महिलायें अब आगे आएँ</b> </p>

               <p class="side-btn">तारे इकठ्ठे हुए</p>
                           <ul className="ul-r">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><h4 className="zero-position">{totalStar}</h4></li>
                           </ul>
                       </div>
          </>
        )
      }
  }

  return (
    <>
       <main>

        <section className="bg-flexible FFEBEB">
                   <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
                   <div class="data-adjust">
           <div className="container-fluid cf-set">





               <div className="row align-items-center justify-content-center ">
                   <div className="col-lg-4">


                        <ShowMsg/>


                       <div className="d-flex mt-30">
                           <div className="mr-20">
                               <div className="gkw-img d-flex" >
                                   <NavLink to="/khelo-01-activity-01-screen">
                                       <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                       <p className="side-btn">वापस जाएं</p>
                                   </NavLink>
                               </div>
                           </div>
                           <div >
                               <div className=" gkw-img d-flex " >
                                   <NavLink to="/khelo-01-activity-09-screen" className="btnset "><span>आगे बढ़ें </span> </NavLink>
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                       <center><img width="90%" src="assets/img/khelo/hamara-nana-img.svg" alt="" /></center>
                   </div>




               </div>
               </div>
           </div>
        </section>






   </main>




    </>
  );
};

export default Khelo01Activity08_1Screen;
