import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Samasya from "../common/Samasya";
import Activity5Menu from "../common/Activity5Menu";
// import BtnDisabled from "../common/BtnDisabled";

const Karo05Activity05Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-10");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [SmNo,setSmNo] = useState('03');
  const [selectedData, setSelectedData] = useState([]);
  const [checkedOption1, setCheckedOption1] = useState();
  const [checkedOption2, setCheckedOption2] = useState();
  const [checkedOption3, setCheckedOption3] = useState();
  const [checkBlankData, setCheckBlankData] = useState("blank");


  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKaroActivity05ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const selectedData = response.data.dat
          setCheckBlankData('filled');
          console.log(selectedData);

          // setChecked("");
          if (selectedData === "option1") {
            setCheckedOption1("checked");
            setCheckedOption2('');
            setCheckedOption3('');
            return false;
          }

          if (selectedData === "option2") {
            // console.log('heo')
            setCheckedOption2("checked");
            setCheckedOption1('');

          setCheckedOption3('');
            return false;
          }

          if (selectedData === "option3") {
            setCheckedOption3("checked");
            setCheckedOption2('');
            setCheckedOption1('');
            return false;
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const selectOption = (e) => {
    const ans = e.target.value;

    if(ans === 'option1')
        {
          setCheckedOption1('checked');
          setCheckedOption2('');
          setCheckedOption3('');

        }

        if(ans === 'option2')
        {
          setCheckedOption2('checked');
          setCheckedOption1('');

          setCheckedOption3('');

        }

        if(ans === 'option3')
        {
          setCheckedOption3('checked');
          setCheckedOption2('');
          setCheckedOption1('');



        }


    setCheckBlankData('filled');

    var data = JSON.stringify({
      user_id: user_id,
      ans: ans,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity05",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveFinalStar = ()=>{

var data = JSON.stringify({
  user_id: user_id,
  "total_stars": "6",
  "activity_type": "karo",
  "activity_no": "5",
  "user_star": "6"
});

var config = {
  method: 'post',
  url: BaseURL+'/addFinalStars',
  headers: {
    'Content-Type': 'application/json',
    'type': `${userType} `,
  },
  data : data
};

axios(config)
.then(function (response) {
  if(response.data.success === true)
  {
    navigate('/karo-05-activity-06-screen');
  }
  // console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

  }

  const BtnDisabled = (props) => {
    if (props.checkBlankData !== "blank") {
      return (
        <>
          <div className={props.class1}>
            <div
              className={props.class2}
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <center>
                <button
                  className="theme_btn theme_btn_bg chnage-d purple-shadow m-auto"
                  onClick={saveFinalStar}
                >
                  आगे बढ़ेंं{" "}
                </button>
              </center>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
         <div className={props.class1}>
          <div
            className={props.class2}
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                  <span>आगे बढ़ेंं </span>{" "}
                </NavLink> */}

            <button className="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
          </div>
        </>
      );
    }
  };


  return (
    <>
      <main>
        <section className="bg-flexible blues">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} SmNo={SmNo} />
          <div class="data-adjust">

          <div className="container-fluid cf-set">




            <div className="row align-items-center justify-content-between">
              <div className="col-lg-12">
                <h1
                  className="h1 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  छेड़ना
                </h1>
              </div>
              <div
                className="col-lg-5 pr-50 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <div class="gif-box-set">
                  <center>
                    <img
                      width="80%"
                      src="assets/img/karo/gif/chodna.gif"
                      alt=""
                    />
                  </center>
                </div>
                <p className="mpara large-para fs-24 mt-40">
                  सीन: रीता के पिताजी का नए शहर में तबादला हुआ है और उसने नए
                  स्कूल में दाखिला लिया है। स्कूल में अभी उसके दोस्त नहीं
                  बने हैं और उसे भी सबको जानने में समय लग रहा है। राहुल सबसे
                  शैतान है और वह रीता को बहुत तंग कर रहा है, वह कभी रीता के बाल
                  छेड़ता है, कभी उसका बैग छुपा देता है। ऐसे में उसको क्या करना
                  चाहिए?
                </p>
              </div>

              <div className="col-lg-7">
                <div className="row ">
                  <div
                    className="col-lg-6 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <div className="activity-box invisible-checkboxes green-bg">
                      <input
                        type="radio"
                        name="rGroup"
                        value="option1"
                        id="t1"
                        checked={checkedOption1}
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias green-bg-s" for="t1">
                        <h4 className="slider-h burai-text">
                          {" "}
                          रीता को अपने पिताजी से जाकर शिकायत करनी चाहिए और उनको
                          स्कूल आकर टीचर से बात करने के लिए बोलना चाहिए।{" "}
                        </h4>
                      </label>
                    </div>

                    <div className="activity-box invisible-checkboxes red-bg mt-40">
                      <input
                        type="radio"
                        name="rGroup"
                        value="option2"
                        checked={checkedOption2}
                        id="b2"
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias red-bg-s" for="b2">
                        <h4 className="slider-h burai-text">
                          {" "}
                          रीता को राहुल, उसके साथियों और टीचर को अपनी परेशानी बतानी चाहिए
                        </h4>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-m-2">
                    <div
                      className="activity-box invisible-checkboxes green-bg blue-bg wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        value="option3"
                        id="b1"
                        checked={checkedOption3}
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias blue-bg-s" for="b1">
                        <h4 className="slider-h burai-text">
                          {" "}
                          रीता राहुल को ज़ोर से थप्पड़ मारती है/ या सभी के सामने उस पर चिल्लाती है और कहती है कि उसका मज़ाक उसे बिलकुल पसंद नहीं है
                        </h4>
                      </label>
                    </div>



                    <BtnDisabled
                    checkBlankData={checkBlankData}
                    toUrl="/karo-05-activity-06-screen"
                    class1="col-lg-12 mt-80"
                    class2="mt-40 mb-15 wow fadeInDown"
                  />

                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo05Activity05Screen;
