import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
// import HomeMenu from './common/HomeMenu';
import ProfileMenu from './common/ProfileMenu';

const FlashScreen = () => {
  const [lg,setLg] = useState('col-lg-8');
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-2");
  return (
    <>
         <main>
		
        <section className="bg-flexible">
           <ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn}/>
           <div class="data-adjust">
           <div className="container-fluid cf-set">
               <div className="row align-items-center justify-content-center ">
                  
                   <div className="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                       <h1 className="h1 mt-m-1 mb-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">चलिए शुरू करने से पहले एक छोटा सा खेल खेलते हैं!</h1>
                       <NavLink to="/general-screen"><img className="efffect-scale arrow-aage-img" alt="arrow" src="assets/img/icon/arrow-right.png" /></NavLink>
                   </div>

                

                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s">
                       <center><img draggable="false" className="mb-15" alt='khel' width="78%" src="assets/img/general/khel-khelte-han.svg" /></center>
                   </div>
               </div>
           </div>
           </div>
        </section>
   
       

   </main>
   

    </>
  )
}

export default FlashScreen