import React, { useState } from "react";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import FlashMessage from "../FlashMessage";
import { NavLink, useNavigate } from 'react-router-dom';
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordErr, setPasswordErr] = useState({});
  const [confirmPasswordErr, setConfirmPasswordErr] = useState({});
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  const [isVisible1, setVisible1] = useState(false);

  const toggle1 = () => {
    setVisible1(!isVisible1);
  };


  const changePassword = (e) => {
    e.preventDefault();

    const forgetdetails = JSON.parse(
        localStorage.getItem("forgot_password_details")
      );

      const  password_validate = (password) => {
        var re = {
            capital: /(?=.*[A-Z])/,
            length: /(?=.{7,20}$)/,
            specialChar: /[ -\/:-@\[-\`{-~]/,
            digit: /(?=.*[0-9])/,
        };
        return (
            re.capital.test(password) &&
            re.length.test(password) &&
            !re.specialChar.test(password) &&
            re.digit.test(password)
        );
    };
    
         var checkpas =  password_validate(password)
          if (!checkpas) {
   
            
            setMessage('कृपया पासवर्ड में एक संख्यात्मक संख्या, एक अपरकेस, एक लोअरकेस का उपयोग करें या विशेष वर्ण का उपयोग न करें, उदाहरण के लिए @!#$%^&*()" आदि। पासवर्ड 8 अक्षर के बराबर या उससे अधिक होना चाहिए।');
            setType("danger");

            return false;
          }

   
    var data = JSON.stringify({
      user_id: forgetdetails.id,
      password: password,
      password_confirmation: confirmPassword,
      

    });

    var config = {
      method: "post",
      url: BaseURL+"/resetPassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        
          if(response.data.success === true)
          {
            localStorage.removeItem("forgot_password_details")

            setMessage(response.data.message);
            setType("success");
            navigate('/login');
          }else{
            setMessage(response.data.message);
            setType("danger");
          }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   // form validation
  const formValidation = () => {
    const passwordErr = {};
    const confirmPasswordErr = {};

    const password1 = password === undefined ? "" : password;
    const confirmPassword1 =
      confirmPassword === undefined ? "" : confirmPassword;

    let isValid = true;

  

    if (password1.length < 9) {
      passwordErr.required = "पासवर्ड 8 अक्षर के बराबर या उससे अधिक होना चाहिए";
      isValid = false;
    }

  
    const  password_validate = (password) => {
      var re = {
          capital: /(?=.*[A-Z])/,
          length: /(?=.{7,20}$)/,
          specialChar: /[ -\/:-@\[-\`{-~]/,
          digit: /(?=.*[0-9])/,
      };
      return (
          re.capital.test(password) &&
          re.length.test(password) &&
          !re.specialChar.test(password) &&
          re.digit.test(password)
      );
  };
  
       var checkpas =  password_validate(password1)
        if (!checkpas) {
          isValid = false;
          passwordErr.required = "कृपया वैध पासवर्ड दर्ज़ करेंकम से कम एक संख्या, एक अपरकेस, एक लोअरकेस की अनुमति दें। पासवर्ड 8 अक्षर के बराबर या उससे अधिक होना चाहिए";
        }


    if (confirmPassword1.length === 0) {
      confirmPasswordErr.required = "पुष्टि करें कि पासवर्ड आवश्यक है";
      isValid = false;
    }

    if (password !== confirmPassword) {
      confirmPasswordErr.required = "पुष्टि करें कि पासवर्ड मेल नहीं खाता";
      isValid = false;
    }

    

    setPasswordErr(passwordErr);
    setConfirmPasswordErr(confirmPasswordErr);

    return isValid;
  };

  return (
    <>
      <form method="post" onSubmit={changePassword}>
        <main>
          <section class="bg-flexible">
      
          <HomeMenuOuter  />
          <div class="data-adjust">
            <div class="container-fluid cf-set">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-4">
                <FlashMessage type={type} message={message} />
                  <h3
                    class="main-headding wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    नया पासवर्ड बनाये
                  </h3>

                  <div
                    class="input-group wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <label class="levelset ">पासवर्ड</label>
                    <div class="input right">
                      <input
                       type={!isVisible ? "password" : "text"}
                        class="set-input"
                        placeholder="************"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />

                      <span
                              onClick={toggle}
                              style={{
                                "margin-right": "5px",
                                "margin-top": "10px",
                              }}
                            >
                              {isVisible ? (
                                <i className="fa fa-eye fa-2x text-black"></i>
                              ) : (
                                <i className="fa fa-eye-slash fa-2x text-black"></i>
                              )}
                            </span>

                    </div>

                    {Object.keys(passwordErr).map((key) => {
                      return <span className="error">{passwordErr[key]}</span>;
                    })}
                  </div>

                  <div
                    class="input-group mt-20 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <label class="levelset">स्थायी पासवर्ड</label>
                    <div class="input right pink-shodow-input">
                      <input
                         type={!isVisible1 ? "password" : "text"}
                        class="set-input"
                        placeholder="***********"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                      />

                          <span
                              onClick={toggle1}
                              style={{
                                "margin-right": "5px",
                                "margin-top": "10px",
                              }}
                            >
                              {isVisible ? (
                                <i className="fa fa-eye fa-2x text-black"></i>
                              ) : (
                                <i className="fa fa-eye-slash fa-2x text-black"></i>
                              )}
                            </span>

                    </div>

                    {Object.keys(confirmPasswordErr).map((key) => {
                      return (
                        <span className="error">{confirmPasswordErr[key]}</span>
                      );
                    })}
                  </div>

                  <div
                    class="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <button type="submit" className="btnset blue-shadow ">
                      <span>आगे बढ़ेंं </span>
                    </button>

                    <div
                    className="wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                    style={{"margin":"10px"}}
                  >
                    <NavLink to="/login" className="fp">
                    लॉगिन करें{" "}
                    </NavLink>
                   
                  </div>

                  </div>

                  
                </div>
               
                <div
                  class="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      class="mt-50"
                      width="95%"
                      src="assets/img/general/sign-up2.svg"
                    />
                  </center>
                </div>
              </div>
              </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};

export default ResetPassword;
