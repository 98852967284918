import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Karo06Activity03Screen = () => {
    const [lg, setLg] = useState("col-lg-7");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");
  return (
    <>
        <main>

        <section className="bg-flexible karo">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">

           <div className="container-fluid cf-set">




               <div className="row align-items-center justify-content-center">

                   <div className="col-lg-6">
                       <h1 className="h1 mb-20 mt-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">हर दोस्त ज़रूरी है</h1>
                       <p className="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       {/* वैसे तो हम अपने मित्रों से रोज़ ही  मिलते  हैं, स्कूल में, स्कूल के बाद खेलने के लिए और कभी कभी सुबह भी। परन्तु पिछले कुछ दिनों से जब से कोरोना आया है तबसे हमारा मिलना जुलना भी कम हो गया है, तो क्यों ना आज हम अपने दोस्त को खुश करें? */}
                       वैसे तो हम अपने दोस्तों से रोज़ ही मिलते हैं।  लेकिन आज हम उनकी कुछ  खूबियों के बारे में उन्हें लिखकर बताएँगे  तो क्यों न आज उन्हें एक पत्र  में लिखकर बताएँ

                       </p>
                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <NavLink className="theme_btn theme_btn_bg chnage-d" to="/kyakrna-06-activity-screen">आगे बढ़ें </NavLink>
                       </div>


                       <div className="gkw-img d-flex mt-40 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">

                           <div className="mr-20">
                               <NavLink to="/kyakrna-06-activity-screen">
                                   <center><img src="assets/img/icon/kya-karna-hai.svg" alt="" /></center>
                                   <p className="side-btn">क्या करना है?</p>
                               </NavLink>
                           </div>
                           <div className="">
                               <NavLink to="/karo-06-activity-01-screen">
                                   <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                   <p className="side-btn">वापस जाएं</p>
                               </NavLink>
                           </div>
                       </div>

                   </div>




                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s"s>

                       <center><img draggable="false"  width="100%" src="assets/img/karo/mera-priye-mitr.svg" /></center>
                   </div>
               </div>
           </div>
           </div>
        </section>



   </main>

    </>
  )
}

export default Karo06Activity03Screen