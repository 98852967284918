import axios from 'axios';
import React,{useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';
import BaseURL from '../common/Baseurl';



const Karo01SahiJawabScreen = () => {

    
    const [lg, setLg] = useState("col-lg-8");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center mt-80"
    );
    const [faqsize, setfaqsize] = useState('col-lg-4');
    const [user,setUser] = useState([]);
    const [item,setItem] = useState([]);

    const user_details = JSON.parse(localStorage.getItem('user_details'));
    

    useEffect(()=>{

       

        const data = JSON.stringify({
            "user_id": user_details.user_id
           });

        //    console.log(data);

           const config = {
           method: 'POST',
           url: BaseURL+'/karoActivity01Result',
           headers: { 
            'Authorization': `Bearer ${user_details.token} `, 
            'Content-Type': 'application/json'
          },
           data : data
         };

         axios(config).then((response)=>{

            setItem(response.data.data)
            console.log(response);

         }).catch((err)=>{
             console.log(err);
         })


    },[])


    useEffect(()=>{

    },)

  return (
    <>
        <main>
		
        <section className="fixed-bg blues">
           <div className="container-fluid cf-set">

           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />


               <div className="row align-items-top justify-content-center mt-40">
                   <div className="col-lg-4">
                       <div className="six-tare wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                           <h1 className="rh1 green"> {item.correctans} सही उत्तर दिए हैं   </h1>
                           <p className="text-center"> तारे इकट्ठे हुए </p>
                           <ul className="ul-r">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><h4>{item.totalstar}</h4></li>
                           </ul>
                       </div>
                    

                       <div className="d-flex mt-30">
                           <div className="mr-20">
                               <div className="gkw-img d-flex wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                                   <NavLink to="/karo-01-result">
                                       <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                       <p className="side-btn">वापस जाएं</p>
                                   </NavLink>
                                
                               </div>
                           </div>
                           <div>
                               <div className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                                   <NavLink to="/karo-01-galat-jawab" className="btnset "><span>आगे बढ़ें </span> </NavLink>
                               </div>
                           </div>
                       </div>

                      
                   </div>

                   <div className="col-lg-6 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                       <center><img draggable="false" className="mb-15" width="100%" src="assets/img/general/samaj-badana.svg" /></center>
                   </div>

                   

               </div>
           </div>
        </section>


      
   
       

   </main>
   
    </>
  )
}

export default Karo01SahiJawabScreen