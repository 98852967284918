import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FlashMessage from "../FlashMessage";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const Childpolicy = () => {
  const forgetdetails = JSON.parse(
    localStorage.getItem("forgot_password_details")
  );
  const navigate = useNavigate();
  const [inputValue, setIputValue] = useState();
  const [inputValueErr, setinputValueErr] = useState({});

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const auth = localStorage.getItem("user_details")

 
  

  //   console.log(forgetdetails.message);

  return (
    <>
   
        <main>
          <section className="bg-flexible ">
          {(auth)?<HomeMenu />:<HomeMenuOuter/>}
            
            <div class="data-adjust2">
              <div className="container-fluid cf-set">
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-12">
                    <div className="the-content mt-3">
                    <h4>गोपनीयता नीति (टीकेटी के लिए)</h4>
                    <p>
                      ब्रेकथ्रू ट्रस्ट के लिए बच्चों की गोपनीयता की रक्षा करना
                      महत्वपूर्ण है। हम माता-पिता और अभिभावकों को प्रोत्साहित
                      करते हैं कि वे बच्चों की ऑनलाइन गतिविधि का निरीक्षण करें,
                      उसमें भाग लें और/या उसकी निगरानी करें और मार्गदर्शन करें।
                      जब 18 वर्ष से कम आयु का कोई बच्चा या किशोर एक अकाउंट बनाने
                      के लिए पंजीकरण करना चाहता है, तो हम नाम, जन्म तिथि, जेन्डर
                      और माता-पिता का ईमेल एड्रैस और फोन नंबर मांगते हैं। यह
                      सुनिश्चित करने के लिए कि माता-पिता या कानूनी अभिभावक ने
                      हमारी वेबसाइट और मोबाइल एप्लिकेशन के बच्चे के उपयोग करने
                      और देखने के लिए सहमति दी है , और ऐसे माता-पिता/कानूनी
                      अभिभावक दिए गए मोबाइल नंबर/ईमेल पर एक ओटीपी भेजा जाएगा।
                    </p>
                    <p>
                      केवल पंजीकरण पूरा करने के लिए ओटीपी देने और हमारी वेबसाइट
                      और मोबाइल एप्लिकेशन के नियमों और शर्तों से सहमत होने के
                      बाद ही बच्चे द्वारा वेबसाइट और मोबाइल एप्लिकेशन के उपयोग
                      और इसकी सामग्री तक पहुंचने के लिए माता-पिता/कानूनी अभिभावक
                      की सहमति मानी जाएगी। ब्रेकथ्रू बाल/किशोर उपयोगकर्ता को
                      सेवा प्रदान करने या माता-पिता की सहमति लेने के लिए आवश्यक
                      जानकारी से अधिक जानकारी नहीं मांगता है। अगर आपको लगता है
                      कि आपके बच्चे ने गलती से हमारी वेबसाइट और मोबाइल एप्लिकेशन
                      पर किसी भी प्रकार की जानकारी प्रदान की है, तो हम आपको
                      तुरंत हमसे संपर्क करने के लिए प्रोत्साहित करते हैं और हम
                      अपने रिकॉर्ड से ऐसी जानकारी को तुरंत हटाने की पूरी कोशिश
                      करेंगे।
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      
    </>
  );
};

export default Childpolicy;
