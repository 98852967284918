import React, { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Samasya from "../common/Samasya";
import Activity5Menu from "../common/Activity5Menu";
import BtnDisabled from "../common/BtnDisabled";


const Karo05Activity03Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-10");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [SmNo, setSmNo] = useState("01");
  const [checkBlankData, setCheckBlankData] = useState("blank");

  const [checkedOption1, setCheckedOption1] = useState();
  const [checkedOption2, setCheckedOption2] = useState();
  const [checkedOption3, setCheckedOption3] = useState();


  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKaroActivity05ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setCheckBlankData("filled");
          const selectedData = response.data.data.ans;
          console.log(selectedData);

          if (selectedData === "option1") {
            setCheckedOption1("checked");
            return false;
          }

          if (selectedData === "option2") {
            // console.log('heo')
            setCheckedOption2("checked");
            return false;
          }

          if (selectedData === "option3") {
            setCheckedOption3("checked");
            return false;
          }
        } else {
          setCheckedOption1("");
          setCheckedOption2("");
          setCheckedOption3("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const selectOption = (e) => {
    const ans = e.target.value;

    setCheckBlankData("filled");

    var data = JSON.stringify({
      user_id: user_id,
      ans: ans,
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity05",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {



        if(response.data.message === 'Could Not Create Or Update,Your Certificate has Been Created')
        {

          navigate("/karo-07-activity-05-screen");
          return false;
        }


        if(ans === 'option1')
        {
          setCheckedOption1('checked');
          setCheckedOption2('');
          setCheckedOption3('');

        }

        if(ans === 'option2')
        {
          setCheckedOption2('checked');
          setCheckedOption1('');

          setCheckedOption3('');

        }

        if(ans === 'option3')
        {
          setCheckedOption3('checked');
          setCheckedOption2('');
          setCheckedOption1('');



        }



        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <main>
        <section className="bg-flexible blues">
        <Name
              lg={lg}
              faqsize={faqsize}
              rowAllgn={rowAllgn}
              SmNo={SmNo}
            />
            <div class="data-adjust">

          <div className="container-fluid cf-set">


            <div className="row align-items-center justify-content-between">
              <div className="col-lg-12">
                <h1
                  className="h1 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  पीठ पीछे बुराई करना
                </h1>
              </div>
              <div
                className="col-lg-5 pr-50 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img
                  width="80%"
                  src="assets/img/karo/gif/peeth-peeche-burai.gif"
                  alt=""
                />
                <p className="mpara large-para fs-24 mt-40">
                  सीन : कक्षा के कुछ लड़के आकाश के कपड़ों का मज़ाक उड़ा रहे हैं,
                  जिसे आकाश सुन लेता है। उसी समूह में नवीन भी है जो आकाश का
                  हमेशा मज़ाक उड़ाता है। आकाश को बहुत गुस्सा आता है और बुरा महसूस
                  होता है। ऐसे में उसको क्या करना चाहिए?{" "}
                </p>
              </div>

              <div className="col-lg-7">
                <div className="row ">
                  <div
                    className="col-lg-6 mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="activity-box invisible-checkboxes green-bg">
                      <input
                        type="radio"
                        name="rGroup"
                        value="option1"
                        id="t1"
                        checked={checkedOption1}
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias green-bg-s" for="t1">
                        <h4 className="slider-h burai-text">
                          {" "}
                          आकाश समूह के पास जा कर ज़ोर से चिल्लाता है, नवीन को
                          थप्पड़ मार कर भाग जाता है और उम्मीद करता है कि ऐसा
                          दुबारा कभी न हो।
                        </h4>
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <div className="activity-box invisible-checkboxes green-bg blue-bg">
                      <input
                        type="radio"
                        name="rGroup"
                        value="option2"
                        id="b1"
                        checked={checkedOption2}
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias blue-bg-s" for="b1">
                        <h4 className="slider-h burai-text">
                          {" "}
                          आकाश समूह के पास जाता है और समूह को बताता है कि उनके
                          अभद्र बातों और व्यवहार से उस पर क्या असर पड़ता है और वह
                          स्वयं को हीन महसूस करता है।
                        </h4>
                      </label>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="activity-box invisible-checkboxes red-bg">
                      <input
                        type="radio"
                        name="rGroup"
                        value="option3"
                        id="b2"
                        checked={checkedOption3}
                        onClick={selectOption}
                      />
                      <label className="checkbox-alias red-bg-s" for="b2">
                        <h4 className="slider-h burai-text">
                          {" "}
                          आकाश टीचर को अपनी परेशानी बताता है और नवीन से बातचीत
                          करने के लिए मदद लेता है।
                        </h4>
                      </label>
                    </div>
                  </div>


                  <BtnDisabled
                    checkBlankData={checkBlankData}
                    toUrl="/karo-05-activity-04-screen"
                    class1="col-lg-6 mt-30"
                    class2="mt-15 mb-15 wow fadeInDown"
                  />
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo05Activity03Screen;
