import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import Name from "../common/Name";


const Suno02Activity01Screnn = () => {

  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");

  return (
    <>
      <main>
        <section className="bg-flexible jaja-baba-bg">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-20">
              <div className="col-lg-4">
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  सुनो{" "}
                </h1>
                <div
                  className="karo-activity-barabri wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <h4>कभी हाँ कभी ना</h4>
                  {/* assets/img/suno/suno-img.svg  */}
                  <img
                    className="mt-20"
                    width="65%"
                    src="assets/img/suno/suno-to-sahi.svg"
                    alt=""
                  />
                  <div className="">
                    <NavLink
                      to="/suno-02-activity-02-screen"
                      className="btnset radius-set m-auto"
                    >
                      <span>चुनें</span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="100%"
                    src="assets/img/suno/suno-img.svg"
                  />
                </center>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno02Activity01Screnn;
