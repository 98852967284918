import React, { useEffect, useState } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink } from "react-router-dom";

const Khelo06Activity06Screen = () => {

    const [checkQues1BlankData, setQues1CheckBlankData] = useState("blank");

    const [ques1Value, setQues1Value] = useState(60);

    const [larkaQues1Active, setLarkaQues1Active] = useState('');
    const [larkiQues1Active, setLarkiQues1Active] = useState('');
    const [donoQues1Active, setDonoQues1Active] = useState('');



    const user_details = JSON.parse(localStorage.getItem("user_details"));
    const groupId = localStorage.getItem('groupId');

    let userType = 'user';
    let user_id = user_details.user_id;
    if(user_details.role_id == 16)
    {

      userType = 'group';
      user_id =groupId;
    }


    const quest7 = (e) => {
      const val1 = e.target.value;
      setQues1CheckBlankData("filled");

      var quesVal1 = "larki";

      if (val1 === "30") {
        quesVal1 = "larka";

        setLarkiQues1Active('')
        setLarkaQues1Active('active');
        setDonoQues1Active('');

      }

      if (val1 === "60") {
        quesVal1 = "larki";
        setLarkiQues1Active('active')
        setLarkaQues1Active('');
        setDonoQues1Active('');
      }

      if (val1 === "90") {
        quesVal1 = "dono";
        setLarkiQues1Active('')
        setLarkaQues1Active('');
        setDonoQues1Active('active');
      }

      var data = JSON.stringify({
        user_id: user_id,
        ans: quesVal1,
        ques_id: "7",
      });

      var config = {
        method: "post",
        url: BaseURL + "/kheloActivity06",
        headers: {
          "type":`${userType}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    };



    useEffect(() => {

      var data = JSON.stringify({
        user_id: user_id,
        ques_id: "7",
      });

      var config = {
        method: "post",
        url: BaseURL+"/getKheloActivity06ByUser",
        headers: {
          "type":`${userType}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if(response.data.success === true)
          {
            setQues1CheckBlankData("filled");

            const result = response.data.data.ans;
            if(result === 'larka')
            {
              setQues1Value(30)
              setLarkiQues1Active('')
              setLarkaQues1Active('active');
            setDonoQues1Active('');
            }

            if(result === 'larki')
            {
              setQues1Value(60)
              setLarkiQues1Active('active')
              setLarkaQues1Active('');
              setDonoQues1Active('');
            }

            if(result === 'dono')
            {
              setQues1Value(90)
              setLarkiQues1Active('')
              setLarkaQues1Active('');
              setDonoQues1Active('active');
            }



          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);




    const BtnDisabled = () => {
      if (checkQues1BlankData !== "blank") {
        return (
          <>
            <div
              className="col-lg-2 offset-lg-1"

            >
              <div
                className="mt-15 mb-15 mt-m-2"

              >
                <NavLink to="/khelo-06-activity-07-screen" className="btnset ">
                  आगे बढ़ेंं
                </NavLink>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              className="col-lg-2 offset-lg-1"

            >
              <div
                className="mt-15 mb-15 mt-m-2"

              >
                <button class="btnset m-auto disabled-btn" type="button" disabled>
                  आगे बढ़ेंं
                </button>
              </div>
            </div>
          </>
        );
      }
    };

  return (
    <>
      <main>
        <section className="bg-flexible F8F1FD">
          <div className="fixed-header">
          <Name/>
            <div className="container-fluid cf-set">
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <div
                    className=" mt-m-2 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <span
                      className="theme_btn theme_btn_bg chnage-d step-btn m-auto"
                    >
                      सवाल <span className="span-c-change">07</span>
                    </span>
                  </div>
                  <span className="note">टिप्पणी:नीचे दिए पीला बार पर क्लिक करें और अपना उत्तर चुनें</span>
                  <p
                    className="mpara mt-15 mt-m-1 large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    {" "}
                    अपने ऊपर पूर्ण विश्वास रखना ताकि हम अपने सपने पूरे कर सकें{" "}
                  </p>
                  <div className="img_boxes2">
                    <span className={(larkaQues1Active === 'active')?larkaQues1Active:""} data={30}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img.svg" alt="" />
                      </center>
                    </span>
                    <span className={(larkiQues1Active === 'active')?larkiQues1Active:""} data={60}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img2.svg" alt="" />
                      </center>
                    </span>
                    <span className={(donoQues1Active === 'active')?donoQues1Active:""} data={90}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img3.svg" alt="" />
                      </center>
                    </span>
                  </div>
                  <div className="mul-asul-slider">
                    <div className="slidecontainer">
                      <input
                        type="range"
                        min={30}
                        max={90}
                        defaultValue={ques1Value}
                        step={30}
                        className="murange"
                        id="myRange2"
                        onChange={quest7}
                      />
                    </div>
                  </div>
                </div>
               <BtnDisabled/>
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mt-50"
                      width="85%"
                      src="assets/img/khelo/mul-usul-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo06Activity06Screen;
