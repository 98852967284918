import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo02Activity01Screen = () => {
    const [lg,setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
        "row align-items-center justify-content-center"
      );
      const [faqsize, setfaqsize] = useState('col-lg-3');

  return (
    <>
    <main>
 		<section className="bg-flexible karo">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />

            

        <div class="data-adjust">
            <div className="container-fluid cf-set">
                <div className="row align-items-center justify-content-center mt-40">
                    <div className="col-lg-4">
                        <h1 className="h1 mb-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">करो</h1>
                        
                        <div className="karo-activity-barabri wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                            <h4>मैं भी सुन्दर हूँ </h4>
                            <img className="mt-30" width="90%" src="assets/img/general/mbsh-chune.svg" alt="" />
                            <div className="mt-30">
                                <NavLink to="/karo-02-activity-03-screen" className="btnset radius-set m-auto"><span>चुनें</span> </NavLink>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                        <center><img draggable="false" className="mt-m-30" width="95%" src="assets/img/on-boarding/pinky-and-punkaj.svg" /></center>
                    </div>
                </div>
            </div>
            </div>
         </section>
    </main>
	
    </>
  )
}

export default Karo02Activity01Screen