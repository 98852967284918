import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Name from "../common/Name";

const Khelo04Activity06Screen = () => {

    const [user_star, setUser_star] = useState();

  const user_details = JSON.parse(localStorage.getItem("user_details"));

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity04ByUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setUser_star(response.data.data.user_star);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const Result = () => {

    if (user_star === 1) {
      // alert();
      return (
        <>
          <div
            className="six-tare  wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1 vw-2-5 green">
              शाबाश! सही जवाब है <br />
              बाईचुंग भूटिया!!
            </h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{user_star}</h4>
              </li>
            </ul>
          </div>
        </>
      );
    } else if (user_star === 0){
      return (
        <>
          <div
            className="six-tare  wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1 vw-2-5 red">
              गलत जवाब! <br /> सही जवाब है बाईचुंग भूटिया!!
            </h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{user_star}</h4>
              </li>
            </ul>
          </div>
        </>
      );
    }
  };


  return (
    <>
     <main className=" F8F1FD ">
        <section className="bg-flexible karo overflowsetbody F8F1FD height-auto">
        <Name/>
          <div className="container-fluid cf-set">

            <div className="row  mt-30">
              <div className="col-lg-5">
              <Result/>

                <h1
                  className="mt-40 red wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  आपकी जानकारी के लिए -
                </h1>{" "}
                <br />
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img
                            src="assets/img/khelo/raster-img/bajran-puniya.jpg"
                            alt=""
                          />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>बजरंग पुनिया</h3>
                        <p>
                        बजरंग पुनिया एक भारतीय फ्रीस्टाइल पहलवान हैं, बजरंग पुनिया विश्व कुश्ती चैंपियनशिप में 4 पदक जीतने वाले एकमात्र भारतीय पहलवान हैं ।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img
                            src="assets/img/khelo/raster-img/bhuteeya.jpg"
                            alt=""
                          />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>बाईचुंग भूटिया</h3>
                        <p>
                        बाइचुंग भूटिया: बाईचुंग भूटिया फुटबॉल खिलाड़ी हैं जो स्ट्राइकर (striker) के रूप में खेलते थे। भूटिया को अंतरराष्ट्रीय स्तर पर भारतीय फुटबॉल का मशालदार माना जाता है। उन्हें 1999 में ‘अर्जुन पुरस्कार’ से सम्मानित सम्मानित किया गया है |
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img src="assets/img/khelo/raster-img/milkha-singh.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>मिल्खा सिंह </h3>
                        <p>
                        मिल्खा सिंह एक धावक थे | मेलबर्न 1956 ओलंपिक हो या रोम 1960 ओलंपिक या फिर टोक्यो 1964 का ओलंपिक, मिल्खा सिंह ने हमेशा भारत का प्रतिनिधित्व किया। वे भारत के महानतम एथलीटों में से एक रहे हैं |
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img src="assets/img/khelo/raster-img/denvener.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>देवेन्द्र झाझड़िया</h3>
                        <p>
                        देवेन्द्र झाझड़िया एक भारतीय पैरालंपिक खिलाड़ी है। ये पैरालंपिक में दो स्वर्ण पदक जीतने वाले पहले भारतीय पैरालिंपियन है। 2004 पैरालंपिक एथेंस में उन्होंने पहला स्वर्ण पदक जीता था |
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div
                      className="mt-30  wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <NavLink
                        className="theme_btn theme_btn_bg chnage-d"
                        to="/khelo-04-activity-07-screen"
                      >
                        अगला सवाल
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo04Activity06Screen;
