import React from 'react'
import { NavLink } from 'react-router-dom'

const Kyakrna06ActivityScreen = () => {
    return (
        <>


            <main>

                <section className="bg-boarding bg-flexible pt-m-2 karo">
                    <div className="container-fluid cf-set">


                        <div className="row align-items-center justify-content-center ">
                            <div className="col col-lg-7">
                                <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />क्या करना है ?</h2>
                            </div>
                            <div className="col col-lg-5 ">
                                <NavLink className="float-right width-auto" to="/karo-07-activity-03-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                            </div>
                        </div>


                        <div className="row align-items-center justify-content-center mt-50">
                            

                            <div className="col-lg-7 ">
                            <h3>आज हम अपना दस्तावेज तैयार करेंगे, इस दस्तावेज का नाम है मेरी पहचान प्रमाण पत्र है</h3>
                                <div className="certificate">
                                    <a href="">
                                        {" "}
                                        <center>
                                            <img
                                                className="certificate-lgoo"
                                                src="assets/img/on-boarding/tkt-logo.png"
                                            />
                                        </center>
                                    </a>
                                    <h4>मेरी पहचान सर्टिफिकेट।</h4>
                                    <p>THIS CERTIFICATE IS AWARED TO</p>
                                    <h2>अन</h2>
                                </div>
                            </div>


                            <div className="col-lg-4 offset-lg-1">


                                <div className="six-tare wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                                    <p>इस एक्टिविटी को पूरा करने पर आपको 10 तारे मिलेंगे। </p>
                                    <ul className="ul-r align-left">
                                        <li><img src="assets/img/icon/star.svg" alt="" /></li>
                                        <li><img src="assets/img/icon/star.svg" alt="" /></li>
                                        <li><img src="assets/img/icon/star.svg" alt="" /></li>
                                        <li><h4>X 10</h4></li>
                                    </ul>
                                </div>


                                <div >
                               <div className="mt-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                                   <NavLink to="/karo-07-activity-04-screen" className="btnset "><span>आगे बढ़ें </span> </NavLink>
                               </div>
                           </div>

                            </div>
                        </div>
                    </div>
                </section>



            </main>



        </>
    )
}

export default Kyakrna06ActivityScreen