import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";

const Suno01Activity02Screen = () => {
    const navigate = useNavigate();
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-6");

  return (
    <>
      <main>
        <section className="bg-flexible jaja-baba-bg">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between mt-30">
              <div className="col-lg-6">
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  जाजा बाजा की कहानी{" "}
                </h1>
                <p
                  className="mpara large-para fs-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  क्या आपको कहानियाँ अच्छी लगती हैं? मुझे याद है बचपन मे मेरी
                  दादी मुझे बहुत सारी कहानियाँ सुनाया करती थी। तो आज हम आपको एक
                  कहानी सुनाएँगे, ध्यान से सुनिएगा। आप चाहे तो उसे बार-बार सुन
                  सकते हैं । ध्यान से कहानी को सुनेंं और फिर उत्तर दें
                </p>
                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink
                    to="/kyakrna-01-suno-activity-screen"
                    className="btnset "
                  >
                    <span>आगे बढ़ें </span>{" "}
                  </NavLink>
                </div>
                <div
                  className="gkw-img d-flex mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <div className="mr-20">
                   
                  </div>
                  <div className="mr-20">
                    <NavLink to="/kyakrna-01-suno-activity-screen">
                      <center>
                        <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                      </center>
                      <p className="side-btn">क्या करना है?</p>
                    </NavLink>
                  </div>
                  <div className="">
                    <NavLink to="/suno-01-activity-01-screen">
                      <center>
                        <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                      </center>
                      <p className="side-btn">वापस जाएं</p>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <center>
                  <img
                    draggable="false"
                    className="mt-0"
                    width="100%"
                    src="assets/img/suno/jaja-baja.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno01Activity02Screen;
