import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import Name from "../common/Name";
const Suno03Activity02Screen = () => {
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");

  return (
    <>
      <main>
        <section className="bg-flexible jaja-baba-bg">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-20">
                <div className="col-lg-6">
                  <h1
                    className="h1 mb-20 mt-m-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    सुनो तो सही सुनो{" "}
                  </h1>
                  <p
                    className="mpara large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    ऐसा आपको कई बार लगा होगा कि जो भी आपको कहा गया है वह आपने
                    बिल्कुल सही समझा है। परन्तु कई बार गलतफहमी हो जाती है। ऐसा
                    संभव है कि हमने पूरी बात ध्यान से न सुनी हो या अधूरी सुनी हो |
                    आइये, ध्यान से सुनने की अभ्यास  करें।
                  </p>
                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink
                      to="/kyakrna-03-suno-activity-screen"
                      className="btnset "
                    >
                      <span>आगे बढ़ें </span>{" "}
                    </NavLink>
                  </div>
                  <div
                    className="gkw-img d-flex mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    {/* <div className="mr-20">
                    <a href="stss-gyaan-box.html">
                      <center>
                        <img src="assets/img/icon/gyan-box.svg" alt="" />
                      </center>
                      <p className="side-btn">ज्ञान बॉक्स</p>
                    </a>
                  </div> */}
                    <div className="mr-20">
                      <NavLink to="/kyakrna-03-suno-activity-screen">
                        <center>
                          <img src="assets/img/icon/kya-karna-hai.svg" alt="" />
                        </center>
                        <p className="side-btn">क्या करना है?</p>
                      </NavLink>
                    </div>
                    <div className="">
                      <NavLink to="/suno-03-activity-01-screen">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">वापस जाएं</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 mt-m-1 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="100%"
                      src="assets/img/suno/suno-img-2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno03Activity02Screen;
