import React,{useEffect, useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Name from '../common/Name';
import axios from 'axios';
import BaseURL from '../common/Baseurl';

const Karo03Activity08Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
    const [beforCleanImage,setBeforeCleanImage] = useState()
    const [afterCleanImage,setAfterCleanImage] = useState()


    const user_details = JSON.parse(localStorage.getItem("user_details"));
    const groupId = localStorage.getItem('groupId');

    let userType = 'user';
    let user_id = user_details.user_id;
    if(user_details.role_id == 16)
    {

      userType = 'group';
      user_id =groupId;
    }

    useEffect(() => {

        const data = JSON.stringify({
          user_id: user_id,
          ques_id: "1",
        });

        const config = {
          method: "post",
          url: BaseURL + "/getKaroActivity03ByUser",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            'type': `${userType} `,
            'Content-Type': 'application/json'
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            if(response.data.success === true)
            {
                setBeforeCleanImage(response.data.data.ans);
            }
          })
          .catch(function (error) {
            console.log(error);
          });


          /* after clean image */

          const data1 = JSON.stringify({
            user_id: user_id,
            ques_id: "2",
          });

          const config1 = {
            method: "post",
            url: BaseURL + "/getKaroActivity03ByUser",
            headers: {
              'Authorization': `Bearer ${user_details.token} `,
              'type': `${userType} `,
              'Content-Type': 'application/json'
            },
            data: data1,
          };

          axios(config1)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
              if(response.data.success === true)
              {
                  setAfterCleanImage(response.data.data.ans);
              }
            })
            .catch(function (error) {
              console.log(error);
            });

      }, []);

      const saveFinalStar = ()=>{

        var data = JSON.stringify({
          user_id: user_id,
          "total_stars": "10",
          "activity_type": "karo",
          "activity_no": "3",
          "user_star": "10"
        });

        var config = {
          method: 'post',
          url: BaseURL+'/addFinalStars',
          headers: {
            'Content-Type': 'application/json',
            'type': `${userType} `,
          },
          data : data
        };

        axios(config)
        .then(function (response) {
          if(response.data.success === true)
          {
            navigate('/karo-03-activity-09-screen');
          }
          // console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });

      }

  return (
    <>
        <main>

        <section className="bg-flexible gerua">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">
           <div className="container-fluid cf-set">


               <div className="row align-items-center justify-content-between mt-80">
                   <div className="col-lg-4">
                       <h1 className="h1 mb-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">एक्टिविटी समाप्त </h1>
                       <p className="mpara large-para fs-30 mt-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">अब दोनों फोटो को अच्छे से देख कर उनकी तुलना करें
                       </p>

                       <div className="mt-15 mb-15 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           <button className="theme_btn theme_btn_bg chnage-d" onClick={saveFinalStar} >आगे बढ़ेंं  </button>
                       </div>
                   </div>

                   <div className="col-lg-4 text-center wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <div className="d-block"><img width="100%" src={beforCleanImage} alt="" /></div>
                       <p className="parasetnew text-center">पहले </p>
                   </div>

                   <div className="col-lg-4 text-center wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                       <div className="d-block"><img width="100%" src={afterCleanImage} alt="" /></div>
                       <p className="parasetnew text-center">बाद में </p>
                   </div>





               </div>
               </div>
           </div>
        </section>






   </main>

    </>
  )
}

export default Karo03Activity08Screen