import React from 'react';
import { NavLink,useNavigate } from "react-router-dom";

const Sidebar = () => {

  const navigate  = useNavigate();

    const openNav = ()=>{
        document.getElementById("mySidenav").style.width = "22vw";
      }
    
      const closeNav = ()=>{
        document.getElementById("mySidenav").style.width = "0vw";
      }

      const signOut = ()=>{
        window.localStorage.clear();
        navigate('/')
      }

      
  return (
    <>
         <div id="mySidenav" className="sidenav">
          <div className="menu-padding">
            <NavLink
              to="#"
              className="closebtn"
              onClick={closeNav}
            >
              ×
            </NavLink>
            <h5 className="text-white">सेटिंग्स </h5>
            <ul className="menu-ul">
              <li>
                <NavLink to="/account-list">अकाउंट </NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">प्राइवेसी एंड सिक्योरिटी</NavLink>
                <span></span>
              </li>
              <li>
                <NavLink to="/terms-and-conditions">टर्म्स एंड कंडीशंस</NavLink>
              </li>
              <li>
                <NavLink to="#">TKT के बारे में </NavLink>
              </li>
           
            
              <li>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <a href="">लैंग्वेज </a>
                  </div>
                  <div>
                    <select name="cars" className="language-set">
                      <option value="हिंदी">हिंदी</option>
                      {/* <option value="अंग्रेजी">अंग्रेजी</option> */}
                    </select>
                  </div>
                </div>
              </li>
            
              <li>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <NavLink to="/child-policy">चाइल्ड पालिसी</NavLink>
                  </div>
                  {/* <div>
                    <NavLink to="#" className="share-btn">
                      चेक करे
                    </NavLink>
                  </div> */}
                </div>
              </li>

              <li>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <a href="#" onClick={signOut}>साइन आउट</a>
                  </div>
                 
                </div>
              </li>

            </ul>
          </div>
        </div>
    </>
  )
}

export default Sidebar