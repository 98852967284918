import React from 'react'
import { NavLink } from 'react-router-dom'

const Gyanbox05ActivityScreen = () => {
  return (
    <>
         <main>
		
        <section class="fixed-bg blues">
           <div class="container-fluid cf-set">


               <div class="row align-items-center justify-content-center mt-100">
                   <div class="col-lg-6 ">
                       <h2 class="h2 kkh-text"> <img class="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />ज्ञान बॉक्स</h2>
                   </div>
                   <div class="col-lg-5">
                       <NavLink class="float-right width-auto" to="/karo-05-activity-06-screen"> <img class="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div>
               </div>

               <div class="row align-items-top justify-content-center mt-40">
                   <div class="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <p class="mpara large-para fs-30 mt-40">हमारी भाषा, व्यवहार, और हमारे काम हमारे दोस्तों, सहपाठियों, और बहन-भाइयों पर असर डालते हैं।</p>
                       <p class="mpara large-para fs-30 mt-0">यदि हम नकारात्मक सोच और बुरा व्यवहार रखें तो यह उनकी भावनाओं को ठेस पहुँचा सकता है और निराशाजनक भी हो सकता है। </p>
                       <p class="mpara large-para fs-30 mt-0">तो हमें ख़ास ख्याल रखना है कि हम अपनी सोच और दूसरों के प्रति अपना व्यवहार सकारात्मक रखें और किसी भी तरह उन्हें नीचा दिखाने की कोशिश ना करें।  </p>
                       <NavLink
                        to="/karo-06-activity-01-screen"
                        className="btnset "
                      >
                        <span>अगली एक्टिविटी </span>{" "}
                      </NavLink>
                   </div>
                   <div class="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <NavLink to="mpm-activity-completion.html" class="width-p"><center><img draggable="false" class="mb-15" width="100%" src="assets/img/karo/ghunsa.svg" /></center></NavLink>
                   </div>
               </div>
           </div>
        </section>
   
       

   </main>
   
    </>
  )
}

export default Gyanbox05ActivityScreen