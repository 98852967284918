import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import reactImageSize from 'react-image-size';

const Karo03Activity05Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [images, setImages] = useState([]);
  const [imageUrls, setimageUrls] = useState([]);
//   const [quesId, setQuesId] = useState();
//   const [userDetails, setuserDetails] = useState([]);
  const [exitImage,setExistImage] = useState();
  const [checkBlankData,setCheckBlankData] = useState("blank");



  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {

    // setuserDetails(user_details);

    if (images.length < 1) return;

    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setimageUrls(newImageUrls);
  }, [images]);

  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "1",
    });

    const config = {
      method: "post",
      url: BaseURL + "/getKaroActivity03ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if(response.data.success === true)
        {
          setCheckBlankData("filled");
            setExistImage(response.data.data.ans);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onImageChange = async(e) => {

    setCheckBlankData("filled");

    setImages([...e.target.files]);



    const questId = e.target.attributes.getNamedItem("data-questId").value;







    var myHeaders = new Headers();
    myHeaders.append("type", userType);

    const files = e.target.files;
    const formData = new FormData();


    formData.append("ans", files[0]);
    formData.append("user_id", user_id);
    formData.append("ques_id", questId);

    // console.log(user_details.user_id);

    fetch(BaseURL + "/karoActivity03", {
      method: "POST",
      headers: myHeaders,
      body: formData,
    }).then((res) => {

      res.json().then((result) => {


      if(result.data.message === 'Could Not Create Or Update,Your Certificate has Been Created')
      {

        navigate("/karo-07-activity-05-screen");
        return false;
      }


        setCheckBlankData("filled");
        console.log(result);
      });
    });

    // console.log(questId);
  };

  // console.log(checkBlankData);

  const BtnDisabled = ()=>{

    if(checkBlankData !== 'blank') {
      return(
        <>
              <div
            className="mt-15 mb-15 "
          >


            {/* <NavLink  to="/karo-03-activity-04-screen" className="btnset">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink> */}


            <NavLink  to="/kyakrna-03_1-activity-screen" className="btnset">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink>





          </div>
        </>
      )
    }else{

      return(
        <>
              <div
             className="mt-15 mb-15 "
          >


            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink> */}

            <button  class="btnset disabled-btn" type="button" disabled>आगे बढ़ेंं</button>


          </div>
        </>
      )

    }
  }



  //   console.log(imageUrls[0]);
  return (
    <>
      <main>
        <section className="bg-flexible gerua">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s">
                <div
                  className="mt-40 "
                >
                  <NavLink
                    className="theme_btn theme_btn_bg chnage-d step-btn"
                    to="#"
                  >
                    स्टेप <span className="span-c-change">01</span>
                  </NavLink>
                </div>

                <p
                  className="mpara large-para fs-30 mt-20 "
                >
                  इस एक्टिविटी में आपको 2 बार फोटो अपलोड करनी है
                </p>
                <p
                  className="mpara large-para fs-30 mt-10 "
                >
                  पहले - चयन की गई जगह को साफ़ करने से पहले यहाँ पर उसकी फोटो
                  अपलोड करें{" "}
                </p>

                {/* <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <NavLink
                    className="theme_btn theme_btn_bg chnage-d"
                    to="/karo-03-activity-06-screen"
                  >
                    आगे बढ़ेंं{" "}
                  </NavLink>
                </div> */}

                <BtnDisabled/>

              </div>

              <div className="col-lg-4">
                <div
                  className="border-box camera-input mt-m-2 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                  >
                  <div className="content">
                    <div className="maincontent">
                      <div className="file-upload-wrapper">
                        <div className="box-fileupload">
                          <input
                            type="file"
                            id="fileId"
                            className="file-upload-input"
                            accept="image/*"
                            name="files"
                            // multiple
                            data-questId="1"
                            onChange={onImageChange}
                          />
                          <label for="fileId" className="file-upload-btn">
                            <center>
                              <img src="assets/img/karo/camera.svg" alt="" />
                            </center>
                          </label>

                          <p className="box-fileupload__lable">अपलोड </p>
                        </div>
                        <div className="error-wrapper"></div>
                        <div className="image-previwe">
                          {" "}
                          {

                              imageUrls.map((imageSrc) => (
                            <img
                              key={imageSrc.id}
                              src={imageSrc}
                              style={{ width: "200px", margin: "2px" }}
                            />
                          ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="shapes one-s"></span>
                  <span className="shapes sec-s"></span>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo03Activity05Screen;
