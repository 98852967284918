import React, { useEffect, useState } from "react";
import { NavLink,useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import swal from "sweetalert";
const Faq2 = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [searchInput, setSearchInput] = useState();
  const [showMessage, setShowMessage] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState();
  const [items, setItems] = useState([]);
  const [checkBlankData, setCheckBlankData] = useState("blank");
  // console.log(JSON.parse(localStorage.getItem('previous_link')));
  const previous_link = JSON.parse(localStorage.getItem('previous_link'));
  const regex = /(<([^>]+)>)/ig;
  const searchFaq = (e) => {
    e.preventDefault();
    // console.log(searchInput);
    if(searchInput === undefined)
    {
      swal("Please Enter Something!", "", "warning");
      return false;
    }
    const config = {
      method: "get",
      url: BaseURL + "/faq?search=" + searchInput,
      headers: {},
    };
    setItems([]);
    setShowErrorMessage('');
    // console.log(BaseURL+"/faq?search="+searchInput);
    axios(config)
      .then(function (response) {
          if(response.data.success === true)
          {
              setItems(response.data.data);
              setSearchInput('');
              setCheckBlankData('filled');
          }else{
            setCheckBlankData('filled');
            setShowErrorMessage(response.data.message);
          }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const addFaq = (e) => {
    e.preventDefault();
    const user_details = JSON.parse(localStorage.getItem('user_details'));
    const user_id = (user_details != null)?user_details.user_id:"";
    const data = JSON.stringify({
      user_id: user_id,
      question: searchInput,
    });
    var config = {
      method: "post",
      url: BaseURL+"/addfaq",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    setItems([]);
    axios(config)
      .then(function (response) {
          if(response.data.success === true)
          {
              setShowMessage(response.data.message);
              setSearchInput('');
              setShowErrorMessage('');
              setCheckBlankData('blank');

              swal("आपकी क्वेरी दर्ज हो गई है..जल्द ही आपकी क्वेरी एफएक्यू लिस्ट में ऐड हो जाएगी।", "", "success");
              return false;

          }else{
            setCheckBlankData('filled');
            setShowMessage(response.data.message);
          }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const BtnFormDisabled = ()=>{
    if(checkBlankData !== 'blank') {
      return(
        <>
              <p className="large-para mt-30">
                    यदि आपको अभी भी अपना उत्तर नहीं मिला है, तो कृपया सबमिट बटन
                    पर क्लिक करें।
                  </p>
              <div className="d-flex mt-30">
              <div className="mr-20">
                               <div className="gkw-img d-flex " >
                                   <NavLink to={previous_link}>
                                       <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                       <p className="side-btn">वापस जाएं</p>
                                   </NavLink>
                               </div>
                           </div>
                <div>
                    <button className="btnset " onClick={addFaq}>
                      सबमिट करें
                    </button>
                    </div>
                  </div>
        </>
      )
    }else{
      return(
        <>
<div className="d-flex mt-30">
                            <div className="mr-20">
                                <div className="gkw-img d-flex " >
                                    <NavLink to={previous_link}>
                                        <center><img src="assets/img/icon/wapas-jayen.svg" alt="" /></center>
                                        <p className="side-btn">वापस जाएं</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div >
                                <div className="" >
                                {/* <button  class="btnset disabled-btn" type="button" disabled> सबमिट करें !</button> */}
                                </div>
                            </div>
                        </div>
        </>
      )
    }
  }

 

  return (
    <>
        <main>
          <section className="bg-boarding">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                  <div className="d-flex my-profile">
                    <h2 className="h2 kkh-text"> FAQs</h2>
                  </div>
                </div>
                <div className="col-lg-4">
                </div>
              </div>
              <div className="row align-items-center justify-content-center mt-30">
                <div
                  className="col-lg-8 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <div className="box-radius chat-type">
                  <span className="showSuccess">{showMessage}</span>
                  <span className="showError">{showErrorMessage}</span> 
                          <div className="content-area-chat" >
                    {items.map((item) => {
                       var ans = '';
                      if(item.answers !== null)
                      {
                         ans = item.answers.replace(regex,'');
                      }
                      return (
                        <>
                            <div className="chat-me " key={item.id}>
                              <div className="float-right">
                                <p className="">{item.question}</p>
                              </div>
                            </div>
                            <div className="chat-you">
                              <div className="">
                                <p>
                                  <span>A .</span>
                                  {
                                   
                                
                                    ans
                                    }
                                </p>
                              </div>
                            </div>
                        </>
                      );
                    })}
                          </div>
                  <form  onSubmit={searchFaq}>
                    <div className="chat-area d-flex">
                      <input
                        type="text"
                        name=""
                        className="chat-input"
                        id=""
                        placeholder="Type Anything........"
                        value={searchInput}
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                      />
                      {/* <NavLink to="" className="send-icon" onClick={searchFaq}>
                        <img src="assets/img/icon/send.svg" alt="" />
                      </NavLink> */}
                      <button className="send-icon" type="submit"><img src="assets/img/icon/send.svg" alt="" /><span><b>Send</b></span></button>
                    </div>
                    </form>
                  </div>
                 <BtnFormDisabled />
                </div>
              </div>
            </div>
          </section>
        </main>
    </>
  );
};
export default Faq2;
