import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Karo01ActivityScreen = () => {
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-20"
  );
  const [faqsize, setfaqsize] = useState('col-lg-3');

  return (
    <>
      <main>
        <section className="fixed-bg karo">
             <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
             <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center mt-30">
              <div className="col-lg-4">
                <h1
                  className="h1 mb-20  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                  style={{"marginTop":"20px"}}
                >
                  करो
                </h1>

                <div
                  className="karo-activity-barabri wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h4>आओ जानें  बराबरी होती क्या है ? </h4>
                  <img
                    className="mt-20"
                    width="80%"
                    src="assets/img/general/barabri-chune.svg"
                    alt=""
                  />
                  <div className="mt-20">
                    <NavLink
                      to="/karo-02-activity-screen"
                      className="btnset radius-set m-auto"
                    >
                      <span>चुनें</span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                        <center><img draggable="false" class="mt-30" width="100%" src="assets/img/general/samaj-badana.svg"  /></center>
                    </div>

            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo01ActivityScreen;
