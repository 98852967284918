import React,{useState} from 'react';
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo04Activity02Screen = () => {

    const [lg, setLg] = useState("col-lg-5");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-4");
  return (
    <>
        <main>
		
        <section className="bg-boarding karo">
           <div className="container-fluid cf-set">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
               <div className="row align-items-center justify-content-center mt-30">
                   <div className="col-lg-4">
                       <h1 className="h1 mb-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">करो</h1>
                       <div className="karo-activity-barabri wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                           <NavLink to="/karo-04-activity-03-screen">
                               <h4>शादी की जल्दी क्या है?</h4>
                               <img width="80%" src="assets/img/general/follower.svg" alt="" />
                           </NavLink>
                       </div>
                      
                   </div>
                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                       <center><img draggable="false" className="mt-40" width="95%" src="assets/img/general/shaki-ke-liye-jaldi-kya.svg" /></center>
                   </div>
               </div>
           </div>
        </section>
   
       

   </main>
   

    </>
  )
}

export default Karo04Activity02Screen