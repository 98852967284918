import React from 'react'
import { NavLink } from 'react-router-dom';

const GeneralScreenTimer = () => {

    const startimer = JSON.parse(localStorage.getItem("startimer"));
    return (
        <>
            <main>
                <section
                    className="bg-boarding star-stop-bg"
                   
                >
                    {/* <div id="bubbles">
                        <div className="bubble x1">
                            <img src="assets/img/icon/general/1.svg" alt="" />
                        </div>
                        <div className="bubble x2">
                            <img src="assets/img/icon/general/2.svg" alt="" />
                        </div>
                        <div className="bubble x3">
                            <img src="assets/img/icon/general/5.svg" alt="" />
                        </div>
                        <div className="bubble x4">
                            <img src="assets/img/icon/general/4.svg" alt="" />
                        </div>
                        <div className="bubble x5">
                            <img src="assets/img/icon/general/5.svg" alt="" />
                        </div>
                        <div className="bubble x6">
                            <img src="assets/img/icon/general/6.svg" alt="" />
                        </div>
                        <div className="bubble x7">
                            <img src="assets/img/icon/general/7.svg" alt="" />
                        </div>
                        <div className="bubble x8">
                            <img src="assets/img/icon/general/4.svg" alt="" />
                        </div>
                        <div className="bubble x9">
                            <img src="assets/img/icon/general/9.svg" alt="" />
                        </div>
                        <div className="bubble x10">
                            <img src="assets/img/icon/general/10.svg" alt="" />
                        </div>
                        <div className="bubble x11">
                            <img src="assets/img/icon/general/13.svg" alt="" />
                        </div>
                    </div>*/}

                    
                    <div className="container-fluid cf-set"> 
                        <div className="row align-items-bottom justify-content-between">
                            <div className="col-lg-8 offset-lg-1">
                                <h1 className="h1 text-left text-white">आपका समय समाप्त हुआ!</h1>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-3">
                                <div className="timer-large">
                                    <div className="imgbox">
                                        <img src="assets/img/general/time.svg" alt="" />
                                    </div>
                                    <p>{startimer}</p>
                                </div>
                                <div style={{ marginTop: "-2vw" }}>
                                    <div
                                        className="dispalyset f-left wow fadeInDown"
                                        data-wow-duration="1s"
                                        data-wow-delay="0.7s"
                                    >
                                        <NavLink to="/general-screen-result" className="btnset m-auto">
                                            <span>आगे बढ़ेंं</span>{" "}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-m-3 offset-lg-2">
                                <center>
                                    <img
                                        draggable="false"
                                        width="90%"
                                        src="assets/img/on-boarding/pinky-and-punkaj.svg"
                                    />
                                </center>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}

export default GeneralScreenTimer