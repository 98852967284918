import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";


const Karo07Activity01Screen = () => {
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-3");
  return (
    <>
        <main>
		
        <section className="bg-flexible karo">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">

           <div className="container-fluid cf-set">
               <div className="row align-items-center justify-content-center mt-10">
                  
                   <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <h1 className="h1 mb-20 mt-m-1">करो</h1>
                       <div className="karo-activity-barabri wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s" style={{"padding": "3vw 0vw 3vw 2vw"}}>
                           <h4>किस से है पहचान <br></br>मेरी</h4>
                           <img width="100%" src="assets/img/general/pehchn-meri.svg" alt="" />
                           <div className="mt-10">
                               <NavLink to="/karo-07-activity-03-screen" className="btnset radius-set m-auto" ><span>चुनें</span> </NavLink>
                           </div>
                       </div>
                   </div>
                   
                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <center><img className="mt-m-2" draggable="false"  width="100%" src="assets/img/karo/kisse-hai-meri-pehchan.svg" /></center>
                   </div>
               </div>


           </div>
           </div>
        </section>
   
       

   </main>

    </>
  )
}

export default Karo07Activity01Screen