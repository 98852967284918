import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";

const Suno01Activity01Screen = () => {

    const navigate = useNavigate();
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-3");

  return (
    <>
      <main>
        <section className="bg-flexible jaja-baba-bg">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-4">
                <h1 className="h1 mb-20 mt-m-1">सुनो </h1>
                <div
                  className="karo-activity-barabri wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h4>जाजा बाजा की कहानी </h4>
                  <img
                    className="mt-20"
                    width="100%"
                    src="assets/img/suno/jaja-baja.svg"
                    alt=""
                  />
                  <div className="mt-40">
                    <NavLink
                      to="/suno-01-activity-02-screen"
                      className="btnset radius-set m-auto"
                    >
                      <span>चुनें</span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <center>
                  <img
                    draggable="false"
                    className="mt-100"
                    width="100%"
                    src="assets/img/suno/jaja-baja.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno01Activity01Screen;
