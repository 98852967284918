import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";

const Karo03Activity04Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [todoItem, setTodo] = useState();

  const [items, setItems] = useState([]);


  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKaroActivity03TodoByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setItems(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const item_list = () => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKaroActivity03TodoByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        setItems(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addTodo = (e) => {


    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
      ans: todoItem,
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity03ToDo",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        if(response.data.message === 'Could Not Create Or Update,Your Certificate has Been Created')
        {

          navigate("/karo-07-activity-05-screen");
          return false;
        }
        if (response.data.success === true) {
          item_list();
          setTodo("");
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteTodo = (e) => {

    const todo_id = e.target.attributes.getNamedItem("data-id").value;
    var data = JSON.stringify({
      todo_id: todo_id,
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL+"/deleteKaroActivity03Todo",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        item_list();
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <main class="FFFAF0">
        <section className="bg-flexible overflowsetbody">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-top justify-content-center ">
              <div className="col-lg-5">
                <div
                  className="mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p
                    className="theme_btn theme_btn_bg chnage-d step-btn"

                  >
                    स्टेप <span className="span-c-change">02</span>
                  </p>
                </div>
                <p
                  className="mpara large-para fs-30 mt-20 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  प्लानिंग करने के दौरान एक लिस्ट तैयार करें जिसमें सारे सामान
                  की जानकारी हो।{" "}
                </p>

                <div
                  className="mt-15 mb-15 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <NavLink
                    className="theme_btn theme_btn_bg chnage-d"
                    to="/karo-03-activity-06-screen"
                  >
                    आगे बढ़ेंं{" "}
                  </NavLink>
                </div>
              </div>

              <div className="col-lg-4 offset-lg-1">
                <div className="bg-td">
                  <div className="arrendarea">
                    <h2 className="heading-td">काम की सूची</h2>
                    <ul className="list-td" id="myUL">
                      {items.map((item) => {
                        return (
                          <>
                            <li>
                              &nbsp;{item.ans} &nbsp;
                              <i
                                className="fa fa-close text-danger fa-1x"
                                data-id={item.id}
                                onClick={deleteTodo}

                              ></i>
                            </li>
                          </>
                        );
                      })}
                    </ul>

                    <div id="myDIV" className="header">
                      <input
                        type="text"
                        className="todo-input"
                        id="myInput"
                        placeholder="Add task"
                        value={todoItem}
                        onChange={(e) => {
                          setTodo(e.target.value);
                        }}
                      />
                      <div onClick={addTodo} className="addBtn">
                        <i className="fa fa-solid fa-plus"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo03Activity04Screen;
