import React,{useState,useEffect} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Karo04Activity08Screen = () => {

  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");


  const [checkBlankData,setCheckBlankData] = useState("blank");

  const [selectItems,setSelectItems] = useState([]);

//   const [baharchecked,setBaharCheckedItem] = useState();
  const[schoolchecked,setSchoolChecked] = useState();
  const[gameschecked,setGamesChecked] = useState();
  const[bajarchecked,setBajarCheckedItem] = useState();
  const [dostochecked ,setDostoCheckedItem] = useState();
  const [ganachecked ,setGanaCheckedItem] = useState();
  const [padhaichecked ,setPadhaiCheckedItem] = useState();
  const [matapitachecked ,setMataPitaCheckedItem] = useState();
  const [bhaibahanchecked ,setBhaiBahanCheckedItem] = useState();

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const selectActivity = [];
  const getSelect = (e) => {

    const activity = e.target.value;
    selectActivity.push(activity);
    setCheckBlankData('filled');

    var data = JSON.stringify({
      user_id: user_id,
      ans: selectActivity.join(),
      ques_id: "4",
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity04",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        if(response.data.message === 'Could Not Create Or Update,Your Certificate has Been Created')
        {

          navigate("/karo-07-activity-05-screen");
          return false;
        }

        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(selectActivity);
  };

  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "4",
    });

    const config = {
      method: "post",
      url: BaseURL + "/getKaroActivity04ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
            setCheckBlankData('filled');
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(",");
          // console.log(Ans_data);

          Ans_data.map((item) => {

            if(item === 'स्कूल जाना')
            {
                setSchoolChecked('checked');
            }
            if(item === 'गेम्स खेलना (मोबाइल में)')
            {
                setGamesChecked('checked');
            }

            if (item === "बाज़ार जाना") {
              setBajarCheckedItem("checked");
            }

            if(item === 'दोस्तों से मिलने जाना')
            {
              setDostoCheckedItem('checked')
            }

            if(item === 'गाना बजाना')
            {
              setGanaCheckedItem('checked') ;
            }


            if (item === "पढ़ाई करना") {
              setPadhaiCheckedItem("checked");
            }

            // if (item === "बाहर खेलना") {
            //     setBaharCheckedItem("checked");
            //   }




            if (item === "अपने माता पिता के साथ समय व्यतीत करना") {
              setMataPitaCheckedItem("checked");
            }

            if (item === "अपने भाई-बहन के साथ खेलना") {
              setBhaiBahanCheckedItem("checked");
            }




          });

          setSelectItems(Ans_data);

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  // useEffect(() => {}, []);



  const BtnDisabled = ()=>{

    if(checkBlankData !== 'blank') {
      return(
        <>
               <div className="mt-15 mb-15">
                  <center>
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d purple-shadow m-auto"
                      to="/karo-04-activity-05-screen"
                    >
                      आगे बढ़ेंं{" "}
                    </NavLink>
                  </center>
                </div>
        </>
      )
    }else{

      return(
        <>
              <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >


            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink> */}

            <button  class="btnset m-auto disabled-btn" type="button" disabled>आगे बढ़ेंं</button>


          </div>
        </>
      )

    }
  }



  return (
    <>
      <main>
        <section className="bg-flexible blues">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-between">
              <div className="col-lg-12">
                <h1
                  className="h1 mb-20 mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {" "}
                  आपकी रूचि सबसे ज़्यादा किसमें है या आपको सबसे ज़्यादा क्या
                  करना पसंद है?{" "}
                </h1>
              </div>
              <div className="col-lg-12">

              <OwlCarousel className='owl-theme' dot items={6} margin={8} autoplay={true}>

              <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/school-jana.gif"
                            alt=""
                          />
                        </center>
                      </div>
                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="स्कूल जाना"
                            id="skljk1"
                            onClick={getSelect}
                            checked={schoolchecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk1"
                          >
                            <h4 className="slider-h"> स्कूल जाना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/gem-khelna.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="गेम्स खेलना (मोबाइल में)"
                            id="skljk2"
                            onClick={getSelect}
                            checked={gameschecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk2"
                          >
                            <h4 className="slider-h">
                              {" "}
                              गेम्स खेलना (मोबाइल में)
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/gaana-bajaana.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="गाना बजाना"
                            id="skljk3"
                            onClick={getSelect}
                            checked={ganachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk3"
                          >
                            <h4 className="slider-h">गाना बजाना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/doston-se-milne-jana.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="दोस्तों से मिलने जाना"
                            id="skljk4"
                            onClick={getSelect}
                            checked={dostochecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk4"
                          >
                            <h4 className="slider-h">दोस्तों से मिलने जाना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/bazaar-jana.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="बाज़ार जाना"
                            id="skljk5"
                            onClick={getSelect}
                            checked={bajarchecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk5"
                          >
                            <h4 className="slider-h">बाज़ार जाना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/padhai-karna-book.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="पढ़ाई करना"
                            id="skljk6"
                            onClick={getSelect}
                            checked={padhaichecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk6"
                          >
                            <h4 className="slider-h">पढ़ाई करना</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/apne-sathi-samay.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="अपने माता पिता के साथ समय व्यतीत करना"
                            id="skljk7"
                            onClick={getSelect}
                            checked={matapitachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk7"
                          >
                            <h4 className="slider-h">
                              अपने माता पिता के साथ समय व्यतीत करना
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/apne-bhai-behen-ke-sath-khelne.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="अपने भाई-बहन के साथ खेलना"
                            id="skljk8"
                            onClick={getSelect}
                            checked={bhaibahanchecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk8"
                          >
                            <h4 className="slider-h">
                              अपने भाई-बहन के साथ खेलना
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </OwlCarousel>



               <BtnDisabled/>
              </div>
            </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo04Activity08Screen;
