import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Gyanbox06ActivityScreen = () => {
  const [lg, setLg] = useState("col-lg-6 d-flex");
  return (
    <>
      <main>
        <section class="bg-boarding jaja-baja EEECE2">
          <div class="container-fluid cf-set">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 ">
                <h2 class="h2 kkh-text">
                  {" "}
                  <img
                    class="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  ज्ञान बॉक्स
                </h2>
              </div>
              <div class="col-lg-5">
                <NavLink
                  class="float-right width-auto"
                  to="/karo-07-activity-06-screen"
                >
                  {" "}
                  <img
                    class="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div class="row align-items-center justify-content-center mt-50">
              <div class="col-lg-6">
                <p
                  class="mpara large-para fs-23 mt-20 text-leff wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  आमतौर पर हमें पिता के नाम से ही जाना जाता है, जबकि हमारी
                  परवरिश की बड़ी ज़िम्मेदारी तो माँ निभाती हैं। क्यों, हम महिला
                  के योगदान को महत्व नहीं देते? क्यों हम भूल जाते हैं कि हमारी
                  पहचान सिर्फ पुरुषों से नहीं बल्कि उतनी ही महिलाओं से है - शायद
                  ज़्यादा। इसलिए ज़रूरी है कि हम आज से इस पहचान सर्टिफिकेट को याद
                  रखें और अपनी माँ के नाम से भी जोड़ें। सभी परिवार एक जैसे नहीं
                  होते किसी में सिंगल या किसी में सेम जेन्डर के पेरेंट्स या
                  अभिभावक हो सकते हैं। हम में से कई लोग ‘पारंपरिक' परिवार में
                  नहीं रहते होंगे। हमे इस आधार पर किसी के साथ न्याय या भेदभाव
                  नहीं करना चाहिए कि वे किस तरह के परिवार से हैं। हर कोई सम्मान
                  का पात्र है!
                </p>

                <NavLink
                        to="/board-screen"
                        className="btnset "
                      >
                        <span>अगली एक्टिविटी </span>{" "}
                      </NavLink>

              </div>
              <div
                class="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                <NavLink to="/karo-07-activity-04-screen">
                  <center>
                    <img
                      draggable="false"
                      class="mb-15"
                      width="90%"
                      src="assets/img/karo/gyan-box-card.svg"
                    />
                  </center>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Gyanbox06ActivityScreen;
