import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL,{AdminUrl} from "../common/Baseurl";

const Khelo07Activity15Screen = () => {
  const [mithyaChecked, setmithyaChecked] = useState();
  const [satyaChecked, setsatyaChecked] = useState();
  const [checkBlankData, setBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const audio = new Audio(
    AdminUrl+"/correct.mp3"
  );
  const wrongAudio = new Audio(
    AdminUrl+"/wrong.mp3"
  );


  const selectAns = (e) => {
    const selectVal = e.target.value;
    setBlankData("filled");
    if (selectVal === "satya") {
      audio.play();

    }

    if (selectVal === "mithya") {
      wrongAudio.play();

    }

    var data = JSON.stringify({
      user_id: user_id,
      ans: selectVal,
      ques_id: "13",
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity07",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        checkValue(selectVal);

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const checkValue = (selectVal) => {
    if (selectVal === "satya") {

      setsatyaChecked("checked");
      setmithyaChecked("");
    }

    if (selectVal === "mithya") {

      setmithyaChecked("checked");
      setsatyaChecked("");
    }
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "13",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity07ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const selectVal = response.data.data.ans;
          checkValue(selectVal);
          setBlankData("filled");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div className="mt-40">
            <NavLink to="/khelo-07-activity-16-screen" className="btnset ">
              आगे बढ़ेंं
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="mt-40">
            <button class="btnset disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible FFF6E8">
          <div className="fixed-header">
              <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                  <div
                    className="mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <span className="theme_btn theme_btn_bg chnage-d step-btn">
                    स्टेटमेंट <span className="span-c-change">13</span>
                    </span>
                  </div>
                  <div
                    className="six-tare mt-30  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1 vw-2-5 mt-20">शिक्षा का अधिकार है</h1>
                  </div>

                  <div className="row mt-10 m-ml-10">
                    <div className="col-lg-6">
                      <div className="row mt-30 align-items-center justify-content-between">
                        <div
                          className="col-lg-6 wow fadeInDown"
                          data-wow-duration="1s"
                          data-wow-delay="0.1s"
                        >
                          <div className="activity-box invisible-checkboxes red-bg sh-sg">
                            <input
                              type="radio"
                              name="rGroup"
                              defaultValue="mithya"
                              id="b2"
                              onClick={selectAns}
                              checked={mithyaChecked}
                            />
                            <label
                              className="checkbox-alias red-bg-s"
                              htmlFor="b2"
                            >
                              <h4 className="slider-h burai-text"> मिथ्या</h4>
                            </label>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 wow fadeInDown"
                          data-wow-duration="1s"
                          data-wow-delay="0.1s"
                        >
                          <div className="activity-box invisible-checkboxes green-bg sh-sg">
                            <input
                              type="radio"
                              name="rGroup"
                              defaultValue="satya"
                              id="t1"
                              onClick={selectAns}
                              checked={satyaChecked}
                            />
                            <label
                              className="checkbox-alias green-bg-s"
                              htmlFor="t1"
                            >
                              <h4 className="slider-h burai-text">सत्य</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <BtnDisabled />
                </div>
                <div
                  className="col-lg-4 mt-m-2 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/sh-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo07Activity15Screen;
