import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import FlashMessage from "../FlashMessage";

const Karo03ActivityScreen = () => {

  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [option, setOption] = useState();
  const [question, setQuestion] = useState();
  //   const [user_id, setUserId] = useState();
  //for show message
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [checkBlankData, setCheckBlankData] = useState("blank");

  const [checkedOption1, setCheckedOption1] = useState();
  const [checkedOption2, setCheckedOption2] = useState();
  const [checkedOption3, setCheckedOption3] = useState();
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKaroActivity01ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setCheckBlankData("filled");
          const selectedData = response.data.data.ans;
          // console.log(selectedData);

          if (selectedData === "option1") {
            setCheckedOption1("checked");
            return false;
          }

          if (selectedData === "option2") {
            // console.log('heo')
            setCheckedOption2("checked");
            return false;
          }

          if (selectedData === "option3") {
            setCheckedOption3("checked");
            return false;
          }
        } else {
          setCheckedOption1("");
          setCheckedOption2("");
          setCheckedOption3("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const quest1 = (e) => {
    // console.log(e.target.attributes.getNamedItem("data-opton").value);
    const option = e.target.attributes.getNamedItem("data-opton").value;
    const questId = e.target.attributes.getNamedItem("data-questId").value;

    setOption(option);
    setQuestion(questId);
    setCheckedOption1("checked");
    setCheckedOption2("");
    setCheckedOption3("");

    setCheckBlankData("filled");
  };

  const quest2 = (e) => {
    const option = e.target.attributes.getNamedItem("data-opton").value;
    const questId = e.target.attributes.getNamedItem("data-questId").value;

    setOption(option);
    setQuestion(questId);
    setCheckedOption2("checked");
    setCheckedOption1("");
    setCheckedOption3("");
    setCheckBlankData("filled");
  };

  const quest3 = (e) => {
    const option = e.target.attributes.getNamedItem("data-opton").value;
    const questId = e.target.attributes.getNamedItem("data-questId").value;

    setOption(option);
    setQuestion(questId);
    setCheckedOption3("checked");
    setCheckedOption1("");
    setCheckedOption2("");
    setCheckBlankData("filled");
  };



  const data = JSON.stringify({
    user_id: user_id,
    answers: {
      ques1: {
        ans: option,
        ques_id: "1",
      },
      ques2: {
        ans: "",
        ques_id: "",
      },
      ques3: {
        ans: "",
        ques_id: "",
      },
    },
  });

  var config = {
    method: "post",
    url: BaseURL + "/karoActivity01_1",
    headers: {
      'Authorization': `Bearer ${user_details.token} `,
      'type': `${userType}`,
      'Content-Type': 'application/json'
    },
    data: data,
  };

  axios(config)
    .then((response) => {

      if(response.status === 'Token is Expired')
      {
        localStorage.removeItem('user_details')
        navigate("/welcome");
        return false;
      }
      if(response.data.message === 'Could Not Create Or Update,Your Certificate has Been Created')
        {

          navigate("/karo-07-activity-05-screen");
          return false;
        }

      if (response.data.status === true) {
        setCheckedOption1();
        setCheckedOption2();
        setCheckedOption3();

        setMessage(response.data.message);

        setType("success");
      } else if (response.data.status === false) {
        setMessage(response.data.message);

        setType("danger");
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  // console.log(checkBlankData);
  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >

            <NavLink to="/karo-04-activity-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>

          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
                <span>आगे बढ़ेंं </span>{" "}
              </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  // console.log(checkBlankData);

  return (
    <>
      <main>
        <section className="bg-flexible blues">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <FlashMessage type={type} message={message} />
              <div
                className="row align-items-center justify-content-between wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="col-lg-12">
                  <h2
                    className="h2 mb-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    दी गई तस्वीरों में बराबरी किन-किन में दिख रही है? जहां
                    बराबरी हो वहाँ टिक लगाओ।{" "}
                  </h2>
                </div>

                <div className="col-lg-4 mt-m-2">
                  <div className="invisible-checkboxes">
                    <input
                      type="radio"
                      checked={checkedOption1}
                      name="rGroup"
                      value="1"
                      id="r1"
                      data-opton="option1"
                      data-questId="1"
                      onClick={quest1}
                    />
                    <label className="checkbox-alias" for="r1">
                      <center>
                      <img   src="assets/general/Group2621.svg" alt="" />
                      </center>
                      &nbsp;&nbsp;
                      {/* <h4 className="slider-h"> असंतुलन</h4> */}
                    </label>
                  </div>
                </div>

                <div className="col-lg-4 mt-m-2">
                  <div className="invisible-checkboxes">
                    <input
                      type="radio"
                      name="rGroup"
                      value="1"
                      id="r2"
                      checked={checkedOption2}
                      data-opton="option2"
                      data-questId="1"
                      onClick={quest2}
                    />
                    <label className="checkbox-alias" for="r2">
                      <center>
                      <img   src="assets/general/Group2633.svg" alt="" />
                      </center>
                      &nbsp;&nbsp;
                      {/* <h4 className="slider-h"> संतुलन</h4> */}
                    </label>
                  </div>
                </div>

                <div className="col-lg-4 mt-m-2">
                  <div className="invisible-checkboxes">
                    <input
                      type="radio"
                      name="rGroup"
                      value="1"
                      id="r3"
                      data-opton="option3"
                      checked={checkedOption3}
                      data-questId="1"
                      onClick={quest3}
                    />
                    <label className="checkbox-alias" for="r3">
                      <center>
                      <img   src="assets/general/Group2634.svg" alt="" />
                      </center>
                      &nbsp;&nbsp;
                      {/* <h4 className="slider-h"> असंतुलन</h4> */}
                    </label>
                  </div>
                </div>
              </div>

              <BtnDisabled />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo03ActivityScreen;
