import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import Name from "../common/Name";

const Suno03Activity06Screen = () => {

    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-5");

  return (
    <>
      <main>
        <section className="bg-flexible D4F3FD">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-0">
              <div className="col-lg-4">
                <div
                  className="six-tare mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="rh1 red">गलत उत्तर है! </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>0</h4>
                    </li>
                  </ul>
                </div>
                <div className="gkw-img d-flex mt-30">
                  {/* <div
                    className="mr-20  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <a href="stss-gyaan-box.html">
                      <center>
                        <img src="assets/img/icon/gyan-box.svg" alt="" />
                      </center>
                      <p className="side-btn">ज्ञान बॉक्स</p>
                    </a>
                  </div> */}
                  <div className="mr-20">
                    <div
                      className="gkw-img d-flex wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <NavLink to="/suno-03-activity-05-screen">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">वापस जाएं</p>
                      </NavLink>
                    </div>
                  </div>
                  <div>
                    <div
                      className=" wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.4s"
                    >
                      <NavLink to="/suno-03-activity-07-screen" className="btnset ">
                        <span>अगली एक्टिविटी </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <center>
                  <img
                    draggable="false"
                    className="mt-0"
                    width="100%"
                    src="assets/img/suno/suno-img-2.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno03Activity06Screen;
