import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';

const About = () => {
  return (
    <>
    
    <Header/>
        <section className="bg-boarding position-initial" >
           <div className="container">
               <div className="row align-items-center justify-content-between">
                   <div className="col-lg-12 d-none d-lg-block text-lg-center text-xl-right">
                       <div className="set-img ">
                          <a href="signup-and-login%20screen.html"> <center><img width="400px" src="assets/img/on-boarding/tkt-logo.png"/></center></a>
                          <a href="signup-and-login%20screen.html"> <center><img width="50px" className="efffect-scale" src="assets/img/icon/arrow-right.png"/></center></a>


                       </div>
                   </div>
               </div>
           </div>
        </section>
        
        <Footer/>
        
    </>
  )
}

export default About