import React from "react";
import { NavLink } from "react-router-dom";

const Gyanbox02KheloActivityScreen = () => {
  return (
    <>
      <main>
        <section className="bg-boarding FDF2E0">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col col-lg-7 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  ज्ञान बॉक्स
                </h2>
              </div>
              <div className="col col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/khelo-02-activity-04-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div className="row align-items-top justify-content-between mt-50">
              <div
                className="col-lg-7 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mpara large-para fs-30 mt-40">
                  क्या हुआ? क्या आपने अच्छे से पूरा पेज पढ़ा? अगर हाँ तो आपको
                  पूरे तारे मिलेंगे और अगर ना तो आपको अपनी आदतों में कुछ बदलाव
                  लाने की ज़रूरत है। सबसे पहले यह समझना है कि हमें जो भी बोला जाए
                  उसे ध्यान लगा कर पढ़ना और सुनना बहुत ज़रूरी होता है। और जब हम
                  ध्यान लगा कर पढ़ते हैं या सुनते हैं तो काफ़ी मेहनत भी कम हो जाती
                  है जैसा कि आपने देखा, हमें बस 5 सवालों के ही उत्तर देने थे। इस
                  एक्टिविटी से हमें पता चला कि हमें हमेशा ध्यान लगा कर पढ़ना एवं
                  सुनना चाहिए। क्या आप एक बार फिर से ट्राई करना चाहते हैं? अगर
                  हाँ तो ट्राई अगैन बटन को दबाये और सही उत्तर दें।{" "}
                </p>

                <NavLink to="/khelo-03-activity-01-screen" className="btnset ">
                    <span>आगे बढ़ें</span>{" "}
                  </NavLink>

              </div>
              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="80%"
                    src="assets/img/khelo/pas-main-img.svg"
                  />
                </center>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Gyanbox02KheloActivityScreen;
