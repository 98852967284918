import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";

const Karo04Activity06Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [checkBlankData, setCheckBlankData] = useState("blank");
  const [selectItems, setSelectItems] = useState([]);
  const [yeschecked, setYesChecked] = useState();
  const [nochecked, setNoChecked] = useState();


  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  const getSelect = (e) => {
    const activity = e.target.value;

    setCheckBlankData("filled");

    var data = JSON.stringify({
      user_id: user_id,
      ans: activity,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity04",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        if (response.data.message === 'Could Not Create Or Update,Your Certificate has Been Created') {

          navigate("/karo-07-activity-05-screen");
          return false;
        }

        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(selectActivity);
  };


  // const user_details = JSON.parse(localStorage.getItem("user_details"));
  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
    });

    const config = {
      method: "post",
      url: BaseURL + "/getKaroActivity04ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          setCheckBlankData('filled');
          const __ans = response.data.data.ans;
          console.log(__ans);
          if (__ans === 'हाँ') {
            setYesChecked('checked');
          }

          if (__ans === 'नहीं') {
            setNoChecked('checked');
          }



        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  const saveFinalStar = () => {

    var data = JSON.stringify({
      user_id: user_id,
      "total_stars": "10",
      "activity_type": "karo",
      "activity_no": "4",
      "user_star": "10"
    });

    var config = {
      method: 'post',
      url: BaseURL + '/addFinalStars',
      headers: {
        'Content-Type': 'application/json',
        'type': `${userType} `,
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/karo-04-activity-08-screen');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  // console.log(checkBlankData);

  const BtnDisabled = () => {

    if (checkBlankData !== 'blank') {
      return (
        <>
          <div className="col-lg-12">
            <div
              className="mt-50 mb-15 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <center>
                <button
                  className="theme_btn theme_btn_bg chnage-d purple-shadow m-auto"

                  onClick={saveFinalStar}
                >
                  आगे बढ़ेंं{" "}
                </button>
              </center>
            </div>
          </div>
        </>
      )
    } else {

      return (
        <>
          <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >


            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink> */}

            <button class="btnset m-auto disabled-btn" type="button" disabled>आगे बढ़ेंं</button>


          </div>
        </>
      )

    }
  }



  return (
    <>
      <main>
        <section className="bg-flexible blues">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">

              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6">
                  <h1
                    className="h1 mb-20 mt-40 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    {" "}
                    यदि 1 महीने में आपकी शादी होने वाली हो तो क्या आप यह सब कर पाएँगे ?

                  </h1>
                </div>

                <div className="col-lg-4">
                  <div className="content-area">
                    <div className="invisible-checkboxes yes-design">
                      <input
                        type="radio"
                        name="rGroup"
                        value="हाँ"
                        id="yes"
                        onClick={getSelect}
                        checked={yeschecked}
                      />
                      <label
                        className="checkbox-alias activity-btn yes-bg-change"
                        for="yes"
                      >
                        <h4 className="slider-h">हाँ </h4>
                      </label>
                    </div>
                  </div>

                  <div className="content-area">
                    <div className="invisible-checkboxes yes-design red-bg-no">
                      <input
                        type="radio"
                        name="rGroup"
                        value="नहीं"
                        id="no"
                        onClick={getSelect}
                        checked={nochecked}
                      />
                      <label
                        className="checkbox-alias activity-btn no-bg-change"
                        for="no"
                      >
                        <h4 className="slider-h">नहीं </h4>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <BtnDisabled />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo04Activity06Screen;
