import React, { useState } from "react";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import FlashMessage from "../FlashMessage";
import { NavLink, useNavigate } from 'react-router-dom';
import HomeMenu from "../common/HomeMenu";
import HomeMenuOuter from "../common/HomeMenuOuter";

const ResetUsername = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [confirmUsername, setConfirmUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState({});
  const [confirmUsernameErr, setConfirmUsernameErr] = useState({});
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  // console.log(username);
  const changePassword = (e) => {


    e.preventDefault();

    const forgetdetails = JSON.parse(
        localStorage.getItem("forgot_password_details")
      );
    const isValid = formValidation();


    var data = JSON.stringify({
      user_id: forgetdetails.id,
      username: username,
      username_confirmation: confirmUsername,


    });

    var config = {
      method: "post",
      url: BaseURL+"/resetUsername",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
          if(response.data.success === true)
          {
            localStorage.removeItem("forgot_password_details")
            setConfirmUsername('');
            setUsername('');
            setMessage(response.data.message);
            setType("success");
            navigate('/login');

          }else{
            setMessage(response.data.message);
            setType("danger");
          }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   // form validation
  const formValidation = () => {
    const usernameErr = {};
    const confirmUsernameErr = {};

    const username1 = username === undefined ? "" : username;
    const confirmUsername1 =
    confirmUsername === undefined ? "" : confirmUsername;

    let isValid = true;

    // console.log(confirmUsername1.length);
    if (username1.length == 0) {
        usernameErr.required = "उपयोगकर्ता नाम आवश्यक है";
      isValid = false;
    }

    if (confirmUsername1.length == 0) {
        confirmUsernameErr.required = "पुष्टि करें कि उपयोगकर्ता नाम आवश्यक है";
      isValid = false;
    }

    if (username !== confirmUsername) {
        confirmUsernameErr.required = "पुष्टि करें कि उपयोगकर्ता नाम मेल नहीं खाता";
      isValid = false;
    }

    setUsernameErr(usernameErr);
    setConfirmUsernameErr(confirmUsernameErr);

    return isValid;
  };

  return (
    <>
      <form method="post" onSubmit={changePassword}>
        <main>
          <section className="bg-flexible">

          <HomeMenuOuter  />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                <FlashMessage type={type} message={message} />
                  <h3
                    className="main-headding wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                   उपयोगकर्ता नाम रीसेट करें
                  </h3>

                  <div
                    className="input-group wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <label className="levelset ">उपयोगकर्ता नाम</label>
                    <div className="input right">
                      <input
                        type="text"
                        className="set-input"
                        placeholder="************"
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                      />
                    </div>

                    {Object.keys(usernameErr).map((key) => {
                      return <span className="error">{usernameErr[key]}</span>;
                    })}
                  </div>

                  <div
                    className="input-group mt-20 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <label className="levelset">उपयोगकर्ता नाम की पुष्टि करें</label>
                    <div className="input right pink-shodow-input">
                      <input
                        type="text"
                        className="set-input"
                        placeholder="***********"
                        value={confirmUsername}
                        onChange={(e) => {
                          setConfirmUsername(e.target.value);
                        }}
                      />
                    </div>

                    {Object.keys(confirmUsernameErr).map((key) => {
                      return (
                        <span className="error">{confirmUsernameErr[key]}</span>
                      );
                    })}
                  </div>

                  <div
                    className="mt-15 mb-15 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <button type="submit" className="btnset blue-shadow ">
                      <span>आगे बढ़ेंं </span>
                    </button>

                    <NavLink to="/login" className="fp">
                    लॉगिन करें{" "}
                    </NavLink>

                  </div>


                </div>

                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mt-50"
                      width="95%"
                      src="assets/img/general/sign-up2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
            </div>
          </section>
        </main>
      </form>
    </>
  );
};

export default ResetUsername;
