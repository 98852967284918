import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    <div class="footer-new">
        <div class="container-fluid cf-set">
            <div class="row align-items-center justify-content-center ">
                <div class="col-lg-12 d-flex">
                    <div class="text-center m-auto font-set-f">
                        All Rights Reserved, {(new Date().getFullYear())}
                        <NavLink to="/"><img class="brand b2 footer-home-logo" src="assets/img/general/bt_new_logo.png" alt="" /></NavLink>
                    </div>
                </div>
                <div className="col-lg-12 ">

                    <div className="d-flex align-items-center justify-content-center">
                        <ul className="terms-c">
                            <li> <NavLink to="/terms-and-conditions">Terms & Conditions </NavLink>	</li>
                            <li> <NavLink to="/privacy-policy">Privacy Policy </NavLink>	</li>
                            <li > <NavLink to="/support" style={{"color":"#fff"}}>Contact Us </NavLink>	</li>
                        </ul>
                    </div>

                </div>



            </div>
        </div>
    </div>

    <div className="search-wrap">
        <div className="search-inner">
            <i className="fas fa-times search-close" id="search-close"></i>
            <div className="search-cell">
                <form method="get">
                    <div className="search-field-holder">
                        <input type="search" className="main-search-input" placeholder="Search Your Keyword..."/>
                    </div>
                </form>
            </div>
        </div>
    </div>



    </>
  )
}

export default Footer