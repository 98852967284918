import React from 'react';
import { NavLink} from 'react-router-dom';

const InnerFooter = () => {
  return (
    <>
        <div className="bottomdesign">
            <div className="container-fluid cf-set">
                <div className="row align-items-center justify-content-center ">
                    <div className="col-  ">
                        <h4 className="all-right">All Rights Reserved, {(new Date().getFullYear())}</h4>
                    </div>
                    <div className="col- col-m-auto">
                        <NavLink to="/"><img className="brand b2" src="assets/img/general/bt_new_logo.png" alt="" /></NavLink>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default InnerFooter