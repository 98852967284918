import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";

const Karo01GalatJawabScreen = () => {
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-30"
  );
  const [faqsize, setfaqsize] = useState("col-lg-3");

  const [item, setItem] = useState([]);
  const user_details = JSON.parse(localStorage.getItem("user_details"));

  useEffect(() => {
    const data = JSON.stringify({
      user_id: user_details.user_id,
    });

    //    console.log(data);

    const config = {
      method: "POST",
      url: BaseURL + "/karoActivity01Result",
      headers: { 
        'Authorization': `Bearer ${user_details.token} `, 
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setItem(response.data.data);
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <main>
        <section className="bg-flexible  blues">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-center mt-40">
              <div className="col-lg-4">
                <div
                  className="six-tare mt-80 mt-m-1 wow fadeInDow"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="rh1 red">{item.wrongans} गलत उत्तर है! </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img
                        src="assets/img/general/star-svgrepo-com.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src="assets/img/general/star-svgrepo-com.svg"
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        src="assets/img/general/star-svgrepo-com.svg"
                        alt=""
                      />
                    </li>
                    {/* <li><h4></h4></li> */}
                  </ul>
                </div>

                <div className="d-flex mt-30">
                  <div className="mr-20">
                    <div
                      className="gkw-img d-flex wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <NavLink to="bvpsb-sahi-jawab.html">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">वापस जाएं</p>
                      </NavLink>
                    </div>
                  </div>
                  <div>
                    <div
                      className=" wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.4s"
                    >
                      <NavLink
                        to="/karo-02-activity-01-screen"
                        className="btnset "
                      >
                        <span>आगे बढ़ें  </span>{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                <center>
                  <img
                    draggable="false"
                    width="78%"
                    src="assets/img/general/samaj-badana.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo01GalatJawabScreen;
