import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import BaseURL from "../common/Baseurl";
import axios from "axios";

const ShabdkoshList = () => {
  const [dictionary, setDictionary] = useState([]);
  useEffect(() => {
    var config = {
      method: "get",
      url: BaseURL + "/dictionary",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setDictionary(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const searchWord = (e) => {
    const word = e.target.value;

    var config = {
      method: "get",

      url: BaseURL + "/dictionary?word=" + word,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setDictionary(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (
    <>
      <main>
        <section className="kya-karna-hai-scroll general-bg">
          <div className="">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 ">
                <div className="col-lg-7">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    शब्दकोश
                  </h2>
                </div>
                <div className="col-lg-5">
                  <NavLink
                    className="float-right width-auto"
                    to="/board-screen"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="container-fluid cf-set">
              <div className="row">
                <div className="col-md-6">
                  <p
                    className="mpara fs-30 large-para wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    सभी जेंडर शब्दों और उनकी परिभाषाओं के बारे में जाने
                  </p>
                  <div className="search-box">
                    <div className="icon">
                      <img src="assets/img/icon/search2.svg" alt="" />
                    </div>
                    <div className="input-search">
                      <input type="text" placeholder="खोजें ..." onChange={searchWord} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-30 ">
                {dictionary.map((dict) => {
                  return (
                    <>
                      <div className="col-md-4 d-flex mt-30">
                        <div className="share-box">
                          <h4 className="h4">{dict.words}</h4>
                          {dict.data.map((word) => {
                            return (
                              <>
                                <div className="row border-top pt-10">
                                  <div className="col-md-8">
                                    <h5> {word.word}</h5>
                                  </div>
                                  <div className="col-md-4 text-right">
                                    <NavLink to={{
                                        pathname:"/shabdkosh-details/"+word.id,
                                        state:{stateParam:true},
                                       
                                    }} className="right-arrow-icon">
                                      <i className="ti-angle-right" />
                                    </NavLink>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ShabdkoshList;
