import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Khelo09Activity14Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible bg-img-mobile khelo-bg mblh-15">
          <div className="fixed-header">
            <Name/>
          </div>
          <div
            className="footer-back mb-30 agge-bade-btn-set"
            
          >
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center ">
                <div className="col-lg-12">
                  <div className="mr-20">
                    {/* <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="btnset m-auto"><span>आगे</span> </a> */}
                    <NavLink to="/khelo-09-activity-15-screen">
                      <button type="button" className="btnset m-auto">
                        आगे{" "}
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo09Activity14Screen;
