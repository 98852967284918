import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import Name from '../common/Name';

const Karo05Activity01Screen = () => {
    const [lg, setLg] = useState("col-lg-6");
    const [rowAllgn, setrowAllgn] = useState(
      "row align-items-center justify-content-center"
    );
    const [faqsize, setfaqsize] = useState("col-lg-3");
  return (
    <>
            <main>
		
        <section className="bg-flexible karo">
           <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
           <div class="data-adjust">
           <div className="container-fluid cf-set">
               <div className="row align-items-center justify-content-center mt-30">
                  
                   <div className="col-lg-4">
                       <h1 className="h1 mb-20 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">करो</h1>
                      

                       <div className="karo-activity-barabri wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <h4>आपसी मतभेद  को करें खत्म </h4>
                           <img width="70%" src="assets/img/general/apsi-muth-bhed.svg" alt="" />
                           <div className="mt-0">
                               <NavLink to="/karo-05-activity-02-screen" className="btnset radius-set m-auto"><span>चुनें</span> </NavLink>
                           </div>
                       </div>
                   </div>
                   <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <center><img draggable="false" className="" width="96%" src="assets/img/karo/apsi-muthbhed.svg" /></center>
                   </div>
               </div>


           </div>
           </div>
        </section>
   
       

   </main>

    </>
  )
}

export default Karo05Activity01Screen