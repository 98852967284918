import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ProfileMenu from "./common/ProfileMenu";
import axios from "axios";
import BaseURL from "./common/Baseurl";

const GeneralScreenResult = () => {
  const [lg, setLg] = useState("col-lg-7");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-15"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const [totalStars,setTotalStars] = useState();

  useEffect(() => {
   
    var data = JSON.stringify({
      user_id: user_details.user_id,
      "activity_type": "game",
      "activity_no": "1"
    });

    var config = {
      method: "post",
      url: BaseURL+"/getFinalStars",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.data.total_stars));
        if(response.data.success === true)
        {
          setTotalStars(response.data.data.total_stars)
        }
       
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <main>
        <section className="fixed-bg general overflow">
          <ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          {/* <div class="data-adjust"> */}

          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center mt-10">
              <div className="col-lg-6 mt-m-2 order-sm-2 order-md-2 order-lg-1">
                <center>
                  <img
                    draggable="false"
                    width="100%"
                    src="assets/img/general/single-pinky2.svg"
                  />
                </center>
              </div>
              <div className="col-lg-5  order-sm-1 order-md-1 order-lg-2">
                <div
                  className="box-radius text-center mt-m-2 mb-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h4 className="green-heading">शाबाश!!</h4>
                  <h1 className="rh1">आपने इकट्ठा किए </h1>

                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{totalStars}</h4>
                    </li>
                  </ul>


                  {/* <center>
                    <img width="40%" src="assets/img/ratting.svg" alt="" />
                  </center> */}
                </div>

                <div
                  className="mt-30 mb-10"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <div className="dispalyset f-left">
                    <NavLink to="/general-screen2" className="btnset m-auto">
                      <span>आगे बढ़ेंं </span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* </div> */}
        </section>
      </main>
    </>
  );
};

export default GeneralScreenResult;
