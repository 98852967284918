import React, { useState, useEffect } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink } from "react-router-dom";

const Khelo12Activity08Screen = () => {
  const [larkaCss, setLarkaCss] = useState();
  const [larkiCss, setLarkiCss] = useState();
  const [donoCss, setDonoCss] = useState();
  const [checkBlankData, setBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  const selectAns = (e) => {
    const selectVal = e.target.value;

    checkValue(selectVal);
    //   console.log(selectVal);
    setBlankData("filled");

    var data = JSON.stringify({
      user_id: user_id,
      ans: selectVal,
      ques_id: "6",
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity12",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const checkValue = (selectVal) => {
    if (selectVal === "उसको बताना है कि वह गलत है") {
      setLarkaCss({ opacity: "1" });
      setLarkiCss({ opacity: "0.6" });
      setDonoCss({ opacity: "0.6" });
    }

    if (selectVal === "वहाँ से भागने की कोशिश करनी है") {
      setLarkiCss({ opacity: "1" });
      setLarkaCss({ opacity: "0.6" });
      setDonoCss({ opacity: "0.6" });
    }

    if (selectVal === "तुरंत किसी भरोसमंद बड़े को बताना है") {
      setDonoCss({ opacity: "1" });
      setLarkiCss({ opacity: "0.6" });
      setLarkaCss({ opacity: "0.6" });
    }
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "6",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity12ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const selectVal = response.data.data.ans;
          checkValue(selectVal);
          setBlankData("filled");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div
            className="mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <NavLink
              to="/khelo-12-activity-09-screen"
              className="btnset m-auto "
            >
              आगे बढ़ेंं
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible F0F4FF">
          <div className="fixed-header">
          <Name/>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4 krs-row-set">
                  <div
                    className="mt-m-2 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <button className="theme_btn theme_btn_bg chnage-d step-btn">
                      <span className="span-c-change">06</span>
                    </button>
                  </div>
                  <div
                    className="gif-box-set mt-m-2 krs-gif-box mt-15 pt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <center>
                      <img
                        width="80%"
                        src="assets/img/kaise/Kaiseraheinsurakshit6.gif"
                        alt=""
                      />
                    </center>
                  </div>
                </div>
                <div
                  className="col-lg-4 krs-row-set wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p className="mpara mt-m-1 large-para fs-30 mt-40 text-center">

यदि कोई आपको अपशब्द कहे या आपका मज़ाक उड़ाए तो आपको क्या करना है?
                  </p>
                </div>
                <div className="col-lg-4 krs-row-set krs-box-change">
                  <div className="kkkn-check-effect">
                    <div
                      className="activity-box invisible-checkboxes kkkn-yellow-bg wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                      style={larkaCss}
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="उसको बताना है कि वह गलत है"
                        id="t1"
                        onClick={selectAns}
                      />
                      <label
                        className="checkbox-alias kkkn-yellow-bg-sub"
                        htmlFor="t1"
                      >
                        <h4 className="slider-h burai-text"> उसको बताना है कि वह गलत है</h4>
                      </label>
                    </div>
                    <div
                      className="activity-box invisible-checkboxes kkkn-yellow-bg green-bg blue-bg mt-15 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                      style={larkiCss}
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="वहाँ से भागने की कोशिश करनी है"
                        id="b1"
                        onClick={selectAns}
                      />
                      <label
                        className="checkbox-alias kkkn-yellow-bg-sub kkkn-blue-bg"
                        htmlFor="b1"
                      >
                        <h4 className="slider-h burai-text">
                          वहाँ से भागने की कोशिश करनी है
                        </h4>
                      </label>
                    </div>
                    <div
                      className="activity-box invisible-checkboxes kkkn-yellow-bg kkkn-pink-bg  mt-15 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                      style={donoCss}
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="तुरंत किसी भरोसमंद बड़े को बताना है"
                        id="b2"
                        onClick={selectAns}
                      />
                      <label
                        className="checkbox-alias kkkn-yellow-bg-sub kkkn-pin-bg-sub "
                        htmlFor="b2"
                      >
                        <h4 className="slider-h burai-text">
                          {" "}
                          तुरंत किसी भरोसमंद बड़े को बताना है
                        </h4>
                      </label>
                    </div>
                   <BtnDisabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo12Activity08Screen;
