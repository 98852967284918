import React from "react";
import { NavLink } from "react-router-dom";

const AccountDelete = () => {
  return (
    <>
      <main>
        <section className="bg-flexible general-bg">
          <div className="">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 ">
                <div className="col-lg-7">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    पासवर्ड
                  </h2>
                </div>
                <div className="col-lg-5">
                  <NavLink
                    className="float-right width-auto"
                    to="/account-list"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row mt-30 align-items-center justify-content-center ">
                <div className="col-lg-4">
                  <div
                    className="six-tare text-center mt-50 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <center>
                      <img src="assets/img/icon/check-icon2.svg" alt="" />
                    </center>
                    <h3 className="green text-center mt-30">
                      अकाउंट डिलीट करना <br />
                      पूर्ण हुआ हुआ
                    </h3>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      className={0}
                      width="100%"
                      src="assets/img/on-boarding/pinky-and-punkaj.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AccountDelete;
