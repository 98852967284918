import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import ProfileMenu from './common/ProfileMenu';

const GeneralScreen2 = () => {
    const [lg,setLg] = useState('col-lg-5');
    const [rowAllgn, setrowAllgn] = useState(
        "row align-items-center justify-content-center"
      );
      const [faqsize, setfaqsize] = useState("col-lg-5");
  return (
    <>
        
    <main>
		
        <section className="bg-flexible overflow">
           <ProfileMenu lg={lg} faqsize={faqsize} rowAllgn={rowAllgn}/>
           <div class="data-adjust">
           <div className="container-fluid cf-set">
           {/* <br></br> */}
           
               <div className="row align-items-center justify-content-center">
                   <div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                       <center><img draggable="false"  width="80%" src="assets/img/on-boarding/tkt-logo.png" /></center>
                   </div>
                   <div className="col-lg-8">
                       <p className="mpara fs-30 large-para wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">अब हम कुछ गेम खेलकर और एक्टिविटी में हिस्सा लेकर तारे इकट्ठा करेंगे । </p>
                       <p className="mpara fs-30 large-para wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">विडियो देख कर हम जेंडर समानता के बारे मे जानेंगे। यह एक्टिविटी से आपको समाज मे आपके अधिकारों और जेंडर समानता के बारे मे जागरूक करने के लिए बनाई गई हैं! </p>
                       {/* <h4 className=" wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">मिलकर बनाएँगे हम तारे ये तारों की टोली </h4> */}
                       

                       <div className="mt-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">
                           <div className="dispalyset f-left">
                               <NavLink to="/board-screen" className="btnset "><span>आगे बढ़ेंं  </span> </NavLink>
                           </div>
                       </div>

                   </div>
                   <div className="col-lg-1"></div>

                

                   
               </div>
               </div>
           </div>
        </section>
   
       

   </main>
   

    </>
  )
}

export default GeneralScreen2