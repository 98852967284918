import React, { useState,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: false,
  navText: ["Prev", "Next"],
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 5,

      }
  },
};

const Karo04Activity04Screen = () => {

  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [selectItems,setSelectItems] = useState([]);

  const [baharchecked ,setBaharCheckedItem] = useState();
  const [ganachecked ,setGanaCheckedItem] = useState();
  const [dostochecked ,setDostoCheckedItem] = useState();
  const [nityachecked ,setNityaCheckedItem] = useState();
  const [chitrachecked ,setChitraCheckedItem] = useState();
  const [padhaichecked ,setPadhaiCheckedItem] = useState();
  const [parivarchecked ,setParivarCheckedItem] = useState();
  const [mobilechecked ,setMobileCheckedItem] = useState();

  const [checkBlankData,setCheckBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const selectActivity = [];
  const getSelect = (e) => {

    const activity = e.target.value;
    selectActivity.push(activity);
    setCheckBlankData('filled');


    var data = JSON.stringify({
      user_id: user_id,
      ans: selectActivity.join(),
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity04",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        if(response.data.message === 'Could Not Create Or Update,Your Certificate has Been Created')
        {

          navigate("/karo-07-activity-05-screen");
          return false;
        }

        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(selectActivity);
  };

  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "1",
    });

    const config = {
      method: "post",
      url: BaseURL + "/getKaroActivity04ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if(response.data.success === true)
        {
          setCheckBlankData('filled');
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(',');
          // console.log(Ans_data);

          Ans_data.map((item)=>{

            if(item === 'बाहर खेलना')
            {
              setBaharCheckedItem('checked') ;
            }

            if(item === 'दोस्तों के साथ घूमना')
            {
              setDostoCheckedItem('checked')
            }

            if(item === 'गाना बजाना')
            {
              setGanaCheckedItem('checked') ;
            }

            if(item === 'नृत्य करना')
            {
              setNityaCheckedItem('checked')
            }

            if(item === 'चित्र बनाना')
            {
              setChitraCheckedItem('checked')
            }

            if(item === 'पढ़ाई करना')
            {
              setPadhaiCheckedItem('checked')
            }

            if(item === 'परिवार के साथ बैठ कर बातें करना')
            {
              setParivarCheckedItem('checked');
            }

            if(item === 'मोबाइल का इस्तेमाल करना')
            {
              setMobileCheckedItem('checked');
            }





          })

          setSelectItems(Ans_data);
          // setCheckBlankData("filled");
            // setExistImage(response.data.data.ans);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);






  const BtnDisabled = ()=>{

    if(checkBlankData !== 'blank') {
      return(
        <>
              <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >


            <NavLink  to="/karo-04-activity-06-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink>


          </div>



        </>
      )
    }else{

      return(
        <>
              <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >


            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink> */}

            <button  class="btnset m-auto disabled-btn" type="button" disabled>आगे बढ़ेंं</button>


          </div>
        </>
      )

    }
  }




  return (
    <>
      <main>
        <section className="bg-flexible blues">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-between">
              <div className="col-lg-12">
                <h1
                  className="h1 mb-20 mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {" "}
                  आपको क्या करना सबसे ज़्यादा पसंद है?{" "}
                </h1>
              </div>



              <div className="col-lg-12">
                <OwlCarousel className='owl-theme' items={6} margin={8} autoplay={true}  dots  >
                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/bahar-khelna.gif"
                            alt=""
                          />
                        </center>
                      </div>
                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="बाहर खेलना"
                            id="skljk1"
                            onClick={getSelect}
                            checked={baharchecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk1"
                          >
                            <h4 className="slider-h"> बाहर खेलना</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/doston-ka-saath-ghoomna.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="दोस्तों के साथ घूमना"
                            id="skljk2"
                            onClick={getSelect}
                            checked={dostochecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk2"
                          >
                            <h4 className="slider-h"> दोस्तों के साथ घूमना</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/gaana-bajaana.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="गाना बजाना"
                            id="skljk3"
                            onClick={getSelect}
                            checked={ganachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk3"
                          >
                            <h4 className="slider-h"> गाना बजाना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/nratya-karana.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="नृत्य करना"
                            id="skljk4"
                            onClick={getSelect}
                            checked={nityachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk4"
                          >
                            <h4 className="slider-h"> नृत्य करना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/chittr-banana.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="चित्र बनाना"
                            id="skljk5"
                            onClick={getSelect}
                            checked={chitrachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk5"
                          >
                            <h4 className="slider-h"> चित्र बनाना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/padai-karna.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="पढ़ाई करना"
                            id="skljk6"
                            onClick={getSelect}
                            checked={padhaichecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk6"
                          >
                            <h4 className="slider-h">पढ़ाई करना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/mobile-ka-istemaal-karna.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="मोबाइल का इस्तेमाल करना"
                            id="skljk7"
                            onClick={getSelect}
                            checked={mobilechecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk7"
                          >
                            <h4 className="slider-h">
                              मोबाइल का इस्तेमाल करना
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/pari-baar-ke-sath-baaat-karna.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="परिवार के साथ बैठ कर बातें करना"
                            id="skljk8"
                            onClick={getSelect}
                            checked={parivarchecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk8"
                          >
                            <h4 className="slider-h">
                              परिवार के साथ बैठ कर बातें करना
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>

               <BtnDisabled />
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo04Activity04Screen;
