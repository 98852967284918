import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Karo04Activity05Screen = () => {

  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");

  const [apnasathichecked,setApnaSathiCheckedItem] = useState();
  const [apnasathimatachecked,setSathiMataCheckedItem] = useState();
  const [khanachecked,setkhanaCheckedItem] = useState();
  const [gharchecked,setGharCheckedItem] = useState();
  const [dekhbhalchecked,setDekhbhalCheckedItem] = useState();
  const [baharchecked,setBaharCheckedItem] = useState();
  const [dostochecked ,setDostoCheckedItem] = useState();
  const [ganachecked ,setGanaCheckedItem] = useState();
  const [chitrachecked ,setChitraCheckedItem] = useState();
  const [selectItems,setSelectItems] = useState([]);

  const [checkBlankData,setCheckBlankData] = useState("blank");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }



  const selectActivity = [];
  const getSelect = (e) => {

    const activity = e.target.value;
    selectActivity.push(activity);
    setCheckBlankData('filled');

    var data = JSON.stringify({
      user_id: user_id,
      ans: selectActivity.join(),
      ques_id: "2",
    });

    var config = {
      method: "post",
      url: BaseURL + "/karoActivity04",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(selectActivity);
  };

  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "2",
    });

    const config = {
      method: "post",
      url: BaseURL + "/getKaroActivity04ByUser",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
            setCheckBlankData('filled');
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(",");
          // console.log(Ans_data);

          Ans_data.map((item) => {
            if (item === "अपने साथी (पति/पत्नी) का ध्यान रखना") {
              setApnaSathiCheckedItem("checked");
            }

            if (item === "अपने साथी के माता - पिता का ध्यान रखना") {
              setSathiMataCheckedItem("checked");
            }

            if (item === "सबके लिए खाना बनाना") {
              setkhanaCheckedItem("checked");
            }

            if (item === "घर की सफाई करना") {
              setGharCheckedItem("checked");
            }

            if (item === "अपने परिवार की देख भाल के लिए, कोई नौकरी करना") {
              setDekhbhalCheckedItem("checked");
            }

            if (item === "बाहर खेलना") {
              setBaharCheckedItem("checked");
            }

            if(item === 'दोस्तों के साथ घूमना')
            {
              setDostoCheckedItem('checked')
            }

            if(item === 'गाना बजाना')
            {
              setGanaCheckedItem('checked') ;
            }

            if(item === 'चित्र बनाना')
            {
              setChitraCheckedItem('checked')
            }


          });

          setSelectItems(Ans_data);

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  useEffect(() => {}, []);

  const BtnDisabled = ()=>{

    if(checkBlankData !== 'blank') {
      return(
        <>
               <div className="mt-15 mb-15">
                  <center>
                    <NavLink
                      className="theme_btn theme_btn_bg chnage-d purple-shadow m-auto"
                      to="/karo-04-activity-07-screen"
                    >
                      आगे बढ़ेंं{" "}
                    </NavLink>
                  </center>
                </div>
        </>
      )
    }else{

      return(
        <>
              <div
            className="mt-30 mb-15"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >


            {/* <NavLink  to="/karo-04-activity-screen" className="btnset m-auto">
              <span>आगे बढ़ेंं </span>{" "}
            </NavLink> */}

            <button  class="btnset m-auto disabled-btn" type="button" disabled>आगे बढ़ेंं</button>


          </div>
        </>
      )

    }
  }



  return (
    <>
      <main>
        <section className="bg-flexible blues">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-between">
              <div className="col-lg-12">
                <h1
                  className="h1 mb-20 mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  {" "}
                  यदि 1 महीने में आपकी शादी है तो आप कौन सी गतिविधियाँ कर पाएँगे ?
                </h1>
              </div>
              <div className="col-lg-12">
                <OwlCarousel className='owl-theme' items={6} margin={8} autoplay={true} dots>
                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/apne-sathi-pati-patni.gif"
                            alt=""
                          />
                        </center>
                      </div>
                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="अपने साथी (पति/पत्नी) का ध्यान रखना"
                            onClick={getSelect}
                            id="skljk1"
                            checked={apnasathichecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk1"
                          >
                            <h4 className="slider-h">
                              {" "}
                              अपने साथी (पति/पत्नी) का ध्यान रखना{" "}
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/apne-sathi-mata-pita-ka-dhyan.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="अपने साथी के माता - पिता का ध्यान रखना"
                            onClick={getSelect}
                            id="skljk2"
                            checked={apnasathimatachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk2"
                          >
                            <h4 className="slider-h">
                              {" "}
                              अपने साथी के माता - पिता का ध्यान रखना{" "}
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/khana-bana.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="सबके लिए खाना बनाना"
                            onClick={getSelect}
                            id="skljk3"
                            checked={khanachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk3"
                          >
                            <h4 className="slider-h">सबके लिए खाना बनाना</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/ghar-ki-safai.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="घर की सफाई करना"
                            onClick={getSelect}
                            id="skljk4"
                            checked={gharchecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk4"
                          >
                            <h4 className="slider-h">घर की सफाई करना</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/parivar-ki-dekh-bhal.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="अपने परिवार की देख भाल के लिए, कोई नौकरी करना"
                            onClick={getSelect}
                            id="skljk5"
                            checked={dekhbhalchecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk5"
                          >
                            <h4 className="slider-h">
                              अपने परिवार की देख भाल के लिए, कोई नौकरी करना
                            </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/bahar-khelna.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="बाहर खेलना"
                            id="skljk6"
                            onClick={getSelect}
                            checked={baharchecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk6"
                          >
                            <h4 className="slider-h">बाहर खेलना</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/doston-ka-saath-ghoomna.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="दोस्तों के साथ घूमना"
                            id="skljk7"
                            onClick={getSelect}
                            checked={dostochecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk7"
                          >
                            <h4 className="slider-h">दोस्तों के साथ घूमना</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/gaana-bajaana.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="गाना बजाना"
                            id="skljk8"
                            onClick={getSelect}
                            checked={ganachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk8"
                          >
                            <h4 className="slider-h">गाना बजाना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="post-slide">
                    <div className="activity-design">
                      <div className="box-img">
                        <center>
                          <img
                            src="assets/img/karo/gif/skljk/chitr-banana-2.gif"
                            alt=""
                          />
                        </center>
                      </div>

                      <div className="content-area">
                        <div className="invisible-checkboxes btn-select">
                          <input
                            type="checkbox"
                            name="rGroup"
                            value="चित्र बनाना"
                            id="skljk9"
                            onClick={getSelect}
                            checked={chitrachecked}
                          />
                          <label
                            className="checkbox-alias activity-btn"
                            for="skljk9"
                          >
                            <h4 className="slider-h">चित्र बनाना </h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>

               <BtnDisabled />
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo04Activity05Screen;
