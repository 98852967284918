import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

import axios from "axios";
import BaseURL from "../common/Baseurl";

const Khelo03Activity05Screen = () => {
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-10"
  );
  const [faqsize, setfaqsize] = useState("col-lg-3");
  const [totalStar,setTotalStar] = useState(0);

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {

    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "1",
    });

    var config = {
      method: "post",
      url: BaseURL+"/kheloActivity03Result",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
          if(response.data.success === true)
          {
              const totalStar = response.data.data.totalstar
              setTotalStar(totalStar);
          }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <main>
        <section className="bg-flexible EEFFE8">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />

          <div class="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-4">
                <div
                  className="six-tare mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="rh1 green">पहला स्तर पूरा हुआ ! </h1>
                  <p className="text-center"> तारे इकट्ठे हुए </p>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{totalStar}</h4>
                    </li>
                  </ul>
                </div>
                <div
                  className="mt-30 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <NavLink to="/khelo-03-activity-06-screen" className="btnset ">
                    <span>दूसरा स्तर</span>{" "}
                  </NavLink>
                </div>
              </div>
              <div
                className="col-lg-4 mt-m-1 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="90%"
                    src="assets/img/khelo/aaj-main-upar-main.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo03Activity05Screen;
