import React from "react";
import { NavLink } from "react-router-dom";

const Gyanbox04ActivityScreen = () => {
  return (
    <>
      <main>
        <section className="bg-boarding blues">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center ">
              <div className="col-lg-6 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  ज्ञान बॉक्स
                </h2>
              </div>
              <div className="col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/karo-04-activity-07-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div className="row align-items-top justify-content-center mt-50">
              <div
                className="col-lg-7 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mpara large-para fs-30 mt-40">
                  इस खेल में सबसे ज़रूरी बात यह है कि यदि जल्दी शादी हो गई तो
                  सभी कुछ छूट जाएगा। जो भी आज आप कर रहे हैं उसे बदलना पड़ेगा और
                  आपके सपने अधूरे रह जाएँगे। हम यह नहीं चाहते। अभी हम तैयार
                  नहीं। अभी हमें पढ़ना है। आगे चलकर नौकरी करनी है। यह बात न
                  सिर्फ़ लड़कों पर लेकिन उतनी ही लड़कियों पर भी लागू है। उन्हें
                  भी पढ़ाई पूरी करनी है, कुछ कौशल सीखना है, कॉलेज जाना है।
                </p>
                <NavLink to="/karo-05-activity-01-screen" className="btnset "><span>आगे बढ़ें </span> </NavLink>
              </div>

              <div
                className="col-lg-3 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <NavLink to="/karo-04-activity-04">
                  <center>
                    <img
                      draggable="false"
                      className="mb-15"
                      width="80%"
                      src="assets/img/karo/gyan-flower.png"
                    />
                  </center>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Gyanbox04ActivityScreen;
