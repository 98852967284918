import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Gyanbox06ActivityScreen = () => {
  const [lg, setLg] = useState("col-lg-6 d-flex");
  return (
    <>
      <main>
        <section className="fixed-bg line-bg">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-100">
              <div className="col-lg-7 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  ज्ञान बॉक्स
                </h2>
              </div>
              <div className="col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/karo-06-activity-05-screen"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div className="row align-items-top justify-content-between mt-50">
              <div className="col-lg-6">
                <p
                  className="mpara large-para fs-30 mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                  style={{
                    "font-size": "2.5vw !important",
                    "line-height": "4vw",
                  }}
                >
                  आपके मित्र या साथी लड़के भी हो सकते हैं और लड़कियाँ भी। हम दोनो
                  की खूबियाँ देख सकते हैं, इससे कोई फर्क नहीं पड़ता कि वो कौन
                  है, लड़का या लड़की - खूबी सब में होती है और आगे बढ़ने की ताकत
                  भी।{" "}
                </p>
                <NavLink
                        to="/karo-07-activity-01-screen"
                        className="btnset "
                      >
                        <span>अगली एक्टिविटी </span>{" "}
                      </NavLink>
              </div>
              <div
                className="col-lg-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <NavLink to="karo-06-activity-04-screen" className="width-p">
                  <center>
                    <img
                      draggable="false"
                      className="mb-15"
                      width="90%"
                      src="assets/img/karo/pinky-pankaj-blue.svg"
                    />
                  </center>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Gyanbox06ActivityScreen;
