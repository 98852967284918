import React, { useEffect, useState } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink } from "react-router-dom";

const Khelo06Activity05Screen = () => {

    const [checkQues1BlankData, setQues1CheckBlankData] = useState("blank");
    const [checkQues2BlankData, setQues2CheckBlankData] = useState("blank");
    const [ques1Value, setQues1Value] = useState(60);
    const [ques2Value, setQues2Value] = useState(60);

    const [larkaQues1Active, setLarkaQues1Active] = useState('');
    const [larkiQues1Active, setLarkiQues1Active] = useState('');
    const [donoQues1Active, setDonoQues1Active] = useState('');

    const [larkaQues2Active, setLarkaQues2Active] = useState('');
    const [larkiQues2Active, setLarkiQues2Active] = useState('');
    const [donoQues2Active, setDonoQues2Active] = useState('');


    const user_details = JSON.parse(localStorage.getItem("user_details"));
    const groupId = localStorage.getItem('groupId');

    let userType = 'user';
    let user_id = user_details.user_id;
    if(user_details.role_id == 16)
    {

      userType = 'group';
      user_id =groupId;
    }

    const quest5 = (e) => {
      const val1 = e.target.value;
      setQues1CheckBlankData("filled");

      var quesVal1 = "larki";

      if (val1 === "30") {
        quesVal1 = "larka";
        setLarkiQues1Active('')
        setLarkaQues1Active('active');
        setDonoQues1Active('');
      }

      if (val1 === "60") {
        quesVal1 = "larki";
        setLarkiQues1Active('active')
        setLarkaQues1Active('');
        setDonoQues1Active('');
      }

      if (val1 === "90") {
        quesVal1 = "dono";
        setLarkiQues1Active('')
        setLarkaQues1Active('');
        setDonoQues1Active('active');
      }

      var data = JSON.stringify({
        user_id: user_id,
        ans: quesVal1,
        ques_id: "5",
      });

      var config = {
        method: "post",
        url: BaseURL + "/kheloActivity06",
        headers: {
          "type":`${userType}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const quest6 = (e) => {
      const val1 = e.target.value;
      setQues2CheckBlankData("filled");

      var quesVal1 = "dono";

      if (val1 === "30") {
        quesVal1 = "larka";
        setLarkaQues2Active('active');
        setLarkiQues2Active('');
        setDonoQues2Active('');

      }

      if (val1 === "90") {
        quesVal1 = "larki";
        setLarkaQues2Active('');
        setLarkiQues2Active('active');
        setDonoQues2Active('');
      }

      if (val1 === "60") {
        quesVal1 = "dono";
        setLarkaQues2Active('');
        setLarkiQues2Active('');
        setDonoQues2Active('active');
      }

      var data = JSON.stringify({
        user_id: user_id,
        ans: quesVal1,
        ques_id: "6",
      });

      var config = {
        method: "post",
        url: BaseURL + "/kheloActivity06",
        headers: {
          "type":`${userType}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    useEffect(() => {

      var data = JSON.stringify({
        user_id: user_id,
        ques_id: "5",
      });

      var config = {
        method: "post",
        url: BaseURL+"/getKheloActivity06ByUser",
        headers: {
          "type":`${userType}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if(response.data.success === true)
          {
            setQues1CheckBlankData("filled");

            const result = response.data.data.ans;
            if(result === 'larka')
            {
              setQues1Value(30)
              setLarkiQues1Active('')
              setLarkaQues1Active('active');
             setDonoQues1Active('');
            }

            if(result === 'larki')
            {
              setQues1Value(60)
              setLarkiQues1Active('active')
              setLarkaQues1Active('');
              setDonoQues1Active('');
            }

            if(result === 'dono')
            {
              setQues1Value(90)
              setLarkiQues1Active('')
            setLarkaQues1Active('');
              setDonoQues1Active('active');
            }



          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);

    useEffect(() => {

      var data = JSON.stringify({
        user_id: user_id,
        ques_id: "6",
      });

      var config = {
        method: "post",
        url: BaseURL+"/getKheloActivity06ByUser",
        headers: {
          "type":`${userType}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if(response.data.success === true)
          {
            setQues2CheckBlankData("filled");

            const result2 = response.data.data.ans;
            if(result2 === 'larka')
            {
              setQues2Value(30)
              setLarkaQues2Active('active');
              setLarkiQues2Active('');
              setDonoQues2Active('');
            }

            if(result2 === 'dono')
            {
              setQues2Value(60)
              setLarkaQues2Active('');
              setLarkiQues2Active('');
              setDonoQues2Active('active');
            }

            if(result2 === 'larki')
            {
              setQues2Value(90)
              setLarkaQues2Active('');
              setLarkiQues2Active('active');
              setDonoQues2Active('');
            }



          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);



    const BtnDisabled = () => {
      if (checkQues1BlankData !== "blank" && checkQues2BlankData !== "blank") {
        return (
          <>
            <div
              className="col-lg-2 offset-lg-1"

            >
              <div
                className="mt-15 mb-15 mt-m-2"

              >
                <NavLink to="/khelo-06-activity-06-screen" className="btnset ">
                  आगे बढ़ेंं
                </NavLink>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div
              className="col-lg-2 offset-lg-1"

            >
              <div
                className="mt-15 mb-15 mt-m-2"

              >
                <button class="btnset m-auto disabled-btn" type="button" disabled>
                  आगे बढ़ेंं
                </button>
              </div>
            </div>
          </>
        );
      }
    };


  return (
    <>
      <main>
        <section className="bg-flexible F8F1FD">
          <div className="fixed-header">
          <Name/>
            <div className="container-fluid cf-set">

            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <div
                    className="mt-m-2 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <span
                      className="theme_btn theme_btn_bg chnage-d step-btn m-auto"
                    >
                      सवाल <span className="span-c-change">05</span>
                    </span>
                  </div>
                  <span className="note">टिप्पणी:नीचे दिए पीला बार पर क्लिक करें और अपना उत्तर चुनें</span>
                  <p
                    className="mpara mt-15 mt-m-1 large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    दूसरों के विचारों को सुनना और समझना{" "}
                  </p>
                  <div className="img_boxes2">
                    <span className={(larkaQues1Active === 'active')?larkaQues1Active:""} data={30}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img.svg" alt="" />
                      </center>
                    </span>
                    <span className={(larkiQues1Active === 'active')?larkiQues1Active:""}  data={60}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img2.svg" alt="" />
                      </center>
                    </span>
                    <span className={(donoQues1Active === 'active')?donoQues1Active:""} data={90}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img3.svg" alt="" />
                      </center>
                    </span>
                  </div>
                  <div className="mul-asul-slider">
                    <div className="slidecontainer">
                      <input
                        type="range"
                        min={30}
                        max={90}
                        defaultValue={ques1Value}
                        step={30}
                        className="murange"
                        id="myRange2"
                        onChange={quest5}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <div
                    className="mt-m-2 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <span
                      className="theme_btn theme_btn_bg chnage-d step-btn m-auto"

                    >
                      सवाल <span className="span-c-change">06</span>
                    </span>
                  </div>
                  <span className="note">टिप्पणी:नीचे दिए पीला बार पर क्लिक करें और अपना उत्तर चुनें</span>
                  <p
                    className="mpara mt-15 mt-m-1 large-para fs-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    खुलकर अपनी ज़िंदगी जीना
                  </p>
                  <div className="img_boxes">
                    <span className={(larkaQues2Active === 'active')?larkaQues2Active:""} data={30}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img.svg" alt="" />
                      </center>
                    </span>
                    <span  className={(donoQues2Active === 'active')?donoQues2Active:""} data={60}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img3.svg" alt="" />
                      </center>
                    </span>
                    <span className={(larkiQues2Active === 'active')?larkiQues2Active:""} data={90}>
                      {" "}
                      <center>
                        <img src="assets/img/khelo/mu-slider-img2.svg" alt="" />
                      </center>
                    </span>
                  </div>
                  <div className="mul-asul-slider">
                    <div className="slidecontainer">
                      <input
                        type="range"
                        min={30}
                        max={90}
                        defaultValue={ques1Value}
                        step={30}
                        className="murange"
                        id="myRange"
                        onChange={quest6}
                      />
                    </div>
                  </div>
                </div>
               <BtnDisabled/>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo06Activity05Screen;
