import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Karo06Activity01Screen = () => {
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-3");

  return (
    <>
      <main>
        <section className="bg-flexible karo">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">

          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center mt-30">
              <div className="col-lg-4">
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  करो
                </h1>

                <div
                  className="karo-activity-barabri wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <h4>हर दोस्त ज़रूरी है</h4>
                  <img
                    width="100%"
                    src="assets/img/general/priye-mitr.svg"
                    alt=""
                  />
                  <div className="mt-20">
                    <NavLink
                      to="/karo-06-activity-03-screen"
                      className="btnset radius-set m-auto"
                    >
                      <span>चुनें</span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 mt-m-2 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    draggable="false"
                    width="100%"
                    src="assets/img/karo/mera-priye-mitr.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Karo06Activity01Screen;
