import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Name from "../common/Name";


const Khelo04Activity10Screen = () => {


    const [user_star, setUser_star] = useState();

    const user_details = JSON.parse(localStorage.getItem("user_details"));

    useEffect(() => {
      var data = JSON.stringify({
        user_id: user_details.user_id,
        ques_id: "4",
      });

      var config = {
        method: "post",
        url: BaseURL + "/getKheloActivity04ByUser",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response.data.success === true) {
            setUser_star(response.data.data.user_star);
          }
        //   console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
    }, []);

    const Result1 = () => {
        if (user_star === 1) {
          return (
            <>
              <div
                className="six-tare  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h1 className="rh1 vw-2-5 green">
                  शाबाश! सही जवाब है <br />
                  विराट कोहली!!
                </h1>
                <p className="text-center"> तारे इकट्ठे हुए </p>
                <ul className="ul-r">
                  <li>
                    <img src="assets/img/icon/star.svg" alt="" />
                  </li>
                  <li>
                    <img src="assets/img/icon/star.svg" alt="" />
                  </li>
                  <li>
                    <img src="assets/img/icon/star.svg" alt="" />
                  </li>
                  <li>
                    <h4>{user_star}</h4>
                  </li>
                </ul>
              </div>
            </>
          );
        } else if (user_star === 0){
          return (
            <>
              <div
                className="six-tare  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h1 className="rh1 vw-2-5 red">
                  गलत जवाब! <br /> सही जवाब है विराट कोहली!!
                </h1>
                <p className="text-center"> तारे इकट्ठे हुए </p>
                <ul className="ul-r">
                  <li>
                    <img src="assets/img/icon/star.svg" alt="" />
                  </li>
                  <li>
                    <img src="assets/img/icon/star.svg" alt="" />
                  </li>
                  <li>
                    <img src="assets/img/icon/star.svg" alt="" />
                  </li>
                  <li>
                    <h4>{user_star}</h4>
                  </li>
                </ul>
              </div>
            </>
          );
        }
      };


  return (
    <>
      <main>
        <section className="bg-boarding karo overflowsetbody F8F1FD height-auto">
        <Name/>
          <div className="container-fluid cf-set">

            <div className="row  mt-50">
              <div className="col-lg-5">
               <Result1/>
                <h1
                  className="mt-40 red wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  आपकी जानकारी के लिए -
                </h1>{" "}
                <br />
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img
                            src="assets/img/khelo/raster-img/virat-kohli.jpg"
                            alt=""
                          />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>विराट कोहली</h3>
                        <p>
                        विराट कोहली भारतीय क्रिकेट टीम के एक दिवसीय क्रिकेट, टेस्ट क्रिकेट व 20-20 (अंतरराष्ट्रीय) फॉर्मेट के क्रिकेटर हैं। कोहली को दुनिया के सर्वश्रेष्ठ बल्लेबाजों में से एक माना जाता है। वे सन् 2008 की अंडर–19 क्रिकेट विश्व कप विजेता दल के कप्तान भी रह चुके हैं।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                          <img src="assets/img/khelo/raster-img/sachin.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>सचिन तेंदुलकर</h3>
                        <p>
                        सचिन रमेश तेंदुलकर, पूर्व अंतर्राष्ट्रीय क्रिकेटर हैं जिन्होंने भारतीय राष्ट्रीय टीम की कप्तानी की है । भारत के सर्वोच्च नागरिक सम्मान भारत रत्न से सम्मानित होने वाले वह सर्वप्रथम खिलाड़ी और सबसे कम उम्र के व्यक्ति हैं। राजीव गांधी खेल रत्न पुरस्कार से सम्मानित होने वाले पहले क्रिकेट खिलाड़ी हैं। सन् 2008 में वे पद्म विभूषण से भी पुरस्कृत किये जा चुके है।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                        <img src="assets/img/khelo/raster-img/dhoni.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>महेंद्र सिंह धोनी</h3>
                        <p>
                        महेंद्र सिंह धोनी झारखंड के रांची में जन्मे पूर्व भारतीय क्रिकेट खिलाड़ी हैं। उन्हें व्यापक रूप से क्रिकेट के इतिहास में सबसे महान कप्तान विकेट कीपर-बल्लेबाज और फिनिशर में से एक माना जाता है। वह दाएं हाथ के विकेटकीपर-बल्लेबाज के रूप में खेलते हैं |
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                        <img src="assets/img/khelo/raster-img/kapil.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>कपिल देव</h3>
                        <p>
                        कपिल देव रामलाल निखंज भारत के पूर्व क्रिकेट खिलाड़ी हैं। भारत के सर्वश्रेष्ठ क्रिकेट खिलाड़ियों में उनकी गणना होती है। वे भारतीय क्रिकेट के कप्तान के पद पर रह चुके हैं। 1983 के क्रिकेट विश्वकप में वे भारतीय क्रिकेट टीम के कप्तान थे।
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div className="services-box">
                      <div className="img-box">
                        <center>
                        <img src="assets/img/khelo/raster-img/sumit-antul.jpg" alt="" />
                        </center>
                      </div>
                      <div className="content-area">
                        <h3>सुमित अंतिल</h3>
                        <p>
                        सुमित अंतिल एक भारतीय पैरालिंपियन और भाला फेंक खिलाड़ी हैं। उन्होंने 2020 ग्रीष्मकालीन पैरालिंपिक और 2023 विश्व पैरा एथलेटिक्स चैंपियनशिप में पुरुषों की भाला फेंक श्रेणी में स्वर्ण पदक जीता है |
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.4s"
                  >
                    <div
                      className="mt-30  wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <NavLink
                        className="theme_btn theme_btn_bg chnage-d"
                        to="/khelo-04-activity-11-screen"
                      >
                        अगला सवाल
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo04Activity10Screen;
