import React from 'react'
import { NavLink } from 'react-router-dom'

const Kyakrna05ActivityScreen = () => {
  return (
    <>
        <main>

        <section className="bg-boarding bg-flexible pt-m-2 karo">
           <div className="container-fluid cf-set">


               <div className="row align-items-center justify-content-center ">
                   <div className="col col-lg-6">
                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />क्या करना है ?</h2>
                   </div>
                   <div className="col col-lg-5">
                       <NavLink className="float-right width-auto" to="/karo-05-activity-02-screen"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></NavLink>
                   </div>
               </div>


               <div className="row align-items-center justify-content-center mt-30">
                   <div className="col-lg-7">


                       <p className="mpara large-para fs-30 mt-40 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">आपको 3 अलग-अलग चित्र दिखाए जाएँगे और आपको उन चित्रों में दिखाई गई समस्या का हल बताना है।  </p>
                       <p className="mpara large-para fs-30 mt-0 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">आपको हर सवाल के लिए 3 विकल्पों में से 1 को चुन कर जवाब देना है।  </p>
                       <p className="mpara large-para fs-30 mt-0 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">एक से अधिक विकल्प भी सही हो सकते हैं।  </p>



                       <div className="mt-15 mb-15" >
                           <NavLink className="theme_btn theme_btn_bg chnage-d" to="/karo-05-activity-03-screen">आगे बढ़ें </NavLink>
                       </div>

                   </div>




                   <div className="col-lg-4">
                       <div className="six-tare wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                           <p>इस एक्टिविटी को पूरा करने पर आपको 6 तारे मिलेंगे। </p>
                           <ul className="ul-r align-left">
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><img src="assets/img/icon/star.svg" alt="" /></li>
                               <li><h4>6</h4></li>
                           </ul>
                       </div>

                   </div>
               </div>
           </div>
        </section>



   </main>

    </>
  )
}

export default Kyakrna05ActivityScreen