import React from "react";
import { NavLink } from "react-router-dom";

const Gyanbox02ActivityScreen = () => {
  return (
    <>
      <main>
        <section className="bg-boarding blues">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center ">
              <div className="col-lg-7 ">
                <h2 className="h2 kkh-text">
                  {" "}
                  <img
                    className="mr-20 kkh-home-img"
                    src="assets/img/icon/home.png"
                    alt=""
                  />
                  ज्ञान बॉक्स
                </h2>
              </div>
              <div className="col-lg-5">
                <NavLink
                  className="float-right width-auto"
                  to="/karo-01-result"
                >
                  {" "}
                  <img
                    className="close-icon"
                    src="assets/img/icon/close.svg"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>

            <div className="row align-items-top justify-content-between mt-50">
              <div className="col-lg-7">
                <p
                  className="mpara large-para fs-30 mt-40 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  हमें बहुत बार ऐसा लगता है कि लड़के ज़्यादा ताकतवर होते हैं और
                  लड़कियाँ ज़्यादा घर के काम करती हैं, या फिर लड़के और लड़की बराबर
                  नहीं होते। हम थोड़ा विचार करें और अपने आस पास देखें तो समझ में
                  आएगा कि लड़का लड़की बराबर होते हैं। यदि हम सोचने बैठे तो पता
                  चलता है कि शक्ति सब में है, दम सब में है, लेकिन हमे लगता है कि
                  मेहनत का काम सिर्फ लड़के या मर्द कर सकते हैं, ये कितनी बड़ी
                  गलतफहमी है। लड़कियाँ भी बहुत से मेहनत के काम करती हैं, जिन्हें
                  हम नजर अंदाज़ कर देते हैं।
                </p>
              </div>
              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <NavLink to="/karo-03-activity-screen">
                  <center>
                    <img
                      draggable="false"
                      className="mb-15 efffect-scale"
                      width="70%"
                      src="assets/img/karo/gyan-box-taraju.svg"
                    />
                  </center>
                </NavLink>
              </div>
            </div>

            <div>
              <div
                className=" wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                {/* <NavLink to="/karo-01-galat-jawab" className="btnset "><span>आगे बढ़ें </span> </NavLink> */}
                <NavLink to="/karo-02-activity-01-screen" className="btnset ">
                  <span>आगे बढ़ें </span>{" "}
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Gyanbox02ActivityScreen;
