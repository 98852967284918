import React from "react";
import { NavLink } from "react-router-dom";
import Name from "../common/Name";

const Suno03Activity01Screen = () => {
  return (
    <>
      <main>
        <section className="bg-flexible jaja-baba-bg">
        <Name />
        <div class="data-adjust">
          <div className="container-fluid cf-set">
            
            <div className="row align-items-center justify-content-center mt-20">
              <div className="col-lg-4">
                <h1
                  className="h1 mb-20 mt-m-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  सुनो{" "}
                </h1>
                <div
                  className="karo-activity-barabri wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <h4>सुनो तो सही सुनो </h4>
                  <img
                    className="mt-20"
                    width="70%"
                    src="assets/img/suno/suno-img.svg"
                    alt=""
                  />
                  <div className="">
                    <NavLink
                      to="/suno-03-activity-02-screen"
                      className="btnset radius-set m-auto"
                    >
                      <span>चुनें</span>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 mt-m-30 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <center>
                  <img
                    draggable="false"
                    width="95%"
                    src="assets/img/suno/suno-img-2.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Suno03Activity01Screen;
