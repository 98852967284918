import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link, NavLink } from "react-router-dom";
import BaseURL from "../common/Baseurl";
import axios from "axios";

const ShabdkoshDetails = () => {
  const location = useLocation();
  const { id } = useParams();

  const [dictionary, setDictionary] = useState({});
  useEffect(() => {
    var config = {
      method: "get",
      url: BaseURL + "/dictionary?id=" + id,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setDictionary(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log(dictionary.word);

  return (
    <>
      <main>
        <section className="bg-flexible general-bg">
          <div className="">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 ">
                <div className="col-lg-7">
                  <h2 className="h2 kkh-text">
                    {" "}
                    <img
                      className="mr-20 kkh-home-img"
                      src="assets/img/icon/home.png"
                      alt=""
                    />
                    शब्दकोश
                  </h2>
                </div>
                <div className="col-lg-5">
                  <NavLink
                    className="float-right width-auto"
                    to="/shabdkosh"
                  >
                    {" "}
                    <img
                      className="close-icon"
                      src="assets/img/icon/close.svg"
                      alt=""
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row">
                <div className="col-md-6">
                  <p
                    className="mpara fs-30 large-para wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    सभी जेंडर शब्दों और उनकी परिभाषाओं के बारे में जाने
                  </p>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-md-6 ">
                  <h4 className="h4">{dictionary.hindi_word}</h4>
                  <div className="share-box">
                    <p
                      className="mpara fs-22 large-para wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.1s"
                    >
                      <b>परिभाषा</b>
                    </p>
                    <p
                      className="mpara fs-22 text-left large-para wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.1s"
                    >
                      {dictionary.define_hindi}
                    </p>
                    <p
                      className="mpara fs-22 large-para mb-0 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.1s"
                    >
                      <b>उच्चारण</b>{" "}
                      <img
                        className="mic-icon"
                        src="assets/img/general/icon/mic-icon.png"
                        alt=""
                      />
                    </p>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <h4 className="h4">{dictionary.word}</h4>
                  <div className="share-box">
                    <h4 className="h4">Definition</h4>
                    <p
                      className="mpara fs-22 text-left large-para wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.1s"
                    >
                      {dictionary.define_eng}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="mpara fs-30 large-para mt-10 wow fadeInDown">
                    Related terms: {dictionary.related_terms}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ShabdkoshDetails;
