import React,{useState,useEffect} from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink } from "react-router-dom";

const Khelo13Activity07Screen = () => {

    const [larkaCss, setLarkaCss] = useState();
    const [larkiCss, setLarkiCss] = useState();

    const [checkBlankData, setBlankData] = useState("blank");


    const user_details = JSON.parse(localStorage.getItem('user_details'))
    const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  const getSelect = (e) => {
    const selectValue = e.target.value;

    checkValue(selectValue);
    var data = JSON.stringify({
      user_id: user_id,
      ans: selectValue,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL+"/kheloActivity13",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setBlankData('filled');
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const checkValue = (selectVal) => {
    if (selectVal === "हाँ, अगर मुझे सही लगे") {

        setLarkaCss({ opacity: "1" });
        setLarkiCss({ opacity: "0.1" });

    //   setLarkaCss('checked');
    //   setLarkiCss('');
    //   setDonoCss({ opacity: "0.1" });
    }



    if (selectVal === "नहीं, अगर मुझे अच्छा न लगे") {

        setLarkaCss({ opacity: "0.1" });
        setLarkiCss({ opacity: "1" });

        // setLarkaCss('');
        // setLarkiCss('checked');
    }
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
    });

    var config = {
      method: "post",
      url: BaseURL + "/getKheloActivity13ByUser",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const selectVal = response.data.data.ans;
          checkValue(selectVal);
          setBlankData("filled");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const BtnDisabled = () => {
    if (checkBlankData !== "blank") {
      return (
        <>
          <div
             className="mt-30"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
          >
            <NavLink
              to="/khelo-13-activity-08-screen"
              className="btnset m-auto "
            >
              आगे बढ़ेंं
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
           className="mt-30"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
          >
            <button class="btnset m-auto disabled-btn" type="button" disabled>
              आगे बढ़ेंं
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <main>
        <section className="bg-flexible E8FEFF">
          <div className="fixed-header">
            <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-20">
                <div className="col-lg-4">
                  <div
                    className="mt-m-2 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <button className="theme_btn theme_btn_bg chnage-d step-btn">
                      <span className="span-c-change">03</span>
                    </button>
                  </div>
                  <p
                    className="mpara large-para mt-m-1 fs-30 mt-40  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    क्या कोई हमें गोद में उठा सकता है, खेल के अलावा?
                  </p>

                  <BtnDisabled/>
                </div>
                <div className="col-lg-4 mt-m-2 offset-lg-1 krs-row-set krs-box-change">
                  <div className="kkkn-check-effect mt-m-2">
                    <div
                      className="activity-box invisible-checkboxes kkkn-yellow-bg wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                      style={larkaCss}
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="हाँ, अगर मुझे सही लगे"
                        id="t1"
                        onClick={getSelect}
                        // checked={larkaCssChecked}
                      />
                      <label
                        className="checkbox-alias kab-red-sub"
                        htmlFor="t1"
                      >
                        <h4 className="slider-h burai-text">
                          {" "}
                          हाँ, अगर मुझे सही लगे
                        </h4>
                      </label>
                    </div>
                    <div
                      className="activity-box invisible-checkboxes kkkn-yellow-bg kkkn-pink-bg  mt-15 wow fadeInDow"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                      style={larkiCss}
                    >
                      <input
                        type="radio"
                        name="rGroup"
                        defaultValue="नहीं, अगर मुझे अच्छा न लगे"
                        id="b2"
                        onClick={getSelect}
                        // checked={larkiCssChecked}
                        // checked="checked"
                      />
                      <label
                        className="checkbox-alias kkkn-pin-bg-sub "
                        htmlFor="b2"
                      >
                        <h4 className="slider-h burai-text">
                          {" "}
                          नहीं, अगर मुझे अच्छा न लगे

                        </h4>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Khelo13Activity07Screen;
